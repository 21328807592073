import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { experimentalStyled as styled, keyframes } from "@mui/material/styles";
import { bounce } from "@mui/system";

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

const BouncingText = styled(Typography)`
  animation: ${bounceAnimation} 1s infinite;
`;

const Pagenotfound = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box textAlign="center">
        <BouncingText variant="h4" color="primary" mt={2}>
          Oops! Page Not Found
        </BouncingText>
        <Typography variant="body1" color="textSecondary" mt={2}>
          The page you are looking for doesn't exist.
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="outlined"
          color="primary"
          startIcon={<ArrowBackIcon />}
          sx={{ marginTop: 20 }}
        >
          Go Back Home
        </Button>
      </Box>
    </Container>
  );
};

export default Pagenotfound;
