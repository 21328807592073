import React, { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  InputBase,
  Tooltip,
  Fab,
  Card,
  CardHeader,
  Collapse,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import MailIcon from "@mui/icons-material/Mail";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Person from "@mui/icons-material/Person";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { ExitToApp, Home } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import { getSearchData, getLogoutUrl } from "../../backend/backend";
import SearchBar from "./SearchBar";
import Loading from "./Loading";

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  fontFamily: "EquipExtended",
  textTransform: "capitalize",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
   
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Header = ({ newSearchedData, setNewSearchedData, allData }) => {
  const [searchedData, setSearchedData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.substring(2);
  console.log(path)
  const isHomePage = path === "oe-portal/architecture-template/home/1";
  const isNewHomePage = path === "test";
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const UserNameContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  }));

  const UserNameText = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(1),
  }));

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    setNewSearchedData(searchedData);
  };

  const appBarStyle = {
    backgroundColor: "#13294B",
  };

  const headerTextStyle = {
    flexGrow: 1,
    fontFamily: "EquipExtended",
    textTransform: "capitalize",
  };

  const navigateToHome = () => {
    navigate("/coe-portal/architecture-template/home/1");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSearchData(searchValue);
        setSearchedData(data);
        setNewSearchedData(data);
      } catch (error) {}
    };

    fetchData();
  }, [searchValue]);

  const name = sessionStorage.getItem("name");
  const accessToken = sessionStorage.getItem("accessToken");
  const initialLetter = name ? name.charAt(0).toUpperCase() : "";

  const isAccessTokenExpired = (token) => {
    if (token) {
      const now = Math.floor(Date.now() / 1000);
      return token.expiresAt < now;
    }
    return true;
  };

  useEffect(() => {
    if (isAccessTokenExpired(accessToken)) {
      navigate("/sessionExpired");
    }
  }, [accessToken]);

  const handleLogout = async () => {
    setIsLoading(true);
    const response = await getLogoutUrl();
    window.location.href = response.logoutUrl;
    setIsLoading(false);
    sessionStorage.clear();
  };
  const firstWordOfPath = path.split('/')[2];
  const formattedFirstWord = firstWordOfPath.replace(/([A-Z])/g, ' $1').trim();
  return (
    <AppBar position="static" style={appBarStyle}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={navigateToHome}
        >
          <Home />
        </IconButton>
        <ResponsiveTypography variant="h6" style={headerTextStyle}>
          {formattedFirstWord  || "Home"}
        </ResponsiveTypography>
        {isHomePage && (
          <SearchBar
            value={searchValue}
            onChange={handleChange}
            onSearch={handleSearch}
            setSearchValue={setSearchValue}
            allData={allData}
            searchedData={searchedData}
            style={{ border: "2px solid red" }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </SearchBar>
        )}
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Fab
              size="small"
              color="primary"
             
            >
              {initialLetter}
            </Fab>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>{" "}
            {sessionStorage.getItem("name")}
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <MailIcon fontSize="small" />
            </ListItemIcon>
            {sessionStorage.getItem("username")}
          </MenuItem>
          <Divider />

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
