import React, { useEffect, useState } from "react";
import { invokeLambdaFunction } from "../data/backend";
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TablePagination,
  Select,
  MenuItem,
  Box,
  TextField,
  styled,
  Tabs,
  Tab,
  Fab,
  Button,
  FormControl,
} from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../../components/Header";
import { Home } from "@mui/icons-material";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../../../ArchitectureTemplate/Components/Footer";
import Sidenav from "../../components/Sidenav";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getSymbolFromCurrency from "currency-symbol-map";
import { set } from "date-fns";
import Snack from "../../../ArchitectureTemplate/Components/Snack"
import CustomSkeleton from "../../components/CustomSkeleton";
import {getRoles} from "../../../backend/backend"
import {maxAllowedDate} from "../../dateConstants.js"
import {minAllowedDate} from "../../dateConstants.js"
const styles = {
  tableContainer: {
    maxWidth: "100%",
  },
  tableHead: {
    backgroundColor: "#F8F8FF",
  },
  tableCell: {
    fontFamily: "Equip",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  deleteButton: {
    color: "#f44336",
  },
};
const CustomerView = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { level, region, paramstartdate, paramenddate } = useParams();
   
  const navigate = useNavigate();
  const [aggregatedData, setAggregatedData] = useState([]);
  const date = new Date().toISOString().split("T")[0];
  const [selectedTab, setSelectedTab] = useState("maintable");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [currency,setCurrency]=useState("");
  const [status,setStatus]=useState("");
  const[statusCode,setStatusCode]=useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // console.log(paramstartdate,paramenddate)
  useEffect(() => {
    const fetchDataAndRoles = async () => {
      const userData = await getRoles(
        sessionStorage.getItem("name"),
        sessionStorage.getItem("username"),
        "AWS"
      ); // Call getRoles first and wait for it to complete
      const { billing_account_details, message, user_role, useremail, username } =
      userData;
      // console.log(userData);
    if ( startDate && endDate) {
      // console.log(startDate,endDate)
      fetchData(startDate,endDate,userData.billing_account_details,userData.user_role);
      sessionStorage.setItem("endDate", endDate);
      sessionStorage.setItem("startDate", startDate);
    }
    else{
      setStartDate(paramstartdate);
      setEndDate(paramenddate)
      fetchData(paramstartdate,paramenddate,userData.billing_account_details,userData.user_role)
    }
  };
  fetchDataAndRoles();
  }, [startDate,endDate]);
  const fetchData = async (startdate,enddate,userdata,userrole) => {
    let accountIds = []; 
    if(userrole!="Member"){
      accountIds = userdata?.map((user) => user.account_id);
    }
    try {
      const response = await invokeLambdaFunction({
        level: level,
        region:region,
        start_date: startdate,
        end_date: enddate,
        filters:accountIds
      });
      // if(response){
      //    
      //   // const Jsonresponse=JSON.parse(response);
      //   if (response.length > 0) {
      //     setCurrency(response[0].CostUnit);
      //   }
      //   setData(response);
      //   setLoading(false);}
      //   else{
      //     setStatus("No Data is available");
      //   setStatusCode(500)
      //  setData(response)
      //   setSnackbarOpen(true);
      //   }
      if (response.statusCode === 200 && response.body !== "No record found") {
        const jsontype = JSON.parse(response.body);
        setCurrency(jsontype[0].CostUnit);
        setLoading(false);
        setData(jsontype);

    } else if (response.statusCode === 200 && response.body === "No record found") {
        setStatus(response.body);
        setStatusCode(500)
        setSnackbarOpen(true);
        
        setData([]);
       
    }else if (response.statusCode=== 403){
      setStatus(response.body);
      setStatusCode(500)
      setSnackbarOpen(true);

    }
     else {
        setStatus("No Data is available");
        setStatusCode(500);
        setData(response);
        setSnackbarOpen(true);
    }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
    return `${year}-${month}-01`;
  };
  const handleDateChange = (newDate) => {
    const inputdate=newDate.toISOString().split("T")[0]
    setStartDate(inputdate);

    // Calculate the start date as the first day of the previous month
    const currentMonth = new Date(newDate);
    currentMonth.setMonth(currentMonth.getMonth() + 1);
    currentMonth.setDate(1); // Set to the first day of the month
    const formattedStartDate = formatDate(currentMonth);
    setEndDate(formattedStartDate);
  
  };
  function formatDateToDisplay(dateString) {
    if (!dateString) {
      return ""; // Return empty string if dateString is blank
  }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return ""; // Return empty string if date is invalid
  }
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
}
   
  const tableHeadStyle = {
    fontWeight: "bold",
    color: "#13294B",
    fontFamily: "EquipExtended",
    fontSize: 14,
  };
  const tableElementStyle = {
    fontFamily: "Equip",
    fontSize: 14,
    fontWeight: 400,
  };

  const handleRowClick = (region, orgname) => {
    const level = "accounts";

    navigate(`/aws/billingdashboard/accounts/${region}/${orgname}/${startDate}/${endDate}`);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update page state for TablePagination component
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Header />
          <Box sx={{ minHeight: "80vh" }}>
          {status && statusCode && (
            <Snack
              open={snackbarOpen}
              status={statusCode}
              data={status}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              handleClose={handleCloseSnackbar}
            />
          )}
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, margin: "10px", justifyItems: "flex-start" }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab
                    label="Home"
                    iconPosition="start"
                    icon={<Home />}
                    value="back"
                    component={Link}
                    to={`/aws/billingdashboard`}
                  />
                  <Tab
                    label={region}
                    value={"maintable"}
                    component={Link}
                    iconPosition="start"
                    icon={<CloudQueueIcon />}
                  />
                </Tabs>
              </div>
              <div style={{ margin: "10px", justifyItems: "flex-end" }}>
                <TextField
                  sx={{ minWidth: 200, marginTop: 5, marginRight: 2 }}
                  value={currency}
                  variant="standard"
                />
                <DatePicker
                 className="datepicker"
                  label="Select Month and Year"
                  showMonthYearPicker
                  selected={new Date(startDate)}
                  value={formatDateToDisplay(startDate)}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  dateFormat="MM/yyyy"
                  maxDate={maxAllowedDate}
                  minDate={minAllowedDate}
                />

                {/* <FormControl > */}
                {/* <Select
               sx={{ minWidth: 200, marginTop:5  }}
                // value={currentDate}
                // onChange={handleInvoiceDateChange}
                displayEmpty
                variant="standard"
               
              > */}
                {/* {filteredData2 &&
                  filteredData2.map((row) => (
                    <MenuItem key={row.InvoiceDate} value={row.InvoiceDate}>
                      {row.InvoiceDate}
                    </MenuItem>
                  ))} */}
                {/* </Select>
            </FormControl> */}
              </div>
            </div>
            <TableContainer
              style={styles.tableContainer}
              sx={{ boxShadow: "0px 4px 8px rgba(186, 220, 211, 5)" }}
            >
              <Table aria-label="pivot table">
                <TableHead style={styles.tableHead}>
                  <TableRow>
                    <TableCell />
                    {/* <TableCell  style={tableHeadStyle} >Region</TableCell> */}
                    <TableCell style={tableHeadStyle}>Customer Name</TableCell>
                    {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}

                    {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}
                    <TableCell style={tableHeadStyle}>Billing Month</TableCell>
                    {/* <TableCell style={tableHeadStyle}>End Date</TableCell> */}

                    <TableCell
                      style={{ ...tableHeadStyle, textAlign: loading ? " " : "right", }}
                    >
                      {" "}
                      Net Cost
                    </TableCell>
                    <TableCell
                      style={{ ...tableHeadStyle, textAlign: loading ? " " : "right", }}
                    >
                      Customer Cost
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading ? (
                Array.from({ length: 2 }).map((_, index) => (
            <CustomSkeleton key={index} numColumns={5} />
          ))
        ) : (data &&
          data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <React.Fragment key={index}>
                        <TableRow
                          className="tableRowHover"
                          // onClick={() =>
                          //   handleRowClick(region, row.CustomerName)
                          // }
                        >
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRowClick(region,row.CustomerName);
                              }}
                            >
                              {/* {expandedRow === row.key ? (
                            <ExpandMoreIcon />
                          ) : ( */}
                              <AddIcon />
                              {/* )} */}
                            </IconButton>
                          </TableCell>
                          {/* <TableCell style={tableElementStyle}>{row.CustomerRegion}</TableCell> */}
                          <TableCell style={tableElementStyle}>
                            {row.CustomerName}
                          </TableCell>
                          {/* <TableCell style={tableElementStyle}>
                            {row.CostUnit}
                          </TableCell> */}
                          {/* <TableCell style={tableElementStyle}>
                          {row.EndDate}
                        </TableCell> */}
                          {/* <TableCell style={tableElementStyle}>
                      {row.Currency}
                    </TableCell> */}
                     <TableCell
                              style={{
                                ...tableElementStyle,
                                textAlign: "left",
                              }}
                            >
                                {formatDateToDisplay(row.StartDate)}
                            </TableCell>
                          {/* {row.EndDate > date ? (
                            <TableCell style={tableElementStyle}>
                              Ongoing
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                ...tableElementStyle,
                                textAlign: "left",
                              }}
                            >
                               {formatDateToDisplay(row.EndDate)}
                            </TableCell>
                          )} */}

                          {/* <TableCell style={tableElementStyle}>{row.DueDate}</TableCell> */}
                          <TableCell
                            style={{ ...tableElementStyle, textAlign: loading ? " " : "right", }}
                          >
                            {" "}
                            {getSymbolFromCurrency(row.CostUnit)}{" "}
                            {row.CustomerCost.toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{ ...tableElementStyle, textAlign: loading ? " " : "right", }}
                          >
                            {" "}
                            {getSymbolFromCurrency(row.CostUnit)}{" "}
                            {row.NetCost.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )))}
                </TableBody>
              </Table>
            </TableContainer>

            {data && data.length > 5 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default CustomerView;
