import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Button,
  Box,
  Tabs,
  Tab,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Snack from "../../ArchitectureTemplate/Components/Snack"
import getSymbolFromCurrency from 'currency-symbol-map'
import HomeIcon from '@mui/icons-material/Home';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import {maxAllowedDate} from "../dateConstants.js"
import {minAllowedDate} from "../dateConstants.js"
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import parseCsvAndAggregate from "./Aggregation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MainTable from "./MainTable";
import Header from "../components/Header";

import Footer from "../../ArchitectureTemplate/Components/Footer";
import Sidenav from "../components/Sidenav";
import CustomSkeleton from "../components/CustomSkeleton";
const ProjectDetailsTable = ({ data, expandedProject }) => {
  const { key, customername } = useParams();
  const location = useLocation();
  const rowData = JSON.parse(location?.state);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("customer");
  const [filteredData2, setFilteredData2] = useState(null);
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState("");
  const currentDate=key.split('_')[1];
  const [currency,setCurrency]=useState("");
  const [loading, setLoading] = useState(true);
  const [status,setStatus]=useState("");
  const[statusCode,setStatusCode]=useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // console.log(currentDate)
   
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleRowClick = (key, customername, project) => {
     
    navigate(`/gcp/billingdashboard/${key}/${customername}/${project}`);
  };
  useEffect(() => {
    // Call the parseCsvToJson function and pass a callback to set the state
    parseCsvAndAggregate((data) => {
      setJsonData(data);
      setLoading(false)
    });
  }, []);

  useEffect(() => {
    if (jsonData) {
      // First filter based on the key
      const filteredByKey = jsonData.filter((row) => row.key === key);

      // Then filter the nested array based on the customer name
      const filteredData = filteredByKey.map((row) => ({
        ...row,
        Customers: row.Customers.filter(
          (customer) => customer.Customer === customername
        ),
      }));
      if (filteredData.length > 0) {
      setCurrency(filteredData[0].Currency)

      setFilteredData(filteredData[0].Customers[0].Projects);
      }
      else{
        setCurrency("Filter by Currency"); // Set currency to empty string if no record found
        setFilteredData([]);
        setStatus("No data available");
        setStatusCode(403);
        setSnackbarOpen(true);
      }
    }
     
  }, [jsonData, key, customername]);
useEffect(() => {
    if (jsonData) {
      // console.log(jsonData,"json")
      const filteredData = jsonData.filter(row => {
        const customerNameFound = row.Customers.some(customer => customer.Customer === customername);
        // console.log(customerNameFound,"custoemrname")
        // console.log(row.key.split('_')[0] === key.split('_')[0],"key")
                return row.key.split('_')[0] === key.split('_')[0] && customerNameFound;
        
      });
    //  console.log(filteredData)
      setFilteredData2(filteredData);
      // console.log(filteredData2,"fit")
      // Extracting unique invoice dates from filtered data
      const uniqueInvoiceDates = [...new Set(filteredData.map(row => row.InvoiceDate))];
      setSelectedInvoiceDate(sessionStorage.getItem(selectedInvoiceDate))
      // setSelectedInvoiceDate(sessionStorage.getItem(selectedInvoiceDate)); // Set the default selected invoice date
    }
  }, [jsonData, key,customername]);
  const handleInvoiceDateChange = (event) => {
    setSelectedInvoiceDate(event.target.value);
    sessionStorage.setItem('selectedInvoiceDate', event.target.value)
    navigate(`/gcp/billingdashboard/${key.split("_")[0]}_${event.target.value}/${customername}`)
  
  };
  function formatDateToDisplay(dateString) {
    const date = new Date(dateString);
    const options = {  month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
}
function getLastDayOfMonth(date) {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const lastDay = new Date(year, month + 1, 0);
  return `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;
}
// useEffect(() => {
//   console.log('Updated selectedInvoiceDate:', selectedInvoiceDate);
// }, [selectedInvoiceDate]);
const handleDateChange = (newDate) => {
  const lastDayOfMonth = getLastDayOfMonth(newDate);
  // console.log(lastDayOfMonth)
const inputdate=newDate.toISOString().split("T")[0]
  setSelectedInvoiceDate(lastDayOfMonth);
  // console.log(selectedInvoiceDate);
  // console.log(inputdate);
  sessionStorage.setItem("selectedInvoiceDate", lastDayOfMonth);
  
    navigate(`/gcp/billingdashboard/${key.split("_")[0]}_${lastDayOfMonth}/${customername}`);
  // console.log(newDate)
  // const inputdate=newDate.toISOString().split("T")[0]
  // setStartDate(inputdate);
  
  // // Calculate the start date as the first day of the previous month
  // const currentMonth = new Date(newDate);
  // currentMonth.setMonth(currentMonth.getMonth() + 1);
  // currentMonth.setDate(1); // Set to the first day of the month

  // const formattedStartDate = formatDate(currentMonth);
  // // console.log(currentMonth)
  // setEndDate(formattedStartDate);
  
 
};
const handleCloseSnackbar = () => {
  setSnackbarOpen(false);
};
  const tableHeadStyle = {
    fontWeight: 'bold',
    color: '#13294B',
    fontFamily: 'EquipExtended',
    fontSize: 14,
   
};
const tableElementStyle = {
    fontFamily: 'Equip',
    fontSize: 14,
    fontWeight: 400,
   
};

  return (
    <>
  
  <Box sx={{ display: "flex" }}>
        <Sidenav/>
       
        <Box  component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Header />
        <Box sx={{minHeight:"80vh"}}>
        {status && statusCode && (
            <Snack
              open={snackbarOpen}
              status={statusCode}
              data={status}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              handleClose={handleCloseSnackbar}
            />
          )}
      <div style={{ display: "flex" }}>
          <div style={{flex:1, margin: "10px", justifyItems: "flex-start" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
        >
        <Tab
            label="Home"
            value="back"
            component={Link}
            to={`/gcp/billingdashboard`}
            iconPosition="start"
            icon={<HomeIcon/>}
          />
           <Tab
            label={key.split("_")[0]}
            value="back"
            component={Link}
            to={`/gcp/billingdashboard/${key}`}
            iconPosition="start"
            icon={<CloudQueueIcon />}
          />
          <Tab
            label={customername}
            value="customer"
            component={Link}
            iconPosition="start"
            icon={<GroupIcon  />}
          />
        </Tabs>
        </div>
          <div style={{margin: "10px", justifyItems: "flex-end" }}>
          <TextField
        sx={{ minWidth: 200, marginTop:5,marginRight:2 }}
       
        value={currency}
        
        variant="standard"
      />
        {/* <FormControl >
          <Select
             sx={{ minWidth: 200, marginTop:5 }}
            value={currentDate}
            onChange={handleInvoiceDateChange}
            variant="standard"
            displayEmpty
          >
            {filteredData2 && filteredData2.map(row => (
              <MenuItem key={row.InvoiceDate} value={row.InvoiceDate}>
                {row.InvoiceDate}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        <DatePicker className="datepicker"
                     
                     label="Select Month and Year"
                     showMonthYearPicker
                     
                     selected={currentDate}
                     value={formatDateToDisplay(currentDate)}
                 
                     onChange={handleDateChange}
                     renderInput={(params) => <TextField variant="filled" {...params} 
                     style={{minWidth:100,marginRight:"20px"}}
                     />}
                     maxDate={maxAllowedDate}
                     minDate={minAllowedDate}
                   />
        </div>
        </div>
        <TableContainer
          sx={{ boxShadow: "0px 4px 8px rgba(186, 220, 211, 2)" }}
        >
          <Table aria-label="project-details">
           
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={tableHeadStyle}>Project</TableCell>
                
                <TableCell style={tableHeadStyle}>Billing Account</TableCell>
                {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}
                <TableCell style={{ ...tableHeadStyle,textAlign: loading ? " " : "right"}}>Net Cost</TableCell>
                <TableCell style={{ ...tableHeadStyle,textAlign: loading ? " " : "right"}}>Customer Cost</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              Array.from({ length: 2 }).map((_, index) => (
            <CustomSkeleton key={index} numColumns={5} />
          ))
        ) : (filteredData && (
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredData
                ).map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      onClick={() =>
                        handleRowClick(key, customername, row.Project)
                      }
                    >
                      <TableCell>
                        <IconButton
                          aria-label={
                            expandedProject === row.key ? "Collapse" : "Expand"
                          }
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(key, customername, row.Project);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell style={tableElementStyle}>
                        {row.Project}
                      </TableCell>
                      <TableCell style={tableElementStyle}>
                        {row.BillingAccount}
                      </TableCell>
                      {/* <TableCell style={tableElementStyle}>
                        {currency}
                      </TableCell> */}
                      <TableCell  style={{ ...tableElementStyle, textAlign: 'right' }}>
                        {getSymbolFromCurrency(currency)} {row.NetCost.toFixed(2)}
                      </TableCell>
                      <TableCell  style={{ ...tableElementStyle, textAlign: 'right' }}>
                      {getSymbolFromCurrency(currency)} {row.CustomerCost.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            ))}
          </Table>
          
        </TableContainer>
        {filteredData  && filteredData.length>0 &&  (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {/* <Button component={Link} to={"/"} variant="outlined" color="primary">
          Back to main table
        </Button> */}
      </Box>
      <Footer/>
    /</Box>
   </Box>
 
   </>
  );
};

export default ProjectDetailsTable;
