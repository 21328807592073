import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Button,
  Typography,
  Box,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@mui/material";
import {
  useParams,
  useLocation,
  useNavigate,
  Link,
  json,
} from "react-router-dom";
import WorkIcon from "@mui/icons-material/Work";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import parseCsvAndAggregate from "./Aggregation";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import PersonIcon from "@mui/icons-material/Person";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import nagarrologo from "../../assets/images/Nagarro Horizontal Light.png";
// import backgroundWatermark from '../../Assessts/3d.jpg'
import {
  CloudDownload,
  FlashAuto,
  HomeRepairServiceOutlined,
} from "@mui/icons-material";
import getSymbolFromCurrency from "currency-symbol-map";
import Header from "../components/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Snack from "../../ArchitectureTemplate/Components/Snack";
import Footer from "../../ArchitectureTemplate/Components/Footer";
import Sidenav from "../components/Sidenav";
import CustomSkeleton from "../components/CustomSkeleton";
import {maxAllowedDate} from "../dateConstants.js"
import {minAllowedDate} from "../dateConstants.js"
const ServiceDetailsTable = () => {
  const { key, customername, projectname } = useParams();

  const location = useLocation();
  const rowData = JSON.parse(location?.state);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [jsonData, setJsonData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [filterServices, setFilterServices] = useState(null);
  const [selectedTab, setSelectedTab] = useState("project");
  const [filteredData2, setFilteredData2] = useState(null);
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState("");
  const [totalNetCost, setTotalNetCost] = useState(null);
  const [pdfBillingAccount, setPdfBillingAccount] = useState("");
  const currentDate = key.split("_")[1];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currency, setCurrency] = useState("");
  const tableHeadStyle = {
    fontWeight: "bold",
    color: "#13294B",
    fontFamily: "EquipExtended",
    fontSize: 14,
  };
  const tableElementStyle = {
    fontFamily: "Equip",
    fontSize: 14,
    fontWeight: 400,
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    // Call the parseCsvToJson function and pass a callback to set the state
    parseCsvAndAggregate((data) => {
      setJsonData(data);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    if (jsonData) {
      // First filter based on the key
      const filteredByKey = jsonData.filter((row) => row.key === key);

      // Then filter the nested array based on the customer name
      const filteredByCustomer = filteredByKey.map((row) => ({
        ...row,
        Customers: row.Customers.filter(
          (customer) => customer.Customer === customername
        ),
      }));

      // Then filter the projects array based on the project name
      const filteredByProject = filteredByCustomer.map((row) => ({
        ...row,
        Customers: row.Customers.map((customer) => ({
          ...customer,
          Projects: customer.Projects.filter(
            (project) => project.Project === projectname
          ),
        })),
      }));

      // Extract the filtered services from the first matching project
      const filterServices =
        filteredByProject[0]?.Customers[0]?.Projects[0]?.Services;
        if (filteredByProject.length > 0) {
      setCurrency(filteredByProject[0].Currency);
        
      if (filteredByProject[0].Customers[0].Projects[0]) {
        setTotalNetCost(
          filteredByProject[0].Customers[0].Projects[0].CustomerCost
        );
        setPdfBillingAccount(
          filteredByProject[0].Customers[0].Projects[0].BillingAccount
        );
      }

      setFilteredData(filterServices);
    }
    else{
      setCurrency("Filter by Currency"); // Set currency to empty string if no record found
      setFilteredData([]);
      setStatus("No data available");
      setStatusCode(403);
      setSnackbarOpen(true);
    }
    }
  }, [jsonData, key, customername, projectname]);
  useEffect(() => {
    if (jsonData) {
      // console.log(jsonData, "json");
      const filteredData = jsonData.filter((row) => {
        const customerMatch = row.Customers.some((customer) => {
          // Check if the customer name matches
          const customerNameMatch = customer.Customer === customername;
          // Check if the project name matches within this customer's projects
          const projectMatch = customer.Projects.some(
            (project) => project.Project === projectname
          );
          return customerNameMatch && projectMatch;
        });
        // Check if the key matches and if at least one customer and project match was found
        return row.key.split("_")[0] === key.split("_")[0] && customerMatch;
      });
      // console.log(filteredData);
      setFilteredData2(filteredData);
      // console.log(filteredData2, "fit");
      // Extracting unique invoice dates from filtered data
      const uniqueInvoiceDates = [
        ...new Set(filteredData.map((row) => row.InvoiceDate)),
      ];
      setSelectedInvoiceDate(uniqueInvoiceDates[0]); // Set the default selected invoice date
    }
  }, [jsonData, key, customername]);
  const handleInvoiceDateChange = (event) => {
    setSelectedInvoiceDate(event.target.value);
    sessionStorage.setItem("selectedInvoiceDate", event.target.value);
    navigate(
      `/gcp/billingdashboard/${key.split("_")[0]}_${
        event.target.value
      }/${customername}/${projectname}`
    );
  };
  function formatDateToDisplay(dateString) {
    const date = new Date(dateString);
    const options = { month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  function getLastDayOfMonth(date) {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const lastDay = new Date(year, month + 1, 0);
    return `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(lastDay.getDate()).padStart(2, "0")}`;
  }
  // useEffect(() => {
  //   console.log('Updated selectedInvoiceDate:', selectedInvoiceDate);
  // }, [selectedInvoiceDate]);
  const handleDateChange = (newDate) => {
    const lastDayOfMonth = getLastDayOfMonth(newDate);
    // console.log(lastDayOfMonth);
    const inputdate = newDate.toISOString().split("T")[0];
    setSelectedInvoiceDate(lastDayOfMonth);
    // console.log(selectedInvoiceDate);
    // console.log(inputdate);
    sessionStorage.setItem("selectedInvoiceDate", lastDayOfMonth);

    navigate(
      `/gcp/billingdashboard/${
        key.split("_")[0]
      }_${lastDayOfMonth}/${customername}/${projectname}`
    );
    // console.log(newDate)
    // const inputdate=newDate.toISOString().split("T")[0]
    // setStartDate(inputdate);

    // // Calculate the start date as the first day of the previous month
    // const currentMonth = new Date(newDate);
    // currentMonth.setMonth(currentMonth.getMonth() + 1);
    // currentMonth.setDate(1); // Set to the first day of the month

    // const formattedStartDate = formatDate(currentMonth);
    // // console.log(currentMonth)
    // setEndDate(formattedStartDate);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const exportToPDF = () => {
    const doc = new jsPDF();

    // Add logo image
    const logoImg = new Image();
    logoImg.onload = function () {
      const logoWidth = 60; // Set the width of the logo
      const logoHeight = 60 * (logoImg.height / logoImg.width);
      const logoX = doc.internal.pageSize.getWidth() - logoWidth - 5; // Align the logo to the extreme right
      const logoY = 20 - logoHeight / 2; // Align the logo vertically with the header text
      doc.addImage(logoImg, "JPEG", logoX, logoY, logoWidth, logoHeight);

      // Add additional text
      doc.setFont("helvetica", "bold");
      doc.setFontSize(20);
      doc.setTextColor("#000000");

      doc.text(`Cost breakdown`, 13, 23);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(`Hyperscaler:`, 13, 40);
      doc.text(`Billing Account:`, 13, 45);
      doc.text(`Invoice Date:`, 13, 50);
      doc.text(`Customer:`, 13, 55);
      doc.text(`Project:`, 13, 60);
      doc.text(`Currency:`, 13, 65); // Add currency after project
      doc.setFont("helvetica", "bold");
      doc.setFontSize(15);
      doc.setTextColor("#000000");
      doc.text(`Total Cost:`, 13, 90);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(`GCP`, 48, 40);
      doc.text(`${pdfBillingAccount}`, 48, 45);
      doc.text(`${key.split("_")[1]}`, 48, 50);
      doc.text(`${customername}`, 48, 55);
      doc.text(`${projectname}`, 48, 60);
      doc.text(`${currency}`, 48, 65); //
      doc.setFont("helvetica", "normal");
      doc.setFontSize(15);
      doc.setTextColor("#000000");
      doc.text(`${totalNetCost.toFixed(2)}*`, 48, 90); //

      // Add "excluding VAT" text
      doc.setFont("helvetica", "normal");
      doc.setFontSize(8); // Adjust font size as needed
      doc.setTextColor("#999999"); // Set a subtle color for the text
      doc.text(`excluding VAT`, 48, 95); // Adjust X and Y positions as needed

      // Add table
      const tableOptions = {
        theme: "grid",
        startY: 100, // Adjust start Y position to accommodate the header and additional text
        headStyles: {
          fillColor: "13294B", // Light gray color with low opacity
          textColor: "#FFFFFF", // Set header text color
          fontStyle: "bold",
        },
        bodyStyles: {
          textColor: "#000000", // Set body text color
        },
        head: [["Service", "Discounts", "Promotions and Credits", "Cost"]],
        body: filteredData.map((service) => [
          service.Service,
          service.Discounts,
          service.PromotionsAndOthers,
          service.CustomerCost,
        ]),
        styles: {
          font: "helvetica",
          fontSize: 10,
          cellPadding: 5,
        },
        margin: { top: 10 },
      };
      doc.autoTable(tableOptions);

      // Save the PDF
      doc.save("service_details.pdf");
    };
    logoImg.src = nagarrologo; // Provide the path to your logo image
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Header />
          <Box sx={{ minHeight: "80vh" }}>
            {status && statusCode && (
              <Snack
                open={snackbarOpen}
                status={statusCode}
                data={status}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                handleClose={handleCloseSnackbar}
              />
            )}
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, margin: "10px", justifyItems: "flex-start" }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  sx={{ minHeight: 0 }}
                  variant="scrollable"
                >
                  <Tab
                    label="Home"
                    value="back"
                    component={Link}
                    to={`/gcp/billingdashboard`}
                    iconPosition="start"
                    icon={<HomeIcon />}
                  />
                  <Tab
                    label={key.split("_")[0]}
                    value="back"
                    component={Link}
                    to={`/gcp/billingdashboard/${key}`}
                    iconPosition="start"
                    icon={<CloudQueueIcon />}
                  />
                  <Tab
                    label={customername}
                    value="customer"
                    component={Link}
                    to={`/gcp/billingdashboard/${key}/${customername}`}
                    iconPosition="start"
                    icon={<GroupIcon />}
                  />
                  <Tab
                    label={projectname}
                    value="project"
                    component={Link}
                    iconPosition="start"
                    icon={<WorkIcon />}
                  />
                </Tabs>
              </div>
              <div style={{ margin: "10px", justifyItems: "flex-end" }}>
                <TextField
                  sx={{ minWidth: 200, marginTop: 5, marginRight: 2 }}
                  value={currency}
                  variant="standard"
                />
                {/* <FormControl>
                  <Select
                    sx={{ minWidth: 200, marginTop: 5 }}
                    value={currentDate}
                    onChange={handleInvoiceDateChange}
                    displayEmpty
                    variant="standard"
                  >
                    {filteredData2 &&
                      filteredData2.map((row) => (
                        <MenuItem key={row.InvoiceDate} value={row.InvoiceDate}>
                          {row.InvoiceDate}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
                <DatePicker
                  className="datepicker"
                  label="Select Month and Year"
                  showMonthYearPicker
                  selected={currentDate}
                  value={formatDateToDisplay(currentDate)}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      variant="filled"
                      {...params}
                      style={{ minWidth: 100, marginRight: "20px" }}
                    />
                  )}
                  maxDate={maxAllowedDate}
                  minDate={minAllowedDate}

                />
              </div>
            </div>

            <React.Fragment>
              <TableContainer
                sx={{ boxShadow: "0px 4px 8px rgba(186, 220, 211, 2)" }}
              >
                <Table aria-label="service-details">
                  <TableHead>
                    <TableRow>
                      <TableCell style={tableHeadStyle}>Service</TableCell>
                      {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}
                      <TableCell
                        style={{
                          ...tableHeadStyle,
                          textAlign: loading ? " " : "right",
                        }}
                      >
                        Net Cost
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableHeadStyle,
                          textAlign: loading ? " " : "right",
                        }}
                      >
                        Discounts
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableHeadStyle,
                          textAlign: loading ? " " : "right",
                        }}
                      >
                        Promotions and Credits
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableHeadStyle,
                          textAlign: loading ? " " : "right",
                        }}
                      >
                        Customer Cost
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {loading
                    ? Array.from({ length: 2 }).map((_, index) => (
                        <CustomSkeleton
                          key={index}
                          numColumns={6}
                          service={6}
                        />
                      ))
                    : filteredData && (
                        <TableBody>
                          {filteredData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((service, idx) => (
                              <TableRow key={idx}>
                                <TableCell style={tableElementStyle}>
                                  {service.Service}
                                </TableCell>
                                {/* <TableCell style={tableElementStyle}>
                          {currency}
                        </TableCell> */}
                                <TableCell
                                  style={{
                                    ...tableElementStyle,
                                    textAlign: "right",
                                  }}
                                >
                                  {getSymbolFromCurrency(currency)}{" "}
                                  {service.Cost}
                                </TableCell>
                                <TableCell
                                  style={{
                                    ...tableElementStyle,
                                    textAlign: "right",
                                  }}
                                >
                                  {service.Discounts}
                                </TableCell>
                                <TableCell
                                  style={{
                                    ...tableElementStyle,
                                    textAlign: "right",
                                  }}
                                >
                                  {service.PromotionsAndOthers}
                                </TableCell>
                                <TableCell
                                  style={{
                                    ...tableElementStyle,
                                    textAlign: "right",
                                  }}
                                >
                                  {getSymbolFromCurrency(currency)}{" "}
                                  {service.CustomerCost}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      )}
                </Table>
              </TableContainer>
              {filteredData && filteredData.length > 5 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                />
              )}
            </React.Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {filteredData && filteredData.length>0 &&(
              <Button
                onClick={exportToPDF}
                variant="contained"
                color="primary"
                size="small"
                style={{
                  backgroundColor: "#47D7AC", // Change background color to #47D7AC
                  color: "#13294B", // Change text color to #13294B
                  padding: "10px 15px", // Reduce padding to make it smaller
                  borderRadius: "32px", // Apply border-radius
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <CloudDownload style={{ marginRight: "5px" }} />
                Download PDF
              </Button>
              )}
            </div>
            {/* <Button sx={{marginTop:1}} component={Link} to={"/"} variant="outlined" color="primary">
          Back to main table
        </Button> */}
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default ServiceDetailsTable;
