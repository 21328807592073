import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';

const DescriptionComponent = ({ document }) => {
  const [showMore, setShowMore] = useState(false);
  const maxLines = 1;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const descriptionLines = document.details.description.split('\n');
  const displayLines = showMore ? descriptionLines : descriptionLines.slice(0, maxLines);

  return (
    <Typography variant="body1" fontFamily="Equip">
      <Typography variant="h6" fontFamily="EquipExtended">
        Description
      </Typography>
      <ul>
        {displayLines.map((line, index) => (
          <li key={index}>{line}</li>
        ))}
        {descriptionLines.length > maxLines && (
        <Button variant='text' onClick={toggleShowMore}>
          {showMore ? 'Read Less' : 'Read More'}
        </Button>
      )}
      </ul>
      
    </Typography>
  );
};

export default DescriptionComponent;
