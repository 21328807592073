import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export default function Snack({
  open,
  status,
  data,
  anchorOrigin,
  handleClose,
}) {
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  let severity = "error"; // Default severity
  if (status === 200) {
    severity = "success";
  } else if (status === 400) {
    severity = "warning"; // Custom severity for status code 400
  }

  return (
    <Snackbar open={open} onClose={handleClose} anchorOrigin={anchorOrigin}>
      <Alert
        onClose={handleClose}
        variant="filled"
        severity={severity}
      >
        {data}
      </Alert>
    </Snackbar>
  );
}
