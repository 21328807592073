import React, { useEffect, useState } from "react";
import {
  DialogActions,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  serviceConnectionCreation,
  getServiceConnectionFromDB,
} from "../../../../backend/backend";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  TableContainer,
  Box,
  Button,
   CircularProgress,
} from "@mui/material";
// import InfoComponent from "../InfoComponent";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ServiceConnectionDeletion } from "../../../../backend/backend";
import { Cancel, CheckCircle } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import Snack from "../../../Components/Snack";
import PasswordIcon from "@mui/icons-material/Password";
import { useNavigate } from "react-router-dom";
function GcpForm() {
  const [defaultButton, setDefaultButton] = useState("GCP");
  const [defaultButton2, setDefaultButton2] = useState(
    "Create a new service connections"
  );
  const [status, setStatus] = useState(null);
  const tabIndex=2;
  const navigate=useNavigate();
  const [subscriptionId, setSubscriptionId] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [serviceConnName, setServiceConnName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [servicePrincipalId, setServicePrincipalId] = useState("");
  const [servicePrincipalKey, setServicePrincipalKey] = useState("");
  const [connectionEmail, setConnectionEmail] = useState("");
  const [projectName, setProjectName] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [existingServConn, setExistingServConn] = useState("");
  const username = sessionStorage.getItem("username");
  // const username = "aakash.goswami@coeenterprise.onmicrosoft.com";
  const name = sessionStorage.getItem("name");
  const [radioButtonVal, setRadioButtonVal] = useState("AWS");
  const [awsRegion, setAwsRegion] = useState("");
  const [azureConnection, setAzureConnection] = useState([]);
  const [awsConnection, setAwsConnection] = useState([]);
  const [gcpConnection, setGcpConnection] = useState([]);
  const [deletionStatus, setDeletionStatus] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleAwsRegion = (e) => {
    setAwsRegion(e.target.value);
  };

  const detailClear = () => {
    setSubscriptionId("");
    setSubscriptionName("");
    setServiceConnName("");
    setTenantId("");
    setServicePrincipalId("");
    setServicePrincipalKey("");
    setConnectionEmail("");
    setProjectName("");
  };

  const region_list = [
    { key: 1, value: "us-east-1" },
    { key: 2, value: "us-east-2" },
    { key: 3, value: "us-west-1" },
    { key: 4, value: "us-west-2" },
    { key: 5, value: "ap-south-1" },
    { key: 6, value: "ap-south-2" },
  ];
  const isSubmitDisabled =
    serviceConnName.length < 4 ||
    connectionEmail.length < 4 ||
    projectName.length < 4 ||
    servicePrincipalKey.length < 4;

  const handleClick = (e) => {
    
    setRadioButtonVal(e.target.value);
    setDefaultButton(e.target.value);
  };
  const handleClick2 = (e) => {
    setDefaultButton2(e.target.value);
  };

  const handleConnectionEmail = (e) => {
    setConnectionEmail(e.target.value);
  };

  const handleProjectName = (e) => {
    setProjectName(e.target.value);
  };

  const handleSubId = (e) => {
    setSubscriptionId(e.target.value);
  };

  const handleSubName = (e) => {
    setSubscriptionName(e.target.value);
  };

  const handleSerConnName = (e) => {
    setServiceConnName(e.target.value);
  };

  const handleTenant = (e) => {
    setTenantId(e.target.value);
  };

  const handleSerPrinId = (e) => {
    setServicePrincipalId(e.target.value);
  };

  const handleSerPrinKey = (e) => {
    setServicePrincipalKey(e.target.value);
  };

  const handleRefresh = () => {
    getServiceConnection(username);
  };

  const handleServiceConnDeletion = (e, serviceConnectionName) => {
    e.preventDefault();

    let body;
   
    if (radioButtonVal === "AZURE") {
      body = {
        service_connection_name: serviceConnectionName,
        cloud_provider: radioButtonVal,
        user_name: username,
      };
    } else if (radioButtonVal === "AWS") {
      body = {
        service_connection_name: serviceConnectionName,
        cloud_provider: radioButtonVal,
        user_name: username,
      };
    } else {
      body = {
        service_connection_name: serviceConnectionName,
        cloud_provider: radioButtonVal,
        user_name: username,
      };
    }

    if (body && body !== "") {
      (async () => {
        try {
          const data1 = await ServiceConnectionDeletion(body);
          
          // Access the returned data
          if (data1 !== null) {
            setDeletionStatus("Service Connection Deleted Successfully!!");
            setStatus(200);

            handleRefresh();
            // alert("Service Connection Deleted Successfully!!");
          } else {
            setDeletionStatus(
              "An error occurred while Deleting the pipeline connection please try again."
            );
            setStatus(500);
          }
        } catch (error) {
          // Handle any errors thrown during the fetch
          console.log("Error:", error);
        }
      })();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true); 
    let body;
    body = {
      subscription_id: subscriptionId,
      subscription_name: subscriptionName,
      service_connection_name: serviceConnName,
      tenant_id: tenantId,
      service_principal_id: servicePrincipalId,
      cloud_provider_name: defaultButton,
      service_principal_key: servicePrincipalKey,
      aws_region: awsRegion,
      service_connection_email: connectionEmail,
      project_name: projectName,
      username: username,
      name: name,
    };
    if (body) {
      (async () => {
        try {
          const data1 = await serviceConnectionCreation(body);
          // Access the returned data
          if (data1 !== null) {
            if (data1.status === 200) {
              setServiceStatus("service connection created successfully!!");
              setStatus(200);
           
              detailClear();
              setSnackbarOpen(true);
              navigate(`/coe-portal/architecture-template/MyConnections?tab=${tabIndex}`);
            }
          } else {
            setServiceStatus(
              "service connection not created some error occur!!"
            );
            setStatus(500);
          }
        } catch (error) {
          // Handle any errors thrown during the fetch
          console.log("Error:", error);
        }finally {
      setSubmitLoading(false);
    }
      })();
    } else {
      
    }
  };
  async function getServiceConnection(username) {
    try {
      const data1 = await getServiceConnectionFromDB(username);
      // Access the returned data
      
      if (data1 !== null) {
        // setPipelineConnections(data1);
        data1.map((element) => {
          setGcpConnection(element.serviceConnection);
        });
      } else {
        // setPipelineConnections(null);
      }
    } catch (error) {
      // Handle any errors thrown during the fetch
      console.log(error);
    }
  }

  useEffect(() => {
    getServiceConnection(username);
  }, []);
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  
  return (
    <>
      {serviceStatus && status && (
        <Snack
        open={snackbarOpen}
          status={status}
          data={serviceStatus}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          handleClose={handleCloseSnackbar}
        />
      )}
      <React.Fragment>
        <Box>
          <TextField
            label="Service Connection Name"
            name="serviceConnectionName"
            value={serviceConnName}
            onChange={handleSerConnName}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Tooltip title="Name of Service Connection to be created">
                  <IconButton size="small">
                    <CheckCircle />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />

          <TextField
            label="Client Email"
            name="connectionEmail"
            value={connectionEmail}
            onChange={handleConnectionEmail}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Tooltip title="Service account email (Can be otained from JSON File)">
                  <IconButton size="small">
                    <EmailIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />

          <TextField
            label=" Project Id"
            name="projectName"
            value={projectName}
            onChange={handleProjectName}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Tooltip title="Your GCP project ID">
                  <IconButton size="small">
                    <CheckCircle />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />

          <TextField
            label="Private Key"
            name="servicePrincipalKey"
            value={servicePrincipalKey}
            onChange={handleSerPrinKey}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Tooltip title="Your Service account private key(Can be otained from JSON File)">
                  <IconButton size="small">
                    <PasswordIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Cancel />}
              onClick={detailClear}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitDisabled || submitLoading}
              onClick={handleSubmit}
            >
                 {submitLoading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </React.Fragment>
    </>
  );
}

export default GcpForm;
