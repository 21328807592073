import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import {
  serviceConnectionCreation,
  getServiceConnectionFromDB,
} from "../../../../backend/backend";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  TableContainer,
  Box,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// Material Dashboard 2 React components
import swal from "sweetalert";
import { ServiceConnectionDeletion } from "../../../../backend/backend";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
const styles = {
  tableContainer: {
    maxWidth: "100%" 
  },
  tableHead: {
    backgroundColor: "#F8F8FF",
  },
  tableCell: {
    fontFamily: "Equip",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    whiteSpace: "nowrap", 
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  deleteButton: {
    color: "#f44336",
  },
};
const TableWrapper = styled("div")({
  width: "100%",
  overflowX: "auto",
});

function AwsTable() {
  const [defaultButton, setDefaultButton] = useState("AWS");
  const [defaultButton2, setDefaultButton2] = useState(
    "Create a new service connections"
  );
  const [status, setStatus] = useState(null);
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [serviceConnName, setServiceConnName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [servicePrincipalId, setServicePrincipalId] = useState("");
  const [servicePrincipalKey, setServicePrincipalKey] = useState("");
  const [connectionEmail, setConnectionEmail] = useState("");
  const [projectName, setProjectName] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [existingServConn, setExistingServConn] = useState("");
  const username = sessionStorage.getItem("username");
  // const username = "aakash.goswami@coeenterprise.onmicrosoft.com";
  const name = sessionStorage.getItem("name");
  const [radioButtonVal, setRadioButtonVal] = useState("AWS");
  const [awsRegion, setAwsRegion] = useState("");
  const [azureConnection, setAzureConnection] = useState([]);
  const [awsConnection, setAwsConnection] = useState([]);
  const [gcpConnection, setGcpConnection] = useState([]);
  const [deletionStatus, setDeletionStatus] = useState("");
  const [deletingRowIndex, setDeletingRowIndex] = useState(null);
  const handleAwsRegion = (e) => {
    setAwsRegion(e.target.value);
  };

  const detailClear = () => {
    setSubscriptionId("");
    setSubscriptionName("");
    setServiceConnName("");
    setTenantId("");
    setServicePrincipalId("");
    setServicePrincipalKey("");
    setConnectionEmail("");
    setProjectName("");
  };

  const region_list = [
    { key: 1, value: "us-east-1" },
    { key: 2, value: "us-east-2" },
    { key: 3, value: "us-west-1" },
    { key: 4, value: "us-west-2" },
    { key: 5, value: "ap-south-1" },
    { key: 6, value: "ap-south-2" },
  ];
  const handleClick = (e) => {
    setRadioButtonVal(e.target.value);
    setDefaultButton(e.target.value);
  };
  const handleClick2 = (e) => {
    setDefaultButton2(e.target.value);
  };

  const handleConnectionEmail = (e) => {
    setConnectionEmail(e.target.value);
  };

  const handleProjectName = (e) => {
    setProjectName(e.target.value);
  };

  const handleSubId = (e) => {
    setSubscriptionId(e.target.value);
  };

  const handleSubName = (e) => {
    setSubscriptionName(e.target.value);
  };

  const handleSerConnName = (e) => {
    setServiceConnName(e.target.value);
  };

  const handleTenant = (e) => {
    setTenantId(e.target.value);
  };

  const handleSerPrinId = (e) => {
    setServicePrincipalId(e.target.value);
  };

  const handleSerPrinKey = (e) => {
    setServicePrincipalKey(e.target.value);
  };

  const handleRefresh = () => {
    getServiceConnection(username);
  };
  const handleServiceConnDeletion = async (
    e,
    serviceConnectionName,
    rowIndex
  ) => {
    e.preventDefault();

    try {
      setDeletingRowIndex(rowIndex);
      setDeletionInProgress(true);
      const confirmation = await swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this service connection!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (confirmation) {
        const body = {
          service_connection_name: serviceConnectionName,
          cloud_provider: radioButtonVal,
          user_name: username,
        };

        const deletionResult = await ServiceConnectionDeletion(body);

        if (deletionResult) {
          setDeletionStatus("Service Connection Deleted Successfully!!");
          setStatus(200);

          // Refresh the table by fetching updated data
          await getServiceConnection(username);

          swal("Poof! Your service connection has been deleted!", {
            icon: "success",
          });
        } else {
          setDeletionStatus(
            "An error occurred while Deleting the pipeline connection please try again."
          );
          setStatus(500);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setDeletingRowIndex(null);
      setDeletionInProgress(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let body;
    if (defaultButton === "AZURE") {
      body = {
        subscription_id: subscriptionId,
        subscription_name: subscriptionName,
        service_connection_name: serviceConnName,
        tenant_id: tenantId,
        service_principal_id: servicePrincipalId,
        cloud_provider_name: defaultButton,
        service_principal_key: servicePrincipalKey,
        aws_region: awsRegion,
        service_connection_email: connectionEmail,
        project_name: projectName,
        username: username,
        name: name,
      };
    } else if (defaultButton === "AWS") {
      body = {
        subscription_id: subscriptionId,
        subscription_name: subscriptionName,
        service_connection_name: serviceConnName,
        tenant_id: tenantId,
        service_principal_id: servicePrincipalId,
        cloud_provider_name: defaultButton,
        service_principal_key: servicePrincipalKey,
        aws_region: awsRegion,
        service_connection_email: connectionEmail,
        project_name: projectName,
        username: username,
        name: name,
      };
    } else {
      body = {
        subscription_id: subscriptionId,
        subscription_name: subscriptionName,
        service_connection_name: serviceConnName,
        tenant_id: tenantId,
        service_principal_id: servicePrincipalId,
        cloud_provider_name: defaultButton,
        service_principal_key: servicePrincipalKey,
        aws_region: awsRegion,
        service_connection_email: connectionEmail,
        project_name: projectName,
        username: username,
        name: name,
      };
    }
    if (body) {
      (async () => {
        try {
          const data1 = await serviceConnectionCreation(body);
          // Access the returned data
          if (data1 !== null) {
            if (data1.status === 200) {
              setServiceStatus("service connection created successfully!!");
              setStatus(200);
              detailClear();
            }
          } else {
            setServiceStatus(
              "service connection not created some error occur!!"
            );
            setStatus(500);
          }
        } catch (error) {
          // Handle any errors thrown during the fetch
          console.log("Error:", error);
        }
      })();
    } else {
      
    }
  };
  async function getServiceConnection(username) {
    try {
      const data1 = await getServiceConnectionFromDB(username);
      // Access the returned data
      if (data1 !== null) {
        // setPipelineConnections(data1);
        data1.map((element) => {
          if (element.cloud === "AZURE") {
            setAzureConnection(element.serviceConnection);
          } else if (element.cloud === "AWS") {
            setAwsConnection(element.serviceConnection);
          } else {
            setGcpConnection(element.serviceConnection);
          }
        });
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getServiceConnection(username);
  }, []);

  return (
    <React.Fragment>
    <Box p={0}>
      
      <TableContainer style={styles.tableContainer}>
        <Table>
          <TableHead style={styles.tableHead}>
            <TableRow>
              <TableCell style={styles.tableCell}>S.No</TableCell>
              <TableCell style={styles.tableCell}>
                Service Connection Name
              </TableCell>
              <TableCell style={styles.tableCell}>Access Key</TableCell>
              <TableCell style={styles.tableCell}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {awsConnection.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item?.serviceConnectionName}</TableCell>
                <TableCell>{item?.access_key}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={(event) =>
                      handleServiceConnDeletion(
                        event,
                        item.serviceConnectionName,
                        index
                      )
                    }
                    disabled={deletingRowIndex === index}
                    style={styles.deleteButton}
                  >
                    {deletingRowIndex === index ? (
                      <CircularProgress size={24} />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
   
    </Box>
    </React.Fragment>
  );
}

export default AwsTable;
