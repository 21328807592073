import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import InfoIcon from "@mui/icons-material/Info";
import FeedbackIcon from "@mui/icons-material/Feedback";
import nagarroLogo from "../../assets/images/nagarrologo.png";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import HubIcon from "@mui/icons-material/Hub";
import IconButton from "@mui/material/IconButton";
import BroadcastOnPersonalIcon from "@mui/icons-material/BroadcastOnPersonal";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import AddIcon from "@mui/icons-material/Add";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Tooltip, Typography, useMediaQuery } from "@mui/material";
import CloudIcon from '@mui/icons-material/Cloud';
const drawerWidth = 240;
const logoHeight = 30;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  overflow: "hidden",
  backgroundColor: "#13294B",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      borderBottomRightRadius: 20,
      backgroundColor: "#13294B",
      color: "#ffffff",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      borderBottomRightRadius: 20,
      backgroundColor: "#13294B",
      color: "#ffffff",
    },
  }),
}));
export default function Sidenav() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const handleClick = () => {
    setOpenList(!openList);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    if (!isSmallScreen) {
      // Only toggle on larger screens
      setOpen(!open);
    }
  };
  const folderName = location.pathname.split("/")[1];
  // console.log(folderName)
  const handleNavigate = () => {
    // console.log(location.pathname)
    if (folderName === "aws") {
      navigate("/billingdashboard");
    } else if(folderName === "gcp"){
      navigate("/billingdashboard");
    }
    else{
      navigate("/billingdashboard");
    }
    setOpen(false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}></AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={open ? "drawerOpen" : "drawerClosed"}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <DrawerHeader>
          {" "}
          {open ? (
            <>
              <img
                src={nagarroLogo}
                style={{ height: 30, verticalAlign: "middle", marginleft: 2 }}
                alt="Nagarro Logo"
              />
              <Typography
                variant="body1"
                sx={{ margin: 1, fontFamily: "Equip" }}
              >
               Cloud Practice
              </Typography>
            </>
          ) : (
            <></>
          )}
          <IconButton onClick={handleToggle}>
            {open ? (
              theme.direction === "rtl" ? (
                <ChevronRightIcon style={{ color: "#ffffff" }} />
              ) : (
                <MenuOpenIcon style={{ color: "#ffffff" }} />
              )
            ) : (
              <MenuIcon style={{ color: "#ffffff" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() =>  {handleNavigate()}}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          {folderName === "gcp" && (
    <ListItem
      disablePadding
      sx={{ display: "block" }}
      onClick={() => navigate("/aws/billingdashboard")}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          <CloudDoneIcon style={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText
          primary="AWS Billing Dashboard"
          sx={{ opacity: open ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  )}
  {folderName === "aws" && (
    <ListItem
      disablePadding
      sx={{ display: "block" }}
      onClick={() => navigate("/gcp/billingdashboard")}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          <CloudCircleIcon style={{ color: "#ffffff" }} />
        </ListItemIcon>
        <ListItemText
          primary="GCP Billing Dashboard"
          sx={{ opacity: open ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  )}
     {folderName === "billingdashboard" && (
      <div></div>)}
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate("/coe-portal")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <CloudIcon style={{ color: "#ffffff" }} />
              </ListItemIcon>
              <ListItemText
                primary="Back to Cloud Practice"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        {/* <Box sx={{ position: "absolute", bottom: 0, left: 0, width: drawerWidth, padding: 2 }}>
          <img
            src={nagarroLogo}
            style={{ height: logoHeight, verticalAlign: "middle", marginLeft: 2 }}
            alt="Nagarro Logo"
          />
        </Box> */}
      </Drawer>
    </Box>
  );
}
