import React, { useState } from "react";
import { Divider, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import CloudIcon from "@mui/icons-material/Cloud";
import aws from "../../../../assets/images/awsproviderlogo.png"
import azure from "../../../../assets/images/azureproviderlogo.png"
import gcp from "../../../../assets/images/gcpproviderlogo.png"
import container from "../../../../assets/images/containerproviderlogo.png"
export const Provider = ({ searchData }) => {
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [providers, setProviders] = useState([
    "AWS",
    "Microsoft Azure",
    "Google Cloud",
    "Containers",
  ]);

  function handleProviderClick(provider) {
    navigate(`/coe-portal/architecture-template/${provider}`);
  }

  const cardStyles = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "200px",
  };

  const imageStyles = {
    width: 100,
    height: 100,
    marginBottom: "8px",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#13294B",
            fontFamily: "EquipExtended",
            fontWeight: "400",
            fontStyle: "normal",
          }}
        >
          Browse by cloud technology
        </Typography>
      </div>
      <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <div
            className={`cardprovider ${hoveredCard === "AWS" ? "hovered" : ""}`}
            onClick={() => handleProviderClick("AWS")}
            onMouseEnter={() => setHoveredCard("AWS")}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <img
              src={aws}
              alt="Image"
            />
            <Typography
              variant="h5"
              sx={{
                color: "#13294B",
                fontFamily: "EquipExtended",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              AWS
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div
            className={`cardprovider ${
              hoveredCard === "AZURE" ? "hovered" : ""
            }`}
            onClick={() => handleProviderClick("AZURE")}
            onMouseEnter={() => setHoveredCard("AZURE")}
            onMouseLeave={() => setHoveredCard(null)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <img
              src={azure}
              alt="Image"
            />
            <Typography
              variant="h5"
              sx={{
                color: "#13294B",
                fontFamily: "EquipExtended",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Microsoft Azure
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div
            className={`cardprovider ${hoveredCard === "GCP" ? "hovered" : ""}`}
            onClick={() => handleProviderClick("GCP")}
            onMouseEnter={() => setHoveredCard("GCP")}
            onMouseLeave={() => setHoveredCard(null)}
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <img
              src={gcp}
              alt="Image"
            />
            <Typography
              variant="h5"
              sx={{
                color: "#13294B",
                fontFamily: "EquipExtended",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Google Cloud{" "}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <div
            className={`cardprovider ${
              hoveredCard === "CONTAINER" ? "hovered" : ""
            }`}
            onMouseEnter={() => setHoveredCard("CONTAINER")}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <img
              src={container}
              alt="Image"
            />
            <Typography
              variant="h5"
              sx={{
                color: "#13294B",
                fontFamily: "EquipExtended",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              Containers
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
