import React, { useState, useEffect } from "react";
import { Grid, Typography, Paper, Link, Divider } from "@mui/material";
import ResponsiveCard from "../ResponisveCard";
import { getSearchRecentData } from "../../../../backend/backend";
import ShowAllLink from "../ShowAllLink";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function RecentlyAdded({ searchData }) {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 1);
        const startDateISO = startDate.toISOString();
        const endDateISO = endDate.toISOString();
        const data1 = await getSearchRecentData(
          `Timestamp ge ${startDateISO} and Timestamp le ${endDateISO}`
        );
        if (data1 !== null) {
          setAllData(data1);
          setLoading(false);
        } else {
          setError(true);
          setAllData([]);
        }
      } catch (error) {
         
      }
    })();
  }, []);
  const maxDisplayItems = 4;
  const itemsToDisplay =
    allData.length <= maxDisplayItems
      ? allData
      : allData.slice(0, maxDisplayItems);
  return (
    <Paper
      elevation={0}
      sx={{
        padding: 2,
        position: "relative",
        background: "linear-gradient(to bottom,#F8F8FF, #E6E6FA)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={{
              color: "#13294B",
              fontFamily: "EquipExtended",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            Recently Added
          </Typography>
        </div>

        <ShowAllLink route="/coe-portal/architecture-template/ShowAll" data={allData} />
      </div>
      <Divider sx={{ marginTop: 2 }} />
      <Grid container spacing={2}>
        {itemsToDisplay.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <ResponsiveCard
              title={item.templateDisplayName}
              description={item.details.description}
              type={item.templateName}
              isPremium={item.tags[0]}
              isCoeVerified={item.tags[1]}
              templateStructureUrl={item.templateStructureUrl}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export default RecentlyAdded;
