import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Container,
  Typography,
  Card,
  CardContent,
  Divider,
  CardMedia,
  DialogContent,
} from "@mui/material";
import Header from "../../Components/Header";
import Sidenav from "../../Components/Sidenav";
import { useLocation } from "react-router-dom";
import gcpimage from "../../../assets/images/gcp.png";
import cloudImage from "../../../assets/images/cloud.jpg";
import azureStep1Image from "../../../assets/images/Azure/azure1.png";
import azureStep2Image from "../../../assets/images/Azure/azure2.png";
import azureStep4Image from "../../../assets/images/Azure/azure3.png";
import azureStep5Image from "../../../assets/images/Azure/azure4.png";
import azureStep6Image from "../../../assets/images/Azure/azure6.png";
import azureStep7Image from "../../../assets/images/Azure/azure7.png";
import azureStep10Image from "../../../assets/images/Azure/azure2.png";
import awsStep1Image from "../../../assets/images/Aws/aws1.png";
import awsStep5Image from "../../../assets/images/Aws/aws5.png";
import awsStep8Image from "../../../assets/images/Aws/aws8.png";
import gcpStep3Image from "../../../assets/images/Gcp/gcp3.png";
import gcpStep4Image from "../../../assets/images/Gcp/gcp4.png";
import gcpStep5Image from "../../../assets/images/Gcp/gcp5.png";
import gcpStep6Image from "../../../assets/images/Gcp/gcp6.png";
import gcpStep8Image from "../../../assets/images/Gcp/gcp8.png";
import Footer from "../../Components/Footer";
const ConnectionGuide = () => {
  const location = useLocation();
  const { title } = location.state;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const imageStyle = {
    width: isMobile ? "100%" : "54vw",
    height: isMobile ? "auto" : "40vh",
   
  };

  // Define content based on the title
  let content = null;
  if (title === "AZURE service connection creation") {
    content = (
      <Card>
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              margin: 2,
              color: "#13294B",
              fontFamily: "Equip",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            AZURE service connection creation
          </Typography>
          <Typography
            variant="body1"
            sx={{
              margin: 2,
              color: "#13294B",
              fontFamily: "Equip",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            To create a AZURE service connection creation, follow these steps:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              margin: 2,
              color: "#13294B",
              fontFamily: "Equip",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            <Typography
              variant="body"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              1. Open Azure Active Directory in your Azure account.
            </Typography>
            <div style={{ margin: "2rem" }}>
              <img
                src={azureStep1Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <Typography
              variant="body"
              style={{ fontFamily: "Equip", marginBottom: "2" }}
            >
              2. Create a new app registration (also known as service principal)
              in Azure AD.
            </Typography>
            <div style={{ margin: "2rem" }}>
              <img
                src={azureStep2Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "30%", height: "30%" }}
              />
            </div>
            <Divider />
            <Typography
              variant="body1"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              3. Click on "New registration" and enter the required details:
              <ol type="a">
                <li>
                  {" "}
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Enter app registration name.{" "}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Select Supported account type according to the tenant of
                    your subscription.{" "}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Click "Register".{" "}
                  </Typography>
                </li>
              </ol>
            </Typography>

            <Typography
              variant="body"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              4. Search for your subscription on the Azure portal and click on
              "Access Control."
            </Typography>
            <div style={{ margin: "2rem" }}>
              <img
                src={azureStep4Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <Typography
              variant="body"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              5. Click on "+ ADD" and then "Add role assignment."
            </Typography>
            <div style={{ margin: "2rem" }}>
              <img
                src={azureStep5Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <Typography
              variant="body"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              6. Switch to "Privileged administrator" role and assign
              contributor role, then click next.
            </Typography>
            <div style={{ margin: "2rem" }}>
              <img
                src={azureStep6Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <Typography
              variant="body"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              7. Click on "Select members" and enter your app registration name
              from step 3.
            </Typography>
            <div style={{ margin: "2rem" }}>
              <img
                src={azureStep7Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <Typography
              variant="body1"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              8. Click on "Review + assign" to complete the assignment.
            </Typography>

            <Typography
              variant="body1"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              9. Go back to Azure Active Directory app registrations > All
              Registrations and search for your app registration.
            </Typography>
            <div style={{ margin: "2rem" }}>
              <img
                src={azureStep10Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "30%", height: "auto" }}
              />
            </div>
            <Typography
              variant="body1"
              style={{
                fontFamily: "Equip",
                margin: "1rem 0 ",
              }}
            >
              10. On the app registration page, click on "Client credentials"
              and then "Create a new secret."
            </Typography>

            <Typography
              variant="body1"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              11. This secret value will be used while creating a service
              connection in the accelerator.
              {/* <img src={azureStep2Image} alt="Step 10 Screenshot" /> */}
            </Typography>

            <Typography
              variant="body1"
              style={{ fontFamily: "Equip", margin: "1rem 0" }}
            >
              12. Details required to create a service connection:
              <ol type="a">
                <li>
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Service connection name - name of the service connection
                    that will be created.{" "}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Subscription ID - Obtained from Azure portal.{" "}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Subscription Name - Obtained from Azure portal.
                  </Typography>
                </li>
                <li>
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Tenant ID - Can be obtained from the overview of the app
                    registration in Azure portal (also called directory ID).
                  </Typography>
                </li>
                <li>
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Service principal ID - Can be obtained from the overview of
                    the app registration in Azure portal (also called clientID).{" "}
                  </Typography>
                </li>
                <li>
                  {" "}
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ fontFamily: "Equip", margin: "1rem" }}
                  >
                    Service Principal Key - Generated in step 11.
                  </Typography>
                </li>
              </ol>
            </Typography>
          </Typography>
        </CardContent>
      </Card>
    );
  } else if (title === "AWS service connection creation") {
    content = (
      <Card>
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              margin: 2,
              color: "#13294B",
              fontFamily: "Equip",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            AWS service connection creation
          </Typography>
          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            1. Login to AWS console and create a new user in IAM.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            2. Enter the Username of the user to be created.
          </Typography>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            3. Click on "Attach Policies Directly" and select
            "AdministratorAccess".
          </Typography>
          <div style={{ margin: "2rem" }}>
            <img
              src={awsStep1Image}
              alt="Step 3.1 Screenshot"
              style={{ width: "100%", height: "auto" }}
            />
          </div>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            4. Click "Next" and then click "Create User".
          </Typography>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            5. In the "Users" Tab in IAM, click the user that was created in
            step 4.
          </Typography>
          <div style={{ margin: "2rem" }}>
            <img
              src={awsStep5Image}
              alt="Step 3.1 Screenshot"
              style={{ width: "100%", height: "auto" }}
            />
          </div>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            6. Switch to the "Security Credentials" tab and scroll down to the
            "Access Key" Section.
          </Typography>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            7. Click on "Create Access Key".
          </Typography>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            8. Click on "Other" in usecase.
          </Typography>
          <div style={{ margin: "2rem" }}>
            <img
              src={awsStep8Image}
              alt="Step 3.1 Screenshot"
              style={{ width: "100%", height: "auto" }}
            />
          </div>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            9. Enter a description and click on "Create Access Key".
          </Typography>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            10. Keep a note of the access keys and secret access keys that will
            be shown or download a CSV file.
          </Typography>

          <Typography
            gutterBottom
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
          >
            11. Details required to create a service connection:
            <ol style={{ marginLeft: "1rem" }}>
              <li>
                <Typography
                  gutterBottom
                  variant="body1"
                  style={{ fontFamily: "Equip", margin: "1rem" }}
                >
                  Service connection name - name of the service connection that
                  will be created.
                </Typography>
              </li>
              <li>
                <Typography
                  gutterBottom
                  variant="body1"
                  style={{ fontFamily: "Equip", margin: "1rem" }}
                >
                  Region - Can be selected from the dropdown.
                </Typography>
              </li>
              <li>
                {" "}
                <Typography
                  gutterBottom
                  variant="body1"
                  style={{ fontFamily: "Equip", margin: "1rem" }}
                >
                  Access Key - obtained in step 10.{" "}
                </Typography>
              </li>
              <li>
                {" "}
                <Typography
                  gutterBottom
                  variant="body1"
                  style={{ fontFamily: "Equip", margin: "1rem" }}
                >
                  Secret Access Key - obtained in step 10.{" "}
                </Typography>
              </li>
            </ol>
          </Typography>
        </CardContent>
      </Card>
    );
  } else if (title === "GCP service connection creation") {
    content = (
      <Card>
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              margin: 2,
              color: "#13294B",
              fontFamily: "Equip",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            GCP service connection creation
          </Typography>
          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            1. Open GCP cloud dashboard.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            2. Go to IAM & Admin in your project.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            3. Click on Service account.
          </Typography>
          <div style={{ margin: "2rem" }}>
              <img
                src={gcpStep3Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "30%", height: "30%" }}
              />
            </div>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            4. Click on create service account.
          </Typography>
          <div style={{ margin: "2rem" }}>
              <img
                src={gcpStep4Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "150%", height: "150%" }}
              />
            </div>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            5. Enter required details like name and description.
          </Typography>
          <div style={{ margin: "2rem" }}>
              <img
                src={gcpStep5Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "70%", height: "70%" }}
              />
            </div>
          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            6. Add a basic > editor role to the service account and click Done.
          </Typography>
          <div style={{ margin: "2rem" }}>
              <img
                src={gcpStep6Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "70%", height: "30%" }}
              />
            </div>
          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            7. Go to the homepage of the service account and search for your
            service account.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            8. Click on the email of your service account and go to the keys
            tab.
          </Typography>
          <div style={{ margin: "2rem" }}>
              <img
                src={gcpStep8Image}
                alt="Step 3.1 Screenshot"
                style={{ width: "150%", height: "150%" }}
              />
            </div>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            9. Click on Add Key and create a JSON Key.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            10. A JSON file will be downloaded that will contain the required
            information to create a service connection.
          </Typography>

          <Typography
            variant="body1"
            style={{ fontFamily: "Equip", margin: "1rem" }}
            gutterBottom
          >
            11. Details required to create a service connection:
            <ol style={{ marginLeft: "1rem" }}>
              <li>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Equip", margin: "1rem" }}
                  gutterBottom
                >
                  Service connection name - name of the service connection that
                  will be created.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Equip", margin: "1rem" }}
                  gutterBottom
                >
                  Client Email - can be obtained from the JSON file generated in
                  Step 10.{" "}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Equip", margin: "1rem" }}
                  gutterBottom
                >
                  Project ID - can be obtained from the GCP dashboard homepage.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  style={{ fontFamily: "Equip", margin: "1rem" }}
                  gutterBottom
                >
                  Private Key - can be obtained from the JSON file generated in
                  Step 10.
                </Typography>
              </li>
            </ol>
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Sidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 1 ,minWidth:80}}>
        <Header />
        <Container maxWidth="lg" sx={{ marginTop: 2 }}>
          <Paper
            elevation={0}
            sx={{
             
              overflow: "hidden",
              backgroundColor: "rgba(240, 249, 253, 0.543)",
              backgroundSize: "60%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              "@media (max-width: 600px)": {
                backgroundImage: "none",
              },
            }}
          >
            {content}
          </Paper>
        </Container>
        <Footer/>
      </Box>
    </Box>
  );
};

export default ConnectionGuide;
