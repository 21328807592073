import React, { useState } from "react";
import { Tabs, Tab, Paper, Box } from "@mui/material";
import AwsForm from "./Forms/AwsForm";
import AzureForm from "./Forms/AzureForm";
import GcpForm from "./Forms/GcpForm";
import Header from "../../Components/Header";
import Sidenav from "../../Components/Sidenav";
import { Container } from "@mui/system";
import Footer from "../../Components/Footer";

const CreateServiceConnectionForm = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ display: "flex" }}>
    <Sidenav />
    <Box component="main" sx={{ flexGrow: 1, p: 1  }}>
      <Header />
      <Container maxWidth="lg" sx={{minHeight:"80vh"}}>
    <Paper elevation={3} sx={{marginTop:2}}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="AWS" />
        <Tab label="Azure" />
        <Tab label="GCP" />
      </Tabs>
      <Box p={3}>
        {tabValue === 0 && <AwsForm />}
        {tabValue === 1 && <AzureForm />}
        {tabValue === 2 && <GcpForm />}
      </Box>
    </Paper>
    </Container>
    <Footer />
    </Box>
   
    </Box>
   
  );
};

export default CreateServiceConnectionForm;
