import { Box, Typography } from "@mui/material";

const ErrorPage = () => {
  const handleRetry = () => {};
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        textAlign="center"
      >
        <Typography
          variant="h4"
          color="#47D7AC"
          fontFamily="EquipExtended"
          gutterBottom
        >
          Oops! An error occurred while fetching data.
        </Typography>
        <Typography
          variant="body1"
          color="#13294B"
          fontFamily="Equip"
          gutterBottom
        >
          Please try again or check your internet connection.
        </Typography>
      </Box>
    </>
  );
};

export default ErrorPage;
