// CustomerTable.js
import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Snack from "../../ArchitectureTemplate/Components/Snack"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import TableNavigation from "./TableNavigation";
import parseCsvAndAggregate from "./Aggregation";
import { ArrowBack, ArrowBackOutlined, Home } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import HomeIcon from '@mui/icons-material/Home';
import NotFoundPage from "../components/NotFoundPage ";
import getSymbolFromCurrency from 'currency-symbol-map'
import Header from "../components/Header";
import {maxAllowedDate} from "../dateConstants.js"
import {minAllowedDate} from "../dateConstants.js"
import Footer from "../../ArchitectureTemplate/Components/Footer";
import Sidenav from "../components/Sidenav";
import CustomSkeleton from "../components/CustomSkeleton";
const CustomerDetailsTable = () => {
  const { previousPath, mainPath, nextPath } = useParams();
  
  const { key } = useParams();
  const location = useLocation();
  const rowData = JSON.parse(location?.state);
  const [expandedRow, setExpandedRow] = useState(null);
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [filteredData2, setFilteredData2] = useState(null);
  const region = key.split("_")[0];
  const currentDate = key.split("_")[1];
   
  const [selectedTab, setSelectedTab] = useState("maintable");
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState("");
   
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Number of items per page
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currency, setCurrency]=useState("")
  const [loading, setLoading] = useState(true);
  const [status,setStatus]=useState("");
  const[statusCode,setStatusCode]=useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleRowClick = (key, key2, row) => {
     
    setExpandedRow(expandedRow === key ? null : key);
    navigate(`/gcp/billingdashboard/${key2}/${key}`, { state: JSON.stringify(row) });

     
    // setExpandedCustomer(null);
    // setExpandedProject(null);
  };
  useEffect(() => {
    // Call the parseCsvToJson function and pass a callback to set the state
    parseCsvAndAggregate((data) => {
      setJsonData(data);
      setLoading(false)
    });
  }, []);

  useEffect(() => {
    if (jsonData) {
       
      const filteredData = jsonData.filter((row) => row.key === key);
      setFilteredData(filteredData);
      // console.log("Filtered Data:", filteredData);
      // setCurrency(filteredData[0].Currency)
      if (filteredData.length > 0) {
        setCurrency(filteredData[0].Currency);
        
      } else {
        setCurrency("Filter by Currency"); // Set currency to empty string if no record found
        setStatus("No data available");
        setStatusCode(403);
        setSnackbarOpen(true);
      }
    }
  }, [jsonData, key]);
  useEffect(() => {
    if (jsonData) {
      const filteredData = jsonData.filter(
        (row) => row.key.split("_")[0] === region
      );
      setFilteredData2(filteredData);
      // Extracting unique invoice dates from filtered data
    
      const uniqueInvoiceDates = [
        ...new Set(filteredData.map((row) => row.InvoiceDate)),
      ];
      setSelectedInvoiceDate(uniqueInvoiceDates[0]); // Set the default selected invoice date
    }
  }, [jsonData, region]);
  const tableHeadStyle = {
    fontWeight: 'bold',
    color: '#13294B',
    fontFamily: 'EquipExtended',
    fontSize: 14,
   
};
const tableElementStyle = {
    fontFamily: 'Equip',
    fontSize: 14,
    fontWeight: 400,
   
};
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleInvoiceDateChange = (event) => {
    setSelectedInvoiceDate(event.target.value);
 sessionStorage.setItem('selectedInvoiceDate', event.target.value)
    navigate(`/gcp/billingdashboard/${region}_${event.target.value}`);
  };
  // const pageCount = Math.ceil(filteredData && filteredData[0].Customers.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = page * rowsPerPage;
// const endIndex = Math.min(startIndex + rowsPerPage, filteredData && filteredData[0].Customers.length);
const handleChangePage = (event, newPage) => {
  setPage(newPage); // Update page state for TablePagination component
};

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  function formatDateToDisplay(dateString) {
    const date = new Date(dateString);
    const options = {  month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
}
function getLastDayOfMonth(date) {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const lastDay = new Date(year, month + 1, 0);
  return `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;
}

const handleDateChange = (newDate) => {
  const lastDayOfMonth = getLastDayOfMonth(newDate);
  // console.log(lastDayOfMonth)
const inputdate=newDate.toISOString().split("T")[0]
  setSelectedInvoiceDate(lastDayOfMonth);
  // console.log(inputdate);
  sessionStorage.setItem("selectedInvoiceDate", lastDayOfMonth);
  
    navigate(`/gcp/billingdashboard/${region}_${lastDayOfMonth}`);
  // console.log(newDate)
  // const inputdate=newDate.toISOString().split("T")[0]
  // setStartDate(inputdate);
  
  // // Calculate the start date as the first day of the previous month
  // const currentMonth = new Date(newDate);
  // currentMonth.setMonth(currentMonth.getMonth() + 1);
  // currentMonth.setDate(1); // Set to the first day of the month

  // const formattedStartDate = formatDate(currentMonth);
  // // console.log(currentMonth)
  // setEndDate(formattedStartDate);
  
 
};
const handleCloseSnackbar = () => {
  setSnackbarOpen(false);
};
  return (
    <>
    <Box sx={{ display: "flex" }}>
        <Sidenav/>
       
        <Box  component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Header />
        <Box sx={{minHeight:"80vh"}}>
        {status && statusCode && (
            <Snack
              open={snackbarOpen}
              status={statusCode}
              data={status}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              handleClose={handleCloseSnackbar}
            />
          )}
        <div style={{ display: "flex" }}>
          <div style={{flex:1, margin: "10px", justifyItems: "flex-start" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              textColor="primary"
              indicatorColor="primary"
              
            >
            
              <Tab label="Home" 
               iconPosition="start"
               icon={<Home />}
              value="back" component={Link} to={`/gcp/billingdashboard`} />
              <Tab
                label={key.split("_")[0]}
                value={"maintable"}
                component={Link}
                iconPosition="start"
                icon={<CloudQueueIcon />}
              />
            </Tabs>
          </div>
          <div style={{margin: "10px", justifyItems: "flex-end" }}>
        {currency !== undefined && (
  <TextField
    sx={{ minWidth: 200, marginTop: 5, marginRight: 2 }}
    value={currency}
    variant="standard"
  />
)}
            {/* <FormControl >
              <Select
               sx={{ minWidth: 200, marginTop:5  }}
                value={currentDate}
                onChange={handleInvoiceDateChange}
                displayEmpty
                variant="standard"
               
              >
                {filteredData2 &&
                  filteredData2.map((row) => (
                    <MenuItem key={row.InvoiceDate} value={row.InvoiceDate}>
                      {row.InvoiceDate}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
            <DatePicker className="datepicker"
                     
                     label="Select Month and Year"
                     showMonthYearPicker
                     
                     selected={currentDate}
                     value={formatDateToDisplay(currentDate)}
                 
                     onChange={handleDateChange}
                     renderInput={(params) => <TextField variant="filled" {...params} 
                   
                     style={{minWidth:100,marginRight:"20px"}}
                     />}
                     maxDate={maxAllowedDate}
                     minDate={minAllowedDate}
                   />
          </div>
        </div>
        <TableContainer
          sx={{ boxShadow: "0px 4px 8px rgba(186, 220, 211, 2)" }}
        >
          <Table aria-label="customer-details">
            {/* <caption style={tableElementStyle}>{region}</caption> */}
            {/* {rowData.CustomerRegion} */}

            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={tableHeadStyle}>Customer</TableCell>
                {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}
                <TableCell style={{...tableHeadStyle,textAlign: loading ? " " : "right"}}>Net Cost</TableCell>
                <TableCell style={{...tableHeadStyle,textAlign: loading ? " " : "right"}}>Customer Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {loading ? (
              Array.from({ length: 2 }).map((_, index) => (
            <CustomSkeleton key={index} numColumns={4} />
          ))
        ) : (
            filteredData && filteredData.length > 0 && filteredData[0]  && filteredData[0].Customers  ? (
  filteredData[0].Customers
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Slice the array to get only the rows for the current page
    .map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      onClick={() =>
                        handleRowClick(
                          row.Customer,
                          filteredData[0].key,
                          row.Projects
                        )
                      }
                    >
                      <TableCell>
                        <IconButton
                          aria-label={
                            expandedRow === row.key ? "Collapse" : "Expand"
                          }
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(
                              row.Customer,
                              filteredData[0].key,
                              row.Projects
                            );
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell style={tableElementStyle}>
                        {row.Customer}
                      </TableCell>
                      {/* <TableCell style={tableElementStyle}>
                        {filteredData[0].Currency}
                      </TableCell> */}
                      <TableCell  style={{ ...tableElementStyle, textAlign: 'right' }}>
                       {getSymbolFromCurrency(currency)} {row.NetCost.toFixed(2)}
                      </TableCell>
                      <TableCell  style={{ ...tableElementStyle, textAlign: 'right' }}>
                      {getSymbolFromCurrency(currency)} {row.CustomerCost.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                // Render a message when no data is available
                <TableRow>
                  <TableCell colSpan={4}></TableCell>
                </TableRow>
              )
              )}
            </TableBody>
          </Table>
       
        </TableContainer>
        {filteredData &&  filteredData.length > 0 &&  filteredData[0].Customers.length > 5 && (
  <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={filteredData[0].Customers.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />)}
      </Box>
      <Footer/>
      
     /</Box>
    </Box>
   
    </>
  );
};

export default CustomerDetailsTable;
