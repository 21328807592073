import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import awsLogo from "../assets/images/awsproviderlogo.png";
import managedata from "../assets/images/managedata.png"
import gcpLogo from "../assets/images/gcpproviderlogo.png";
import Header from "./components/Header";
import Sidenav from "./components/Sidenav";
import Footer from "./components/Footer"
import { Box, Button, Divider, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate=useNavigate();
  const handleClick=(provider)=>{
     
    navigate(`/${provider}/billingdashboard`)

  }
  return (
    <Box sx={{ display: "flex" }}>
      <Sidenav/>
      <Box  component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Header />
        <Box sx={{minHeight:"80vh", p: 1}}>
          <Grid >
            {/* Left side content */}
            <Grid >
              
            </Grid>
            {/* Right side content */}
            <Grid item xs={12} md={6}>
            <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontFamily: "Equip",
            m: 2,
            textAlign: "center",
            color: "#13294B",
            fontWeight: "500",
            fontSize:"20px"
          }}
        >
          Welcome to Billing Dashboard. View your billing details across different cloud service providers.
        </Typography>
        <div style={{ textAlign: "center" }}>
          <img
            src="https://www.nagarro.com/hubfs/Vector%2027.png"
            alt="Vector 27"
            loading="lazy"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
              <Paper
                elevation={0}
                sx={{
                  padding: 2,
                  position: "relative",
                  background: "linear-gradient(to bottom,#F8F8FF, #E6E6FA)",
                  margin:1
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#13294B",
                      fontFamily: "EquipExtended",
                      fontWeight: "400",
                      fontStyle: "normal",
                    }}
                  >
                    Browse by Cloud Provider
                  </Typography>
                </div>
                <Divider sx={{ marginTop: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="tile-container1" onClick={() => handleClick("aws")}
 >
                      <div className="tile-image1">
                        <img src={awsLogo} alt="AWS Logo" />
                      </div>
                      <div className="tile-details1">
                        <div className="tile-content1">
                          <h3 style={{ fontWeight: 300, marginBottom: 0 }}>Amazon Web Services</h3>
                          <p>Billing Dashboard</p>
                        </div>
                        <Button
                          // href={url}
                          // target="_blank"
                          // rel="noopener noreferrer"
                          sx={{ m: 0, p: 0, color: "#5CD2B5" }}
                        >
                          Click Here
                        </Button>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="tile-container1" onClick={() => handleClick("gcp")}>
                      <div className="tile-image1">
                        <img src={gcpLogo} alt="GCP Logo" />
                      </div>
                      <div className="tile-details1">
                        <div className="tile-content1">
                          <h3 style={{ fontWeight: 300, marginBottom: 0 }}>Google Cloud Platform </h3>
                          <p>Billing Dashboard</p>
                        </div>
                        <Button
                          // href={url}
                          // target="_blank"
                          // rel="noopener noreferrer"
                          sx={{ m: 0, p: 0, color: "#5CD2B5" }}
                        >
                          Click Here
                        </Button>
                      </div>
                    </div>
                    
                    
                  </Grid>
                </Grid>
              </Paper>
              {/* <Paper
                elevation={0}
                sx={{
                  padding: 2,
                  position: "relative",
                  background: "linear-gradient(to bottom,#F8F8FF, #E6E6FA)",
                  margin:1
                }}
              >
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#13294B",
                      fontFamily: "EquipExtended",
                      fontWeight: "400",
                      fontStyle: "normal",
                    }}
                  >
                   
                  </Typography>
                </div>
                <Divider sx={{ marginTop: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <div className="tile-container1" onClick={() => handleClick("aws")}
 >
                      <div className="tile-image1">
                        <img src={managedata} alt="AWS Logo" />
                      </div>
                      <div className="tile-details1">
                        <div className="tile-content1">
                          <h3 style={{ fontWeight: 300, marginBottom: 0 }}>Manage Master Data</h3>
                          <p></p>
                        </div>
                        <Button
                          // href={url}
                          // target="_blank"
                          // rel="noopener noreferrer"
                          sx={{ m: 0, p: 0, color: "#5CD2B5" }}
                        >
                          Click Here
                        </Button>
                      </div>
                    </div>
                  </Grid>
                 
                </Grid>
                </Paper> */}
            </Grid>
          </Grid>
        </Box>
        <Footer/>
      </Box>
    </Box>
  );
};

export default HomePage;
