import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const ImageModal = ({ imageUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openImage = (blobUrl) => {
    setIsModalOpen(true);
    setModalImageUrl(blobUrl);
  };
  const imageStyle = {
    width: isMobile ? "100%" : "60vw",
    height: isMobile ? "auto" : "50vh",
    cursor: "pointer",
  };


  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img
        src={imageUrl}
        alt="Image"
        onClick={() => openImage(imageUrl)}
        style={imageStyle}
      />

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 2000,
          },
          content: {
            position: "absolute",
            top: 0,
            left: 0, // Center the modal
            right: 0,
            bottom: 0,
            background: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            maxWidth: "100%",
            zIndex: 1001,
          },
        }}
      >
        <img
          src={modalImageUrl}
          alt="Modal Image"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />

        <button
          onClick={closeModal}
          color="primary"
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            background: "transparent",
            border: "none",
            fontSize: "20px",
            color: "#fff",
            cursor: "pointer",
            zIndex: 1002,
          }}
        >
          X
        </button>
      </Modal>
    </>
  );
};

export default ImageModal;
