import { Button, Fab } from "@mui/material"
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CloudIcon from '@mui/icons-material/Cloud';
const CoeportalButton=()=>{
const navigate=useNavigate();
    return(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
            variant="contained"
            color="primary"
            aria-label="add"
            style={{
                backgroundColor: '#13294B',
                marginTop: '10px',
                marginRight: '10px' // Adjust margin as needed
            }}
            onClick={() => navigate("/coe-portal")}
        >
            <CloudIcon style={{ marginRight: '5px' }} />
            Coe portal
        </Button>
    </div>
    )
}
export default CoeportalButton;