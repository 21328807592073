import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import RotateLoader from "react-spinners/RotateLoader";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { waitForDataAndCallFunction } from "../backend/backend";
import { styled } from "@mui/material/styles";
import {getRoles} from "../backend/backend";
import "./login.css";
const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
});

const LoginCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const clearCache = () => {
    // Clear browser cache (example using Cache API if available)
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }
  };

  const requestAccessToken = async (code) => {
    try {
      const codeVerifier = sessionStorage.getItem("key");
       
      const response = await axios.get(
        process.env.REACT_APP_APIM_LOGIN_CALLBACK_URL,
        // "https://apim-coe-accelerator-portal.azure-api.net/login/callback",
        {
          params: {
            authCode: code,
            codeVerifier: codeVerifier,
          },
        }
      );
      const currentTime = new Date().getTime();
      const { access_token, id_token, user_name, name } = response.data;

      sessionStorage.setItem("accessToken", access_token);
      sessionStorage.setItem("idToken", id_token);
      sessionStorage.setItem("username", user_name);
      sessionStorage.setItem("name", name);
      sessionStorage.setItem("dataIsSet", "true");
      sessionStorage.setItem("token_creation_time", currentTime.toString());

      setLoading(false);
      setSnackbarOpen(true);
      (async () => {
        try {
          const data = await waitForDataAndCallFunction();
        } catch (error) {
          console.error("Error:", error.message);
        }
      })();
      setTimeout(() => {
        navigate("/coe-portal");
      }, 3000);
    } catch (error) {
      setError("An error occurred. Please try again.");

      clearCache();
      setLoading(false);
    }
  };

  (async () => {
    try {

      if (sessionStorage.getItem("accessToken")) {
        const userData = await getRoles(
          sessionStorage.getItem("name"),
          sessionStorage.getItem("username"),
          "GCP"
        );
        // console.log(userData);
        const { billing_account_details, message, user_role, useremail, username } =
        userData;
        sessionStorage.setItem("user_role", user_role);
        // Handle the result if needed
         
      }
    } catch (error) {
      console.error("Error adding user to the database:", error.message);
    }
  })();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const authorizationCode = urlParams.get("code");
    // const key = urlParams.get("codeVerifier");
    //  

    if (authorizationCode) {
      requestAccessToken(authorizationCode);
    }
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container>
      {loading ? (
        <RotateLoader color="#36d7b7" />
      ) : (
        <>
          <Typography
            variant="h5"
            gutterBottom
            className={snackbarOpen ? "fadeIn" : ""}
          >
            {error ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              "Logged in successfully!"
            )}
          </Typography>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity="success">
              Redirecting to Home...
            </Alert>
          </Snackbar>
        </>
      )}
    </Container>
  );
};

export default LoginCallback;
