import React, { useEffect, useState } from "react";
import { invokeLambdaFunction } from "../data/backend";
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TablePagination,
  Select,
  MenuItem,
  Box,
  TextField,
  styled,
  Tabs,
  Tab,
  Fab,
  Button,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "jspdf-autotable";
import Header from "../../components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../../../ArchitectureTemplate/Components/Footer";
import Sidenav from "../../components/Sidenav";
import HomeIcon from "@mui/icons-material/Home";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import GroupIcon from "@mui/icons-material/Group";
import WorkIcon from "@mui/icons-material/Work";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getSymbolFromCurrency from "currency-symbol-map";
import nagarrologo from "../../../assets/images/Nagarro Horizontal Light.png";
import Snack from "../../../ArchitectureTemplate/Components/Snack"
import { CloudDownload, HomeRepairServiceOutlined } from "@mui/icons-material";
import CustomSkeleton from "../../components/CustomSkeleton";
import { REQUESTCOUNT } from "../data/constants";
import {maxAllowedDate} from "../../dateConstants.js"
import {minAllowedDate} from "../../dateConstants.js"
const styles = {
  tableContainer: {
    maxWidth: "100%",
  },
  tableHead: {
    backgroundColor: "#F8F8FF",
  },
  tableCell: {
    fontFamily: "Equip",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  deleteButton: {
    color: "#f44336",
  },
};
const ServiceView = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { region, orgname, accountId,paramstartdate, paramenddate  } = useParams();
  // console.log(level,region)
  const navigate = useNavigate();
  const [aggregatedData, setAggregatedData] = useState([]);
  const date = new Date().toISOString().split("T")[0];
  const [selectedTab, setSelectedTab] = useState("project");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [currency, setCurrency] = useState("");
  const [status,setStatus]=useState("");
  const[statusCode,setStatusCode]=useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [isButtonVisible,setIsButtonVisible]=useState(false);
  //   useEffect(() => {
  //  (async () => {
  //       try {
  //         const response = await invokeLambdaFunction({ level:"services",org_name:orgname,account_id:accountId});
  //           // Log the response
  //         setData(response);
  //         console.log(data)
  //         setLoading(false);
  //         return response;

  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }

  //   ) ();
  //   }, []);
  useEffect(() => {
    if ( startDate && endDate) {
      // console.log(selectedDate,startDate)
      fetchData(startDate,endDate);
      sessionStorage.setItem("endDate", endDate);
      sessionStorage.setItem("startDate", startDate);
    }
    else{
      setStartDate(paramstartdate);
      setEndDate(paramenddate)
      fetchData(paramstartdate,paramenddate)
    }
  }, [startDate,endDate]);
  const fetchData = async (startdate,enddate)  => {
    try {
      const response = await invokeLambdaFunction({
        level: "services",
        org_name: orgname,
        account_id: accountId,
        start_date: startdate,
        end_date: enddate,
      });
      if (response.statusCode === 200 && response.body !== "No record found") {
        const jsontype = JSON.parse(response.body);
        setIsButtonVisible(true);
        setCurrency(jsontype[0].CostUnit);
        setLoading(false);
        if(response.request_count>=REQUESTCOUNT){
          setStatus(`You have exceeded 50% quota of monthly request limit for ${orgname} account`);
          setStatusCode(400);
          setSnackbarOpen(true);

        }
        setData(jsontype);
    } else if (response.statusCode === 200 && response.body === "No record found") {
        setStatus(response.body);
        setStatusCode(500)
        setSnackbarOpen(true);
        setIsButtonVisible(false);
        setData([]);
        setLoading(false);
       
    }else if (response.statusCode=== 403){
      setStatus(`You have exhausted the quota of monthly request limit for ${orgname} account`);
      setStatusCode(500)
      setSnackbarOpen(true);
      setIsButtonVisible(false);
      setData([]);
      setLoading(false);
  
    }
     else {
        setStatus("No Data is available");
        setStatusCode(500);
        setData([]);
        setIsButtonVisible(false);
        setSnackbarOpen(true);
        setLoading(false);

    }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    return `${year}-${month}-01`;
  };

  const handleDateChange = (newDate) => {
    

    const inputdate=newDate.toISOString().split("T")[0]
    setStartDate(inputdate);
  
    // Calculate the start date as the first day of the previous month
    const currentMonth = new Date(newDate);
      currentMonth.setMonth(currentMonth.getMonth() + 1);
      currentMonth.setDate(1); // Set to the first day of the month
      const formattedStartDate = formatDate(currentMonth);
      setEndDate(formattedStartDate);
  };
   
  const tableHeadStyle = {
    fontWeight: "bold",
    color: "#13294B",
    fontFamily: "EquipExtended",
    fontSize: 14,
  };
  const tableElementStyle = {
    fontFamily: "Equip",
    fontSize: 14,
    fontWeight: 400,
  };
  function formatDateToDisplay(dateString) {
    const date = new Date(dateString);
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }
  const handleRowClick = (accountId) => {
    // const level="accounts"
    // navigate(`/aws/billingdashboard/services/${orgname}/${accountId}`)
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const fetchAcountDetails=async()=>{
    try{
      const response=await invokeLambdaFunction({
        level: "accounts",
        org_name: orgname,
        start_date: startDate,
        end_date: endDate,
      });
      const accountDetails = JSON.parse(response.body);
      const specificAccount = accountDetails.find(account => account.AccountId === accountId);
       
      return specificAccount;
    }catch (error) {
      console.error("Error fetching service data:", error);
      return [];
  }
};
const generatePDF= async ()=>{
  setIsGeneratingPDF(true);
  const speceficAccount=await fetchAcountDetails();
   
  const doc = new jsPDF({});
  let y = 35;
    const logoImg = new Image();
    logoImg.onload = function () {
      const logoWidth = 60; // Set the width of the logo
      const logoHeight = 60 * (logoImg.height / logoImg.width);
      const logoX = doc.internal.pageSize.getWidth() - logoWidth - 5; // Align the logo to the extreme right
      const logoY = 20 - logoHeight / 2; // Align the logo vertically with the header text
      doc.addImage(logoImg, "JPEG", logoX, logoY, logoWidth, logoHeight);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(20);
      doc.setTextColor("#000000");
      
      doc.text(`Cost breakdown`, 15, 23);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.setTextColor('#000000');
      doc.text(`Hyperscaler:`,15,35)
      doc.text(`Organisation Unit:`, 15, 40);
      doc.text(`Account ID :`, 15, 45);
      doc.text(`Account Name:`, 15, 50);
      doc.text(`Net Cost:`, 15, 55);
      doc.text(`Customer Cost:`, 15, 60);
      doc.text(`Billing Month:`, 15, 65);
      doc.text(`Currency:`, 15, 70); // Add currency after project
      doc.setFont("helvetica", "normal");
      doc.text(`AWS`, 48, 35);
      doc.text(`${orgname}`, 48, 40);
      doc.text(`${speceficAccount.AccountId}`, 48, 45);
      doc.text(`${speceficAccount.AccountName}`, 48, 50);
      doc.text(`${speceficAccount.NetCost.toFixed(2)}`, 48, 55);
      doc.text(`${speceficAccount.CustomerCost.toFixed(2)}`, 48, 60);
      doc.text(`${formatDateToDisplay(speceficAccount.StartDate)}`, 48, 65); //
      doc.text(`${speceficAccount.CostUnit}`, 48, 70);
      const tableOptions = {
        theme: 'grid',
        startY: 100, // Adjust start Y position to accommodate the header and additional text
        headStyles: {
            fillColor: '13294B', // Light gray color with low opacity
            textColor: "#FFFFFF", // Set header text color
            fontStyle: 'bold'
        },
        bodyStyles: {
            textColor: "#000000" // Set body text color
        },
        head: [["Service Name", "Net Cost","Customer Cost"]],
        body: data.map(service => [ service.ServiceName,
         
          
          service.CustomerCost.toFixed(2),
          service.NetCost.toFixed(2)]),
        styles: {
            font: "helvetica",
            fontSize: 10,
            cellPadding: 5
        },
        margin: { top: 10 }
    };
    doc.autoTable(tableOptions);
      doc.save("service_details.pdf");
      setIsGeneratingPDF(false);
    };
    logoImg.src = nagarrologo;

};
const handleChangePage = (event, newPage) => {
  setPage(newPage); // Update page state for TablePagination component
};
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Header />
          <Box sx={{ minHeight: "80vh" }}>
          {status && statusCode && (
            <Snack
              open={snackbarOpen}
              status={statusCode}
              data={status}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              handleClose={handleCloseSnackbar}
            />
          )}
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, margin: "10px", justifyItems: "flex-start" }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="scrollable"
                >
                  <Tab
                    label="Home"
                    iconPosition="start"
                    icon={<HomeIcon />}
                    value="back"
                    component={Link}
                    to={`/aws/billingdashboard`}
                  />
                  <Tab
                    label={region}
                    value="maintable"
                    component={Link}
                    to={`/aws/billingdashboard/customers/${region}/${startDate}/${endDate}`}
                    iconPosition="start"
                    icon={<CloudQueueIcon />}
                  />
                  <Tab
                    label={orgname}
                    value="customer"
                    component={Link}
                    to={`/aws/billingdashboard/accounts/${region}/${orgname}/${startDate}/${endDate}`}
                    iconPosition="start"
                    icon={<GroupIcon />}
                  />
                  <Tab
                    label={sessionStorage.getItem("accountName")}
                    value="project"
                    component={Link}
                    iconPosition="start"
                    icon={<WorkIcon />}
                  />
                </Tabs>
              </div>
              <div style={{ margin: "10px", justifyItems: "flex-end" }}>
                <TextField
                  sx={{ minWidth: 200, marginTop: 5, marginRight: 2 }}
                  value={currency}
                  variant="standard"
                />
                <DatePicker
                  className="datepicker"
                  label="Select Month and Year"
                  showMonthYearPicker
                  selected={new Date(startDate)}
                  value={formatDateToDisplay(startDate)}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={maxAllowedDate}
                  minDate={minAllowedDate}
                />
                {/* 
            <FormControl >
              <Select
               sx={{ minWidth: 200, marginTop:5  }}
                // value={currentDate}
                // onChange={handleInvoiceDateChange}
                displayEmpty
                variant="standard"
               
              >
                {/* {data2 &&
                  data2.map((row) => (
                    <MenuItem key={row.InvoiceDate} value={row.InvoiceDate}>
                      {row.InvoiceDate}
                    </MenuItem>
                  ))} */}
                {/* </Select>
            </FormControl> */}
              </div>
            </div>
            <TableContainer
              style={styles.tableContainer}
              sx={{ boxShadow: "0px 4px 8px rgba(186, 220, 211, 5)" }}
            >
              <Table aria-label="pivot table">
                <TableHead style={styles.tableHead}>
                  <TableRow>
                  
                    {/* <TableCell  style={tableHeadStyle} >Region</TableCell> */}
                    <TableCell style={tableHeadStyle}>Service Name</TableCell>

                    {/* <TableCell style={tableHeadStyle}>
                     Currency
                    </TableCell> */}

                    {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}
                    <TableCell style={tableHeadStyle}>Billing Month</TableCell>
                    {/* <TableCell style={tableHeadStyle}>End Date</TableCell> */}

                    <TableCell
                      style={{ ...tableHeadStyle,textAlign: loading ? " " : "right" }}
                    >
                      {" "}
                      Net Cost
                    </TableCell>
                    <TableCell
                      style={{ ...tableHeadStyle,textAlign: loading ? " " : "right" }}
                    >
                      Customer Cost
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading ? (
          // Render SkeletonLoaderRow component when loading
          Array.from({ length: 2 }).map((_, index) => (
            <CustomSkeleton key={6} numColumns={5} service={6}/>
          ))
        ) : (data &&
                    data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                      <React.Fragment key={index}>
                        <TableRow
                          className="tableRowHover"
                          onClick={() => handleRowClick(row.AccountId)}
                        >
                          
                          {/* <TableCell style={tableElementStyle}>{row.CustomerRegion}</TableCell> */}
                          <TableCell style={tableElementStyle}>
                            {row.ServiceName}
                          </TableCell>

                          {/* <TableCell style={tableElementStyle}>
                          {row.CostUnit}
                        </TableCell> */}
                          {/* <TableCell style={tableElementStyle}>
                          {row.EndDate}
                        </TableCell> */}
                          {/* <TableCell style={tableElementStyle}>
                      {row.Currency}
                    </TableCell> */}
                          <TableCell
                            style={{ ...tableElementStyle, textAlign: "left" }}
                          >
                            {formatDateToDisplay(row.StartDate)}
                          </TableCell>
                          {/* {row.EndDate > date ? (
                            <TableCell style={tableElementStyle}>
                              Ongoing
                            </TableCell>
                          ) : (
                            <TableCell
                              style={{
                                ...tableElementStyle,
                                textAlign: "left",
                              }}
                            >
                             {formatDateToDisplay(row.EndDate)}
                            </TableCell>
                          )} */}

                          {/* <TableCell style={tableElementStyle}>{row.DueDate}</TableCell> */}
                          <TableCell
                            style={{ ...tableElementStyle,textAlign: loading ? " " : "right" }}
                          >
                            {" "}
                            {getSymbolFromCurrency(row.CostUnit)}{" "}
                            {row.CustomerCost.toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{ ...tableElementStyle,textAlign: loading ? " " : "right" }}
                          >
                            {" "}
                            {getSymbolFromCurrency(row.CostUnit)} {row.NetCost.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )))}
                </TableBody>
              </Table>
            </TableContainer>

            {data && data.length > 5 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
         {isButtonVisible ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                onClick={generatePDF}
                variant="contained"
                color="primary"
                size="small"
                disabled={isGeneratingPDF}
                style={{
                  backgroundColor: isGeneratingPDF ?"#808080": "#47D7AC", // Change background color to #47D7AC
                  color: "#13294B", // Change text color to #13294B
                  padding: "10px 15px", // Reduce padding to make it smaller
                  borderRadius: "32px", // Apply border-radius
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                {isGeneratingPDF ? ( // Display loader or CloudDownload icon based on PDF generation state
                  <CircularProgress
                    size={15}
                    color="inherit"
                    style={{ marginRight: "5px" }}
                  />
                ) : (
                  <CloudDownload style={{ marginRight: "5px" }} />
                )}
                Download PDF
              </Button>
            </div>
        ):(<div>
          
        </div>)}
          </Box>
          
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default ServiceView;
