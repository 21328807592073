import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AzureContent } from "./AzureContent";
import { AwsContent } from "./AwsContent";
import GcpContent from "./GcpContent";
import {
  deployPipeline,
  getServiceConnectionFromDB,
  ServiceConnectionDeletion,
} from "../../../../backend/backend";
// import FilledAlerts from "./Alert";
import Snack from "../../../Components/Snack";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
export const Popup = ({ open, onClose, provider, document, username }) => {
  const navigate = useNavigate();
  let id, service_connection_name, templateName;
  const [azServiceConnection, setAzServiceConnection] = useState("");
  const [awsServiceConnection, setAwsServiceConnection] = useState("");
  const [gcpServiceConnection, setGcpServiceConnection] = useState("");
  const [pipelineStatus, setPipelineStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [pipelineConnections, setPipelineConnections] = useState([]);
  const [deletionStatus, setDeletionStatus] = useState("");
  const [azureConnection, setAzureConnection] = useState([]);
  const [awsConnection, setAwsConnection] = useState([]);
  const [gcpConnection, setGcpConnection] = useState([]);
  const [status, setStatus] = useState(null);
  const [checkDeployment, setCheckDeployment] = useState(false);
  const [deployments, setDeployments] = useState([]);
  const [deploymentDetails, setDeploymentDetails] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [filteredDeployments, setFilteredDeployments] = useState([]);

  async function getServiceConnection(username) {
    try {
      const data1 = await getServiceConnectionFromDB(username);

      if (data1 !== null) {
        setPipelineConnections(data1);
        setDeployments(data1);
        data1.map((element) => {
          if (element.cloud === "AZURE") {
            setAzureConnection(element.serviceConnection);
          } else if (element.cloud === "AWS") {
            setAwsConnection(element.serviceConnection);
          } else {
            setGcpConnection(element.serviceConnection);
          }
        });
      } else {
        setPipelineConnections(null);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const filteredData = deployments.filter((item) => {
      return item.cloud === document.cloudProvider;
    });
    setDeploymentDetails(filteredData);
  }, []);

  useEffect(() => {
    getServiceConnection(username);
  }, []);

  const handleRefresh = () => {
    getServiceConnection(username);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleAwsConnectionChange = (e) => {
    setAwsServiceConnection(e.target.value);
  };

  const handleAzureConnectionChange = (e) => {
    setAzServiceConnection(e.target.value);
  };

  const handleGcpConnectionChange = (e) => {
    setGcpServiceConnection(e.target.value);
  };

  const handleServiceConnDeletion = (e) => {
    e.preventDefault();
    let body;
    if (provider === "AZURE") {
      body = {
        service_connection_name: azServiceConnection,
        cloud_provider: provider,
        user_name: username,
      };
    } else if (provider === "AWS") {
      body = {
        service_connection_name: awsServiceConnection,
        cloud_provider: provider,
        user_name: username,
      };
    } else {
      body = {
        service_connection_name: gcpServiceConnection,
        cloud_provider: provider,
        user_name: username,
      };
    }

    if (body && body !== "") {
      (async () => {
        try {
          const data1 = await ServiceConnectionDeletion(body);

          if (data1 !== null) {
            setDeletionStatus("Service Connection Deleted Successfully!!");
            setStatus(200);
            onClose();
            handleRefresh();
          } else {
            setDeletionStatus(
              "An error occurred while Deleting the pipeline connection please try again."
            );
            setStatus(500);
            onClose();
          }
        } catch (error) {
          console.log("Error:", error);
        }
      })();
    }
  };

  function checkForExisitingDeployments() {
    const filteredData = deployments.filter((item) => {
      return item.cloud === document.cloudProvider;
    });

    let srvConName = "";
    if (document.cloudProvider === "AZURE") srvConName = azServiceConnection;
    else if (document.cloudProvider === "GCP")
      srvConName = gcpServiceConnection;
    else srvConName = awsServiceConnection;

    const result = filteredData[0].deployments.some((item) => {
      return (
        item.serviceConnectionName === srvConName &&
        item.templateName === document.templateName &&
        item.deployed === "True"
      );
    });

    if (result) {
      return true;
    } else {
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let alreadyDeployed = "";
    const result = checkForExisitingDeployments();

    if (result === true) {
      alreadyDeployed = true;
    } else alreadyDeployed = false;

    if (alreadyDeployed) {
      setPipelineStatus(
        "Resource already exist.Please destroy before deploying again."
      );
      setStatus(400);
      setSnackbarOpen(true);
    } else {
      let body;

      if (provider === "AZURE") {
        body = {
          pipelineId: document.pipelineId,
          azServiceConnectionName:
            process.env.REACT_APP_AZURE_SERVICE_CONNECTION_PRIMARY,
          azServiceConnectionNameClient: azServiceConnection,
          azRgName: process.env.REACT_APP_TFBACKEND_RG_NAME,
          azStorageName: process.env.REACT_APP_TFBACKEND_STORAGE_ACCOUNT_NAME,
          azContainerName: "tf-state-container",
          templateName: document.templateName,
          awsServiceConnectionName: "",
          gcpServiceConnectionName: "",
          cloudProviderName: "AZURE",
          userName: username,
          apply_var: "True",
          destroy_var: "",
        };
      } else if (provider === "AWS") {
        body = {
          pipelineId: document.pipelineId,
          azServiceConnectionName:
            process.env.REACT_APP_AZURE_SERVICE_CONNECTION_PRIMARY,
          azServiceConnectionNameClient: "",
          azRgName: process.env.REACT_APP_TFBACKEND_RG_NAME,
          gcpServiceConnectionName: "",
          azStorageName: process.env.REACT_APP_TFBACKEND_STORAGE_ACCOUNT_NAME,
          azContainerName: "tf-state-container",
          templateName: document.templateName,
          awsServiceConnectionName: awsServiceConnection,
          cloudProviderName: "AWS",
          userName: username,
          apply_var: "True",
          destroy_var: "",
        };
      } else if (provider === "GCP") {
        body = {
          pipelineId: document.pipelineId,
          azServiceConnectionName:
            process.env.REACT_APP_AZURE_SERVICE_CONNECTION_PRIMARY,
          azServiceConnectionNameClient: "",
          azRgName: process.env.REACT_APP_TFBACKEND_RG_NAME,
          gcpServiceConnectionName: gcpServiceConnection,
          azStorageName: process.env.REACT_APP_TFBACKEND_STORAGE_ACCOUNT_NAME,
          azContainerName: "tf-state-container",
          templateName: document.templateName,
          awsServiceConnectionName: "",
          cloudProviderName: "GCP",
          userName: username,
          apply_var: "True",
          destroy_var: "",
        };
      } else {
      }

      if (body) {
        (async () => {
          try {
            const data1 = await deployPipeline(body);
            // Access the returned data
            if (data1 !== null) {
              if (data1.status === 200) {
                id = data1.buildId;
                service_connection_name = data1.serviceConnectionName;
                templateName = data1.templateName;
                setPipelineStatus("pipeline triggered successfully");
                setStatus(200);
                setLoading(false);
                onClose(); // Close the popup
                navigate(
                  `/coe-portal/architecture-template/PipelineStatus/status/${id}/${body.pipelineId}/${service_connection_name}/${templateName}`
                );
              }
            } else {
              setPipelineStatus(
                "An error occurred while triggering the pipeline please try again."
              );
              setStatus(500);
              onClose();
              setLoading(false);
            }
          } catch (error) {
            // Handle any errors thrown during the fetch
            console.log("Error:", error);
          }
        })();
      } else {
      }
    }
  };

  return (
    <React.Fragment>
      {pipelineStatus && status && (
        <Snack
          open={snackbarOpen}
          status={status}
          data={pipelineStatus}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          handleClose={handleCloseSnackbar}
        />
      )}
      {provider === "AZURE" ? (
        <AzureContent
          open={open}
          onClose={onClose}
          submit={handleSubmit}
          deleteServiceConn={handleServiceConnDeletion}
          azServiceConnection={azServiceConnection}
          handleAzureConnectionChange={handleAzureConnectionChange}
          pipelineConnections={azureConnection}
          handleRefresh={handleRefresh}
        />
      ) : provider === "AWS" ? (
        <AwsContent
          open={open}
          onClose={onClose}
          submit={handleSubmit}
          deleteServiceConn={handleServiceConnDeletion}
          awsServiceConnection={awsServiceConnection}
          handleAwsConnectionChange={handleAwsConnectionChange}
          pipelineConnections={awsConnection}
          handleRefresh={handleRefresh}
        />
      ) : provider === "GCP" ? (
        <GcpContent
          open={open}
          onClose={onClose}
          submit={handleSubmit}
          deleteServiceConn={handleServiceConnDeletion}
          gcpServiceConnection={gcpServiceConnection}
          handleGcpConnectionChange={handleGcpConnectionChange}
          pipelineConnections={gcpConnection}
          handleRefresh={handleRefresh}
        />
      ) : (
        ""
      )}
      {loading ? (
        ""
      ) : (
        <React.Fragment>
          {pipelineStatus && status && (
            <Snack
              open={snackbarOpen}
              status={status}
              data={pipelineStatus}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              handleClose={handleCloseSnackbar}
            />
          )}
        </React.Fragment>
      )}
      {deletionStatus && status && (
        <Snack
          status={status}
          data={deletionStatus}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      )}
    </React.Fragment>
  );
};
