import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  styled,
} from "@mui/material";     
import Ansi from "ansi-to-react";
import { getLogsUrlData } from "../../backend/backend";

const CustomButton = styled(Button)({
  fontWeight: "h3",
  color: "white",
});

const CustomDialogContent = styled(DialogContent)({
  whiteSpace: "pre-wrap",
  fontFamily: "Arial, sans-serif",
  fontSize: "18px",
  width: "96vw",
  color: "black",
});
const CenteredDialogTitle = styled(DialogTitle)({
  textAlign: "center",
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  width: "100vw",
}));

const LogsButton = ({ logsUrl, stage }) => {
  const [open, setOpen] = useState(false);
  const [logsData, setLogsData] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    const fetchLogsData = async () => {
      try {
        const response = await getLogsUrlData(logsUrl);
        setLogsData(response);
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogsData();
  }, [logsUrl]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <CustomButton variant="contained" color="primary" onClick={handleClick}>
        View Logs
      </CustomButton>

      <CustomDialog
        open={open}
        onClose={handleClose}
        maxWidth="false"
        fullWidth
      >
        <CenteredDialogTitle>{`LOGS FOR  ${stage.toUpperCase()}`}</CenteredDialogTitle>

        <CustomDialogContent>
          <Ansi>{logsData}</Ansi>
        </CustomDialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default LogsButton;
