import React from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { keyframes } from "@emotion/react";

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedCard = styled(Card)`
  padding: 1rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  animation: ${appear} 0.5s ease-out;
`;

const GradientBox = styled(Box)`
  background: linear-gradient(45deg, #45d0a8, #b6e5d5);
  color: white;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-top: -3rem;
`;

function ComplexStatisticsCard({ color, title, count, percentage, icon }) {
  return (
    <AnimatedCard>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={1}
      >
        <GradientBox>
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </GradientBox>
        <Box textAlign="right" lineHeight={1.25}>
          <Typography
            variant="button"
            fontWeight="400"
            color="text"
            fontFamily="Equip"
          >
            {title}
          </Typography>
          <Typography variant="h4">{count}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box pb={2} px={2}>
        <Typography
          component="p"
          variant="button"
          color="text"
          display="flex"
          fontFamily="Equip"
        >
          <Typography
            component="span"
            variant="button"
            fontWeight="bold"
            color={percentage.color}
          >
            {percentage.amount}
          </Typography>
          </Typography>
          <Typography
            variant="body1"
            fontWeight="400"
            color="text"
            fontFamily="Equip"
            sx={{margin:1}}
          >
          &nbsp;{percentage.label1}
        </Typography>
        
        <Typography
            variant="body2"
            fontWeight="600"
            color="text"
            fontFamily="Equip"
            gutterBottom
            sx={{marginLeft:3.4}}
          >
          &nbsp;{percentage.label2}
        </Typography>
      </Box>
    </AnimatedCard>
  );
}

export default ComplexStatisticsCard;
