import React from 'react';
import Typography from '@mui/material/Typography';

const footerStyles = {

  bottom: 0,
  left: 0,
  width: '90%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f8f8ff', 
  padding: '10px',
  marginTop:'20px',
 
};

const imageStyles = {
  width: '107px',
  height: '26px',
  marginRight: '10px',
};

const Footer = (data) => {
  const currentYear = new Date().getFullYear();
  return (
    <div style={footerStyles}>
      <img
        src="https://www.nagarro.com/hubfs/NagarroWebsiteRedesign-Aug2020/Assets/Images/Nagarro%20green%20logo%20with%20title_opt.svg"
        loading="lazy"
        alt="Footer Logo"
        style={imageStyles}
      />

      <Typography variant="body2" color="#6c7689">
        &copy;{currentYear}
      </Typography>
    </div>
  );
};

export default Footer;
