import React, { useState, useEffect } from "react";
import { Divider, Grid, Paper, Typography, Link, Button, Box } from "@mui/material";
import ResponsiveCard from "../ResponisveCard";
import { getSearchData,fetchAllData } from "../../../../backend/backend";
import ShowAllLink from "../ShowAllLink";

function PremiumTemplate() {
  const [recentlyUpdatedTemplates, setRecentlyUpdatedTemplates] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [newSearchedData, setNewSearchedData] = useState([]);
const [allTemplates,setAllTemplates]=useState([]);
  useEffect(() => {
    (async () => {
      try {
        
        const data1 = await getSearchData("*");
        if (data1 !== null) {
         setAllTemplates(data1);
          const filteredData = data1.filter(
            (item) => item.tags[0] === "PREMIUM"
          );
          setAllData(filteredData);
          setNewSearchedData(filteredData);
          setLoading(false);
        } else {
          setError(true);
          setAllData(null);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      <Paper elevation={0} sx={{ padding: 2, position: "relative", background: "linear-gradient(to bottom,#F8F8FF, #E6E6FA)" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            variant="h5"
            sx={{
              color: "#13294B",
              fontFamily: "EquipExtended",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            Premium Templates
          </Typography>
  
          <ShowAllLink route="/coe-portal/architecture-template/ShowAll" data={allData} />
        </div>
        <Divider sx={{ marginTop: 2 }} />
        <Grid container spacing={2}>
          {newSearchedData.slice(0, 4).map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <ResponsiveCard
                title={item.templateDisplayName}
                description={item.details.description}
                type={item.templateName}
                isPremium={item.tags[0]}
                isCoeVerified={item.tags[1]}
                templateStructureUrl={item.templateStructureUrl}
              />
            </Grid>
          ))}
        </Grid>
       
      </Paper>
      <Box sx={{mt:4, p: 2  , alignContent:"center",background: "linear-gradient(to bottom,#F8F8FF, #E6E6FA)"}}>
      <ShowAllLink route="/coe-portal/architecture-template/ShowAll" data={allTemplates} />
        </Box>

    </>
  );
}

export default PremiumTemplate;
