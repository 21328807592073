import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { getAutoCompleteSuggestions } from "../../backend/backend";

const SearchBar = ({ onSearch, allData, setSearchValue, searchedData }) => {
  const [inputValue, setInputValue] = useState("");
  const [searchquery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [autoComplete, setAutoComplete] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const navigate = useNavigate();

  async function getSuggestData(query) {
    try {
      const response = await getAutoCompleteSuggestions(query);

      return response.data.suggestions;
    } catch (error) {
      console.log("Error getting suggestions", error);  
    }
  }

  useEffect(() => {
    if (inputValue.trim().length !== 0) {
      getSuggestData(inputValue)
        .then((autosuggestions) => {
          setSuggestions(autosuggestions.value);
        })
        .catch(() => {});
    }
  }, [inputValue]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      const nextIndex = selectedIndex + 1;
      if (nextIndex < suggestions.length) {
        setSelectedIndex(nextIndex);
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      const nextIndex = selectedIndex - 1;
      if (nextIndex >= 0) {
        setSelectedIndex(nextIndex);
      }
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (selectedIndex !== -1) {
        handleSuggestionClick(inputValue);
      } else {
        onSearch();
      }
    } else if (event.key === " ") {
      if (selectedIndex === -1) {
        setInputValue(suggestions[0].queryPlusText);
        setSelectedIndex(selectedIndex + 1);
      } else
        suggestions[selectedIndex]?.queryPlusText &&
          setInputValue(suggestions[selectedIndex].queryPlusText);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.toLowerCase());
    setSearchValue(suggestion.toLowerCase());
    setSearchQuery(suggestion.toLowerCase());

    setAutoComplete([]);
    setSuggestions([]);
    
    navigate(`/coe-portal/architecture-template/AvailableTemplates/${suggestion}`);
  };

  const handleClearClick = () => {
    setInputValue("");
    setSearchValue("");
    setAutoComplete([]);
    setSuggestions([]);
  };

  return (
    <div>
      <TextField
        placeholder="search templates..."
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        backgroundColor="white"
        InputProps={{
          startAdornment: (
            <IconButton onClick={onSearch} edge="start">
              <SearchIcon />
            </IconButton>
          ),
          endAdornment: inputValue ? (
            <IconButton onClick={handleClearClick} edge="end">
              <ClearIcon />
            </IconButton>
          ) : null,
        }}
        style={{ width: "20vw", backgroundColor: "white" }}
      />

      {suggestions.length > 0 && inputValue && (
        <List
          component="ul"
          style={{
            position: "absolute",
            width: "20%",
            top: 65,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            border: "1px solid #ccc",
            zIndex: 999,
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
            maxHeight: 200,
            overflowY: "auto",
            color: "black",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <ListItem
              key={index}
              button
              onClick={() => handleSuggestionClick(suggestion.queryPlusText)}
              selected={index === selectedIndex}
            >
              {suggestions.length > 0 && (
                <ListItemText primary={suggestion.queryPlusText} />
              )}
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default SearchBar;
