import { Box, Card } from "@mui/material";
import Sidenav from "../../Components/Sidenav";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { TextField, Button, Rating, Container } from "@mui/material";
import Header from "../../Components/Header";
import { saveFeedbackData } from "../../../backend/backend";
import Snack from "../../Components/Snack";
import Footer from "../../Components/Footer";
export default function Feedback() {
  const username = sessionStorage.getItem("username");
  const name = sessionStorage.getItem("name");
  const [status, setStatus] = useState("");
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [status1, setStatus1] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };
  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleClearFields = () => {
    setFeedback("");
    setRating(0);
  };

  const handleSubmit = (e) => {
    let body = {
      email: username,
      name: name,
      feedbackData: {
        rating: rating,
        information: feedback,
      },
    };
    if (body) {
      (async () => {
        try {
          const response = await saveFeedbackData(body);

          if (response !== null) {
            setStatus("Feedback Saved successfully!!");
            setStatus1(200);
            handleClearFields();
            setSnackbarOpen(true);
          } else {
            setStatus("Feedback not Saved some error occur!!");
            setStatus1(500);
          }
        } catch (error) {
          console.log("Error:", error);
        }
      })();
    } else {
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Header />

        <Container maxWidth="lg" sx={{ marginTop: 2,minHeight:"80vh" }}>
          {status && status1 && (
            <Snack
              open={snackbarOpen}
              status={status1}
              data={status}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              handleClose={handleCloseSnackbar}
            />
          )}
          <Typography
            variant="h5"
            sx={{
              marginBottom: 2,
              color: "#13294B",
              fontFamily: "EquipExtended",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            {" "}
            Provide Feedback
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginBottom: 2,
              color: "grey",
              fontFamily: "Equip",
              fontWeight: "400",
              fontStyle: "normal",
            }}
          >
            Your feedback is invaluable in helping us improve our website and
            provide a better experience for our users. Please feel free to share
            any comments, suggestions, or concerns you may have about our
            website.
          </Typography>
          <Rating
            name="rating"
            value={rating}
            onChange={handleRatingChange}
            max={5}
            size="large"
          />
          <TextField
            multiline
            rows={6}
            variant="outlined"
            label="Write your feedback"
            value={feedback}
            onChange={handleFeedbackChange}
            fullWidth
            margin="normal"
          />
          <Button
            style={{ margin:6}}
            variant="contained"
            color="warning"
            onClick={handleClearFields}
          >
            Clear Fields
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit Feedback
          </Button>
        </Container>
        <Footer />
      </Box>
    </Box>
  );
}
