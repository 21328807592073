import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import cloudImage from "../../../assets/images/cloud.jpg";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getTemplateRatingFromDb } from "../../../backend/backend";
const theme = createTheme({
  typography: {
    fontFamily: "Equip",
  },
});

const Template = ({
  key,
  title,
  description,
  type,
  isPremium,
  isCoeVerified,
}) => {
  const navigate = useNavigate();
  const [templateRating, setTemplateRating] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const data1 = await getTemplateRatingFromDb(type);

        if (data1 !== null) {
          setTemplateRating(data1);
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    })();
  });

  const handleClick = () => {
    navigate(`/dashboard/${type}`);
  };
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{ maxWidth: 300 }}
        key={key}
        style={{ marginLeft: "30px", marginBottom: "30px" }}
        onClick={handleClick}
      >
        <Badge
          color={isPremium ? "error" : "success"}
          badgeContent={isPremium == "PREMIUM" ? "Premium" : "Free"}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          overlap="circular"
          sx={{
            position: "absolute",
            transform: "translate(-20%, 20%)",
            fontSize: "30px",
            fontWeight: "bold",
            background: isPremium ? "#FF4081" : "#4CAF50",
            color: "#fff",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        />

        <CardMedia sx={{ height: 70 }} image={cloudImage} title={title} />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ color: "#13294B" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ color: "#13294B" }}
          >
            {description}
          </Typography>
        </CardContent>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          ml={1}
        >
          <Rating
            name={`rating-${key}`}
            value={templateRating !== 0 ? templateRating : 0}
            precision={0.5}
            readOnly
          />
        </Box>
        <CardActions>
          <Button
            size="small"
            onClick={handleClick}
            style={{ color: "#47D7AC" }}
          >
            Explore
          </Button>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
};

export default Template;
