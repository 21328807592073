

import whitepaper1 from "../../assets/vectors/whitepapers.jpg";



import whitepaper3 from "../../assets/vectors/whitepaper3.jpg";

import whitepaper4 from "../../assets/vectors/whitepaper4.jpg";

import whitepaper5 from "../../assets/vectors/whitepaperTeam.jpg";

import whitepaper6 from "../../assets/vectors/whitepaper1.jpg";
// import WhitePapers from "../WhitePapers";

const WhitePapersData = [
  {
    title: "",

    category: "White Paper",

    url: "https://www.nagarro.com/en/blog/post/94/the-hitchhiker-s-micro-guide-to-the-cloud",

    details:
      " The hitchhiker’s micro-guide to the Cloud - by Damianos Soumelidis",

    image: whitepaper1,
  },

  {
    title: "",

    category: "White Paper",

    url: "https://www.nagarro.com/en/blog/rise-of-paas-game-changer-in-cloud-evolution-",

    details:
      " Rise of PaaS: Game changer in cloud evolution - by Mohit Bhardwaj",

    image: whitepaper4,
  },

  {
    title: "",

    category: "White Paper",

    url: "https://www.nagarro.com/en/blog/post/61/orchestrating-microservices-on-amazon-web-services-aws",

    details:
      "Orchestrating Microservices on Amazon Web Services (AWS) - by Cloud team",

    image: whitepaper5,
  },

  {
    title: "",

    category: "White Paper",

    url: "https://www.nagarro.com/en/blog/edge-computing-the-dawn-of-autonomous-things",

    details:
      "Edge computing: The dawn of autonomous things - by Shallu Sarvari & Mohit Bhardwaj",

    image: whitepaper3,
  },

  {
    title: "",

    category: "White Paper",

    url: "https://www.nagarro.com/en/blog/a-quick-guide-to-serverless-computing-platforms",

    details:
      "A quick guide to serverless computing platforms - by Vikas Gandhi",

    image: whitepaper6,
  },

  {
    title: "",

    category: "White Paper",

    url: "https://www.nagarro.com/en/services/data-analytics-intelligence/cloud-data-warehousing",

    details:
      "Data warehouse & analytics: Amazon Redshift or Google BigQuery? - by Cloud team",

    image: whitepaper5,
  },

//   {
//     title: "Cloud Canvas-Powered by Cloud CoE",

//     category: "Offering",

//     id : 1,

//     url: "https://storageblobaccelerator01.z29.web.core.windows.net/",

//     details:
//       "Streamline the process of provisioning resources on your cloud provider with the simplicity of a single click.",

//     image: cloudoffering,
//   },

  // {

  //   title: "Cloud capabilities",

  //   category: "Offering decks",

  //   url: "https://nagarro.sharepoint.com/sites/cloudcoe/SitePages/Azure-capabilities.aspx",

  //   details:

  //     "Cloud capabilities ",

  //   image:

  //     "https://img.freepik.com/free-vector/business-people-work_24908-59902.jpg?w=996&t=st=1695106540~exp=1695107140~hmac=ca1e0438c1d76c692e4b57f083dbc8f287676916119c88868edef0a98cc7189f",

  // },

//   {
//     title: "Azure capabilities",

//     category: "Offering decks",

//     url: "https://nagarro.sharepoint.com/sites/cloudcoe/SitePages/Azure-capabilities.aspx",

//     details:
//       " Explore our comprehensive Azure cloud service offerings in detail.",

//     image:
//       "https://img.freepik.com/free-vector/character-illustration-people-with-technology-icons_53876-66104.jpg?w=1380&t=st=1695206846~exp=1695207446~hmac=21d22859347e79920bd703a9569a966f62dc45af728b7003b90b2c564ed05c36",
//   },

//   {
//     title: "AWS capabilities",

//     category: "Offering decks",

//     url: "https://nagarro.sharepoint.com/sites/cloudcoe/SitePages/AWS-capabilities.aspx",

//     details:
//       "Take a closer look at all the services we provide on the AWS cloud platform",

//     image:
//       "https://img.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_23-2149383348.jpg?w=1380&t=st=1695206947~exp=1695207547~hmac=8dc0339a348b9fc2ad0c18716e519adb8141dfe0ffa5ad7f98bbb3b6d2a1ab60",
//   },

//   {
//     title: "Google cloud capabilities",

//     category: "Offering decks",

//     url: "https://nagarro.sharepoint.com/sites/cloudcoe/SitePages/Google-Cloud-Capability.aspx",

//     details:
//       "Dive deeper into the specifics of our wide-ranging Google Cloud service offerings.",

//     image:
//       "https://img.freepik.com/free-vector/robot-studying-cloud-technology-through-magnifier-artificial-intelligence-machine-learning-cloud-computing-robot-thinking-concept-vector-illustration-isolated_335657-2198.jpg?w=1380&t=st=1695207258~exp=1695207858~hmac=46d5a9a4e07349a94a8cc95bb7c7a29b7ff91c1451a8c70942539624e8d6db88",
//   },

//   {
//     title: "Cloud Operations",

//     category: "Offering decks",

//     url: "https://www.yammer.com/nagarro.com/#/files/130338301",

//     details:
//       "Discover how streamlining cloud operations is crucial for maximizing efficiency and optimizing resource utilization in our presentation.",

//     image:
//       "https://img.freepik.com/free-vector/cloud-services-isometric-composition-with-image-laptop-with-small-human-characters-cloud-icon-vector-illustration_1284-65954.jpg?w=826&t=st=1695207459~exp=1695208059~hmac=cbb0e1dade9a52e0f3716e4acd69847533c4efd673a830ec7e61aaecebc25aa1",
//   },
];

export default WhitePapersData;
