import Grid from "@mui/material/Grid";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  fetchDataById,
  getSasToken,
  saveTemplateRatingToDb,
} from "../../../backend/backend";

import ImageModel from "./components/ImageModel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import StarBorderIcon from "@mui/icons-material/StarBorder";
// Dashboard components
import Projects from "./components/Projects";
import Card from "@mui/material/Card";
import DescriptionComponent from "./components/DescriptionComponent";
import OrdersOverview from "./components/OrdersOverview/index";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import {
  Button,
  Box,
  Typography,
  Divider,
  Stack,
  Rating,
  Paper,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Sidenav from "../../Components/Sidenav";
import Header from "../../Components/Header";

import ComplexStatisticsCard from "./components/ComplexStatisticsCard";
import { Popup } from "./PopUpContent/Popup";

import BeatLoader from "react-spinners/BeatLoader";
import { saveTemplateFeedbackData } from "../../../backend/backend";

import ErrorPage from "../../Components/ErrorPage";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Footer from "../../Components/Footer";
import ReusableDrawer from "./ResuableDrawer";
import Snack from "../../Components/Snack";
// import { saveTemplateFeedbackData } from "../../../backend/backend";

function Dashboard() {
  const [costRegion, setCostRegion] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const [document, setDocument] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [azureButton, setAzureButton] = useState(false);
  const [awsButton, setAwsButton] = useState(false);
  const [gcpButton, setGcpButton] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [blobUrl, setBlobUrl] = useState("");
  const [loadingImage, setLoadingImage] = useState(true);
  const [status, setStatus] = useState("");
  const [status1, setStatus1] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [purpose, setPurpose] = useState("");
  const [helpDescription, setHelpDescription] = useState("");
  const [projectBU, setProjectBU] = useState("");
  const [modifications, setModifications] = useState("");
  const [sasToken, setSasToken] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [rating, setRating] = useState(0);
  const [isRatingDialogOpen, setIsRatingDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  //
  // const [purpose, setPurpose] = useState("");

  const handleOpenRatingDialog = () => {
    setIsRatingDialogOpen(true);
  };

  const id = params.id;
  const prop = {
    AZURE: "AZURE",
    AWS: "AWS",
    GCP: "GCP",
  };

  const username = sessionStorage.getItem("username");
  const name = sessionStorage.getItem("name");
  const [imageLoading, setImageLoading] = useState(true);

  const detailClear = () => {
    setPurpose("");
    setHelpDescription("");
    setProjectBU("");
    setModifications("");
  };
  const handleDownloadClick = () => {
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    detailClear();
  };
  const isDownloadButtonDisabled =
    !purpose || !helpDescription || !projectBU || !modifications;
  const downloadTemplateClick = (e) => {
    e.preventDefault();
    let body = {
      email: username,
      name: name,
      templateFeedbackData: {
        templateName: document.templateName,
        purpose: purpose,
        helpDescription: helpDescription,
        projectBU: projectBU,
        modifications: modifications,
      },
    };

    if (body) {
      (async () => {
        try {
          const response = await saveTemplateFeedbackData(body);
          // Access the returned data
          if (response !== null) {
            setStatus("Template Feedback Saved successfully!!");
            setStatus1(200);
            detailClear();
          } else {
            setStatus("Feedback not Saved some error occur!!");
            setStatus1(500);
          }
        } catch (error) {
          // Handle any errors thrown during the fetch
          console.log("Error:", error);
        }
      })();
    } else {
    }
    const downloadUrl = `https://accelerator-func-app-02.azurewebsites.net/api/DownloadAttachments?code=VVvtXsSJtreTvnrITQjrfflX9Jp7N7c-UobW4Ozr1ZvUAzFujArYPg==&blob=${document.templateName}`;
    window.open(downloadUrl);
    setIsDialogOpen(false);
    setIsDrawerOpen(false);
  };

  const handleRateTemplate = () => {
    let body = {
      username: username,
      rating: rating,
      type: id,
    };
    if (body) {
      (async () => {
        try {
          const response = await saveTemplateRatingToDb(body);
          // Access the returned data
          if (response !== null) {
            setStatus("Rated successfully");
            setStatus1(200);
            detailClear();
            setSnackbarOpen(true);
          } else {
            setStatus("Rating not Saved some error occur!!");
            setStatus1(500);
          }
        } catch (error) {
          // Handle any errors thrown during the fetch
          console.log("Error:", error);
        }
      })();
    } else {
    }

    setRating(0);
    setIsRatingDialogOpen(false);
  };
  const handleCloseRatingDialog = () => {
    setIsRatingDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const fetchSasToken = async () => {
    try {
      const response = await getSasToken();
      if (response === null) {
        throw new Error("Failed to fetch SAS token.");
      }
      const sasToken = response;
      setSasToken(sasToken);
      setLoadingImage(false);
    } catch (error) {
      console.error("Error fetching SAS token:", error);
      setLoadingImage(false);
    }
  };

  const loadImageUrl = () => {
    const imageUrlFromDb = document.templateStructureUrl;
    setBlobUrl(imageUrlFromDb);
    setImageLoading(false);
  };

  const imageStyles = {
    height: "100%",
    width: "100%",
    maxHeight: { xs: "100%", md: "100%" },
    maxWidth: { xs: "100%", md: "100%" },
    borderRadius: "10px",
    alignSelf: "center",
  };

  useEffect(() => {
    fetchSasToken();
    loadImageUrl();
  }, []);

  const handleImageError = () => {
    fetchSasToken();
    loadImageUrl();
  };

  const signedBlobUrl = `${blobUrl}?${sasToken}`;

  useEffect(() => {
    loadImageUrl();
  }, [document.templateStructureUrl]);

  useEffect(() => {
    (async () => {
      try {
        const data1 = await fetchDataById(id);

        if (data1.cloudProvider === "AZURE") {
          setCostRegion(" eastus");
        } else if (data1.cloudProvider === "AWS") {
          setCostRegion(" us-east-1");
        } else if (data1.cloudProvider === "GCP") {
          setCostRegion(" us-central1");
        }

        if (data1 !== null) {
          setDocument(data1);
          const azure = data1.azure_provider;
          const aws = data1.aws_provider;
          const gcp = data1.gcp_provider;
          const isAzureButtonDisabled = !azure;
          const isAwsButtonDisabled = !aws;
          const isGcpButtonDisabled = !gcp;
          setAzureButton(isAzureButtonDisabled);
          setAwsButton(isAwsButtonDisabled);
          setGcpButton(isGcpButtonDisabled);

          setLoading(false);
        } else {
          setError(true);
          setDocument(null);
        }
      } catch (error) {
        // Handle any errors thrown during the fetch
        console.log("Error:", error);
      }
    })();
  }, []);

  const handleButtonClick = (e) => {
    setSelectedButton(e.target.value);
  };
  const handleConnectionButton = () => {
    navigate("/connection");
  };
  const handleButton = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  // ------------------------------------------------------------------------------
  if (!Array.isArray(document) && error) {
    return (
      <>
        {/* <DashboardNavbar /> */}
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
            <Header />
            <ErrorPage />
          </Box>
        </Box>
        {/* <Footer /> */}
      </>
    );
  }
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {/* <DashboardNavbar /> */}
      {status && status1 && (
        <Snack
          open={snackbarOpen}
          status={status1}
          data={status}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          handleClose={handleCloseSnackbar}
        />
      )}
      {loading ? (
        <>
          <Box sx={{ display: "flex" }}>
            <Sidenav />
            <Box component="main" sx={{ flexGrow: 1, p: 1, minWidth: 80 }}>
              <Header />
              {/* // <Loading /> */}
              <Box>
                <BeatLoader
                  color="#36d7b7"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 1}}>
            <Header />
            <Box
                  display="flex"
                  p={1}
                 
                >
                  {/* <Typography variant="body1" fontFamily="Equip">
                    <Typography variant="h6" fontFamily="EquipExtended">
                      Description
                    </Typography>
                    {document.details.description}
                  
                  </Typography> */}
                    <DescriptionComponent document={document}/>
                </Box>
            <Grid container spacing={1} alignItems={"center"} >
           
              <Grid item xs={12} md={6} lg={8}>
             
                <Box
                  display="flex"
                
                  p={1}
                  sx={{
                    width: "100%",
                  }}
                >
                  {loadingImage || !blobUrl || !sasToken ? (
                    // <Loading />
                    <BeatLoader
                      color="#36d7b7"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "50vh",
                      }}
                    />
                  ) : (
                    <ImageModel
                      imageUrl={signedBlobUrl}
                      onError={handleImageError}
                      onLoad={loadImageUrl}
                      style={imageStyles}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}  sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box pb={1} pt={1}>
                  <ComplexStatisticsCard
                    color="primary"
                    title="Template Monthly Cost"
                    count={
                      document?.templateCost !== undefined
                        ? "$ " +
                          parseFloat(
                            document.templateCost.totalMonthlyCost
                          ).toFixed(2)
                        : "--"
                    }
                    icon={<AttachMoneyIcon />}
                    percentage={{
                      color: "success",
                      amount: "",
                      label1: `** Cost shown is tentative. It can vary.`,
                      label2: `Cost Calculation Region: ${costRegion}`,
                    }}
                  />
                </Box>

                <Popup
                  open={isPopupOpen}
                  onClose={handleClosePopup}
                  provider={document.cloudProvider}
                  document={document}
                  username={username}
                />
                {/* <OrdersOverview /> */}
                <Box  sx={{
                  display:'flex',
                  flexDirection:'column',
                  backgroundColor:"#ffffff",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      padding: "20px",
                    
                    }} >
                 
                   <Box>
                   <Typography
                      variant="h6"
                      fontWeight="400"
                      fontFamily="Equip"
                    >
                      {document.cloudProvider}
                    </Typography>
                   </Box>
                    
                    <Divider />
                   
                    <Box sx={{padding:1}} marginTop={3} marginLeft={6}> 
                      <FormControl>
                        <div style={{ display: "flex" }}>
                          <Button
                             style={{
                              backgroundColor: "#40C2A0",
                              color: "white",
                              flex: 1,
                              marginRight: "5px"
                              
                              
                           
                            }}
                            variant="contained"
                            color="primary"
                            startIcon={<CloudDownloadIcon />}
                            onClick={handleButton}
                          >
                            Deploy
                          </Button>
                          <Button
                             style={{
                              backgroundColor: "#40C2A0",
                              color: "white",
                              flex: 1, 
                              marginLeft: "5px"
                              
                            }}
                            variant="contained"
                            color="secondary"
                            startIcon={<StarBorderIcon />}
                            onClick={handleOpenRatingDialog}
                          >
                          Ratings
                          </Button>
                          <ReusableDrawer
                            open={isRatingDialogOpen}
                            onClose={handleCloseRatingDialog}
                          >
                            <Stack spacing={5} p={3}>
                              <Typography
                                variant="h6"
                                fontFamily="EquipExtended"
                                sx={{
                                  backgroundColor: "#40C2A0",
                                  color: "white",
                                  // backgroundColor: "#1976D2", // Set your desired background color
                                  // color: "white", // Set text color
                                  padding: "12px 16px", // Adjust padding as needed
                                  borderRadius: "4px 4px 0 0", // Add rounded corners
                                }}
                              >
                                Rate This Template
                              </Typography>
                              <Typography
                                fontFamily="Equip"
                                fontWeight="400"
                                variant="body1"
                              >
                                We appreciate your feedback! Please take a
                                moment to rate this template.
                              </Typography>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  fontFamily="Equip"
                                  fontWeight="400"
                                  component="legend"
                                  sx={{ marginRight: "10px" }}
                                >
                                  Your Rating
                                </Typography>
                                <Rating
                                  size="large"
                                  name="template-rating"
                                  value={rating}
                                  onChange={(event, newValue) => {
                                    setRating(newValue);
                                  }}
                                />
                              </Box>
                              <Button
                                variant="contained"
                                onClick={handleRateTemplate}
                                sx={{
                                  marginTop: "20px",
                                  backgroundColor: "#40C2A0",
                                  color: "white",
                                }}
                              >
                                Submit Rating
                              </Button>
                            </Stack>
                          </ReusableDrawer>

                          {/* <Button
                            style={{ margin: 1 }}
                            variant="contained"
                            onClick={handleConnectionButton}
                          >
                            Connection
                          </Button> */}
                        </div>
                        <div>
                          <Button
                             style={{
                              marginTop:"5px",
                              width: "100%", 
                            }}
                            variant="outlined"
                            startIcon={<CloudDownloadIcon />}
                            onClick={handleDownloadClick}
                          >
                            Download Template
                          </Button>

                          <ReusableDrawer
                            open={isDrawerOpen}
                            onClose={handleCloseDrawer}
                          >
                            <Stack spacing={5} p={3}>
                              <Typography
                                variant="h6"
                                fontFamily="EquipExtended"
                                sx={{
                                  backgroundColor: "#40C2A0",
                                  color: "white", // Set text color
                                  padding: "10px 16px", // Adjust padding as needed
                                  borderRadius: "4px 4px 0 0", // Add rounded corners
                                }}
                              >
                                Download Template
                              </Typography>

                              <TextField
                                autoFocus
                                id="purpose"
                                label="Purpose of Template"
                                fullWidth
                                value={purpose}
                                onChange={(e) => setPurpose(e.target.value)}
                              />
                              <TextField
                                id="helpDescription"
                                label="How will this template help?"
                                fullWidth
                                value={helpDescription}
                                onChange={(e) =>
                                  setHelpDescription(e.target.value)
                                }
                              />
                              <TextField
                                id="projectBU"
                                label="Project BU"
                                fullWidth
                                value={projectBU}
                                onChange={(e) => setProjectBU(e.target.value)}
                              />
                              <TextField
                                id="modifications"
                                label="Any modifications needed?"
                                fullWidth
                                value={modifications}
                                onChange={(e) =>
                                  setModifications(e.target.value)
                                }
                              />
                              <DialogActions>
                                <Button
                                  color="error"
                                  onClick={handleCloseDrawer}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  sx={{ color: "#40C2A0" }}
                                  onClick={downloadTemplateClick}
                                  disabled={isDownloadButtonDisabled}
                                >
                                  {<DownloadIcon />}
                                </Button>
                              </DialogActions>
                            </Stack>
                          </ReusableDrawer>
                        </div>
                      </FormControl>
                    </Box>
                  
                </Box>
              </Grid>
            </Grid>
            <Projects data={document} />
          </Box>
        </Box>
      )}

      <Footer />
    </>
  );
}

export default Dashboard;
