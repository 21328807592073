import { Box, Divider } from "@mui/material";
import Sidenav from "../../Components/Sidenav";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import cloudImage from "../../../assets/images/cloud.jpg";
import { fetchAllData } from "../../../backend/backend";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Template from "./Template";
import Skeleton from "@mui/material/Skeleton";
import CardSkeleton from "./CardSkeleton";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import ErrorPage from "../../Components/ErrorPage";
import {
  getSearchData,
  getTemplateRatingFromDb,
} from "../../../backend/backend";
import { useParams } from "react-router-dom";
import ResponsiveCard from "./ResponisveCard";
import { Margin } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
export default function ShowAll({ data }) {
  const location = useLocation();
  const passedData = location.state.data;

  const [allData, setAllData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [newSearchedData, setNewSearchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [type, setType] = useState("");
  const [templateRating, setTemplateRating] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const provider = params.provider;

  const [retryCount, setRetryCount] = useState(0);

  const handleRetry = () => {
    setRetryCount(retryCount + 1);
  };

  useEffect(() => {
    (async () => {
      try {
        
        if (passedData !== null) {
          setAllData(passedData);

          setLoading(false);
        } else {
          setError(true);
          setAllData(null);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [provider]);
  if (!Array.isArray(allData) && error) {
    return (
      <>
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
            <Header
              newSearchedData={newSearchedData}
              setNewSearchedData={setNewSearchedData}
              allData={allData}
            />

            <ErrorPage />
          </Box>
        </Box>
      </>
    );
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Sidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Header />

        {loading ? (
          <Grid
            container
            spacing={2}
          
          >
            <CardSkeleton cards={8} />
          </Grid>
        ) : (
          <Box p={2} sx={{minHeight:"80vh"}}>
            <Grid container spacing={2}>
              {passedData.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <ResponsiveCard
                    title={item.templateDisplayName}
                    description={item.details.description}
                    type={item.templateName}
                    isPremium={item.tags[0]}
                    isCoeVerified={item.tags[1]}
                    templateStructureUrl={item.templateStructureUrl}
                  />
                  <Divider sx={{ Margin: 1 }} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        <Footer/>
      </Box>
    </Box>
  );
}
