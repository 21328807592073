import { Box, Button, Container, Grid, Paper } from "@mui/material";
import Header from "../../Components/Header";
import Sidenav from "../../Components/Sidenav";
import RecentlyAdded from "./HomepageTiles/RecentlyAdded";
import { Provider } from "./HomepageTiles/Providers";
import CoeVerified from "./HomepageTiles/CoeVerified";
import React, { useState, useEffect } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import Footer from "../../Components/Footer";
import FreeTemplate from "./HomepageTiles/FreeTemplate";
import PremiumTemplate from "./HomepageTiles/PremiumTemplate";
import { Fab, Zoom } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Test() {
  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [newSearchedData, setNewSearchedData] = useState([]);

  // useEffect(() => {
  //   const loaderTimeout = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);

  //   return () => clearTimeout(loaderTimeout);
  // }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handelClick = () => {};
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 1, minWidth: 80 }}>
          <Header
            newSearchedData={newSearchedData}
            setNewSearchedData={setNewSearchedData}
            allData={allData}
          />
{/* 
          {isLoading ? (
            <BeatLoader
              color="#36d7b7"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            />
          ) : ( */}
            <>
              <Box sx={{ p: 3 }}>
                <Provider searchData={newSearchedData} />
              </Box>

              <Box sx={{ p: 2 }}>
                <RecentlyAdded searchData={newSearchedData} />
              </Box>

              <Box sx={{ p: 2 }}>
                <CoeVerified searchData={newSearchedData} />
              </Box>
              <Box sx={{ p: 2 }}>
                <FreeTemplate />
              </Box>
              <Box sx={{ p: 2 }}>
                <PremiumTemplate />
              </Box>
            </>
          {/* )} */}

          <Footer />
        </Box>
      </Box>
    </>
  );
}

export default Test;
