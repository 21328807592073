import React, { useEffect, useState } from "react";
import { invokeLambdaFunction } from "../data/backend";
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TablePagination,
  Select,
  MenuItem,
  Box,
  TextField,
  styled,
  Tabs,
  Tab,
  Fab,
  Button,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useNavigate, useNavigation, useParams, Link } from "react-router-dom";
import Header from "../../components/Header";
import HomeIcon from "@mui/icons-material/Home";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import GroupIcon from "@mui/icons-material/Group";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../../../ArchitectureTemplate/Components/Footer";
import Sidenav from "../../components/Sidenav";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getSymbolFromCurrency from "currency-symbol-map";
import Snack from "../../../ArchitectureTemplate/Components/Snack";
import nagarrologo from "../../../assets/images/Nagarro Horizontal Light.png";
import { CloudDownload, HomeRepairServiceOutlined } from "@mui/icons-material";
import CustomSkeleton from "../../components/CustomSkeleton";
import {REQUESTCOUNT} from "../data/constants"
import {maxAllowedDate} from "../../dateConstants.js"
import {minAllowedDate} from "../../dateConstants.js"
const styles = {
  tableContainer: {
    maxWidth: "100%",
  },
  tableHead: {
    backgroundColor: "#F8F8FF",
  },
  tableCell: {
    fontFamily: "Equip",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  deleteButton: {
    color: "#f44336",
  },
};
const AccountsView = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { region, orgname, paramstartdate, paramenddate } = useParams();
  // console.log(level,region)
  const navigate = useNavigate();
  const [aggregatedData, setAggregatedData] = useState([]);
  const date = new Date().toISOString().split("T")[0];
  const [selectedTab, setSelectedTab] = useState("customer");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [currency, setCurrency] = useState("");
  const [status, setStatus] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [isButtonVisible,setIsButtonVisible]=useState(false);
  const [apiHitCount, setApiHitCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // useEffect(() => {
  //   // Increment API hit count on component mount
  //   setApiHitCount(prevCount => prevCount + 1);
  //    
  // }, []);
  // useEffect(() => {
  //   if (apiHitCount >= 2) {
  //     setStatus("soft limit");
  //     setStatusCode(500)
  //     setSnackbarOpen(true);
  //   }
  // }, [apiHitCount])
  useEffect(() => {
    if (startDate && endDate) {
      // console.log(selectedDate,startDate)
      fetchData(startDate, endDate);
      sessionStorage.setItem("endDate", endDate);
      sessionStorage.setItem("startDate", startDate);
    } else {
      setStartDate(paramstartdate);
      setEndDate(paramenddate);
      fetchData(paramstartdate, paramenddate);
    }
  }, [startDate, endDate]);
  //   useEffect(() => {
  //  (async () => {
  //       try {
  //         const response = await invokeLambdaFunction({ level:"accounts",org_name:orgname });
  //           // Log the response
  //         setData(response);
  //         console.log(data)
  //         setLoading(false);
  //         return response;

  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }

  //   ) ();
  //   }, []);
  const fetchData = async (startdate, enddate,accountIds) => {
    try {
      const response = await invokeLambdaFunction({
        level: "accounts",
        org_name: orgname,
        start_date: startdate,
        end_date: enddate,
        filters:accountIds
      });
       
      if (response.statusCode === 200 && response.body !== "No record found") {
        const jsontype = JSON.parse(response.body);
        setIsButtonVisible(true);
        setCurrency(jsontype[0].CostUnit);
        setLoading(false);
        if(response.request_count>=REQUESTCOUNT){
          setStatus(`You have exceeded 50% quota of monthly request limit for ${orgname} account`);
          setStatusCode(400);
          setSnackbarOpen(true);

        }

        // console.log(response.request_count)
        setData(jsontype);
      } else if (
        response.statusCode === 200 &&
        response.body === "No record found"
      ) {
        setStatus(response.body);
        setIsButtonVisible(false);
        setStatusCode(500);
        setData([]);
        setSnackbarOpen(true);
        setLoading(false);
      } else if (response.statusCode === 403) {
        setStatus(`You have exhausted the quota of monthly request limit for ${orgname} account`);
        setStatusCode(500);
        setIsButtonVisible(false);
        setData([]);
        setSnackbarOpen(true);
        setLoading(false);
      } else {
        setStatus("No Data is available");
        setStatusCode(500);
        setIsButtonVisible(false);
        setData([]);

        setSnackbarOpen(true);
        setLoading(false);
      }
   
     

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if necessary
    return `${year}-${month}-01`;
  };

  const handleDateChange = (newDate) => {
    const inputdate = newDate.toISOString().split("T")[0];
    setStartDate(inputdate);

    // Calculate the start date as the first day of the previous month
    const currentMonth = new Date(newDate);
    currentMonth.setMonth(currentMonth.getMonth() + 1);
    currentMonth.setDate(1); // Set to the first day of the month
    const formattedStartDate = formatDate(currentMonth);
    setEndDate(formattedStartDate);
  };
  function formatDateToDisplay(dateString) {
    const date = new Date(dateString);
    const options = {  month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
   
  const tableHeadStyle = {
    fontWeight: "bold",
    color: "#13294B",
    fontFamily: "EquipExtended",
    fontSize: 14,
  };
  const tableElementStyle = {
    fontFamily: "Equip",
    fontSize: 14,
    fontWeight: 400,
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleRowClick = (accountId, accountName) => {
    const level = "accounts";
    sessionStorage.setItem("accountName", accountName);
    navigate(
      `/aws/billingdashboard/services/${region}/${orgname}/${accountId}/${startDate}/${endDate}`
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const fetchAccountsWithServiceDetails = async () => {
    const accountsWithServiceDetails = [];

    for (const account of data) {
      const accountId = account.AccountId;
      const accountDetails = {
        AccountId: accountId,
        AccountName: account.AccountName,
        BillingMonth: formatDateToDisplay(account.StartDate),
       
        NetCost: account.NetCost.toFixed(2),
        CustomerCost: account.CustomerCost.toFixed(2),
        Currency: account.CostUnit,
        Services: [],
      };

      // Fetch service data for the current account
      const response = await fetchServiceData(accountId);
      const serviceData = JSON.parse(response.body);
      for (const service of serviceData) {
        const serviceDetails = {
          ServiceName: service.ServiceName,
          BillingMonth: formatDateToDisplay(service.StartDate),
         
          CustomerCost: service.CustomerCost.toFixed(2),
          NetCost: service.NetCost.toFixed(2),
        };
        accountDetails.Services.push(serviceDetails);
      }

      accountsWithServiceDetails.push(accountDetails);
    }
     
    return accountsWithServiceDetails;
  };

  // Assuming you have an API function to fetch service data
  const fetchServiceData = async (accountId) => {
    try {
      const response = await invokeLambdaFunction({
        level: "services",
        org_name: orgname,
        account_id: accountId,
        start_date: startDate,
        end_date: endDate,
      });
      return response;
    } catch (error) {
      console.error("Error fetching service data:", error);
      return [];
    }
  };

  const generatePDF = async () => {
    setIsGeneratingPDF(true);
    const accountsWithServiceDetails = await fetchAccountsWithServiceDetails();

    // Create a new PDF instance
    const doc = new jsPDF({});

    // Set initial y position for content
    let y = 50;
    const logoImg = new Image();
    logoImg.onload = function () {
      const logoWidth = 60; // Set the width of the logo
      const logoHeight = 60 * (logoImg.height / logoImg.width);
      const logoX = doc.internal.pageSize.getWidth() - logoWidth - 5; // Align the logo to the extreme right
      const logoY = 20 - logoHeight / 2; // Align the logo vertically with the header text
      doc.addImage(logoImg, "JPEG", logoX, logoY, logoWidth, logoHeight);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(20);
      doc.setTextColor("#000000");
      doc.text(`Cost breakdown`, 15, 23);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.setTextColor('#000000');
      doc.text(`Hyperscaler:`,15,35)
      doc.text(`Organisation Unit:`, 15, 40);
      doc.setFont("helvetica", "normal");
      doc.text(`AWS`, 48, 35);
      doc.text(`${orgname}`, 48, 40);
      // doc.setFont("helvetica", "bold");
      // doc.setFontSize(10);
      // doc.setTextColor("#000000");
      // doc.text(`Hyperscaler:`, 15, 40);
      // doc.text(`AWS:`, 60, 40);

      // Loop through each account object
      accountsWithServiceDetails.forEach((account) => {
        // Add account details to the PDF
        y += 10;
        Object.entries(account).forEach(([key, value]) => {
          if (key !== "Services") {
            doc.setFont("helvetica", "bold");
            doc.setFontSize(10);
            doc.setTextColor("#000000");
            doc.text(`${key}:`, 15, y); // Adjust X position as needed
            doc.setFont("helvetica", "normal");
            doc.text(`${value}`, 48, y); // Adjust X position as needed
            y += 5; // Adjust Y position as needed
          }
        });

        // Add services table to the PDF
        y += 10;
       
        y += 10;
        const servicesTable = [
          [
            "Service Name",
          
            
            "Customer Cost",
            "Net Cost",
          ],
        ];
        account.Services.forEach((service) => {
          servicesTable.push([
            service.ServiceName,
           
            service.CustomerCost,
            service.NetCost,
          ]);
        });

        // Define table options for each table
        const tableOptions = {
          theme: "grid",
          startY: y,
          head: [servicesTable[0]],
          body: servicesTable.slice(1),
          margin: { top: 10 },
          headStyles: {
            fillColor: "13294B", // Light gray color with low opacity
            textColor: "#FFFFFF", // Set header text color
            fontStyle: "bold",
          },
          bodyStyles: {
            textColor: "#000000", // Set body text color
          },
          styles: {
            font: "helvetica",
            fontSize: 10,
            cellPadding: 5,
          },
        };

        // Add table to the PDF
        doc.autoTable(tableOptions);

        // Add a page break between accounts
        y = doc.autoTable.previous.finalY + 10;
        if (y >200) {
          doc.addPage();
          y = 10;
         
        }
      });
      

      // Save the PDF
      doc.save("service_details.pdf");
      setIsGeneratingPDF(false);
    };
    logoImg.src = nagarrologo;
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage); // Update page state for TablePagination component
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Header />
          <Box sx={{ minHeight: "80vh" }}>
            {status && statusCode && (
              <Snack
                open={snackbarOpen}
                status={statusCode}
                data={status}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                handleClose={handleCloseSnackbar}
              />
            )}
            <div style={{ display: "flex" }}>
              <div
                style={{ flex: 1, margin: "10px", justifyItems: "flex-start" }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab
                    label="Home"
                    iconPosition="start"
                    icon={<HomeIcon />}
                    value="back"
                    component={Link}
                    to={`/aws/billingdashboard`}
                  />
                  <Tab
                    label={region}
                    value="maintable"
                    component={Link}
                    to={`/aws/billingdashboard/customers/${region}/${startDate}/${endDate}`}
                    iconPosition="start"
                    icon={<CloudQueueIcon />}
                  />
                  <Tab
                    label={orgname}
                    value="customer"
                    component={Link}
                    iconPosition="start"
                    icon={<GroupIcon />}
                  />
                </Tabs>
              </div>
              <div style={{ margin: "10px", justifyItems: "flex-end" }}>
                <TextField
                  sx={{ minWidth: 200, marginTop: 5, marginRight: 2 }}
                  value={currency}
                  variant="standard"
                />
                <DatePicker
                  className="datepicker"
                  label="Select Month and Year"
                  showMonthYearPicker
                  selected={new Date(startDate)}
                  value={formatDateToDisplay(startDate)}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  maxDate={maxAllowedDate}
                  minDate={minAllowedDate}
                />
                {/* <FormControl >
              <Select
               sx={{ minWidth: 200, marginTop:5  }}
                // value={currentDate}
                // onChange={handleInvoiceDateChange}
                displayEmpty
                variant="standard"
               
              > */}
                {/* {filteredData2 &&
                  filteredData2.map((row) => (
                    <MenuItem key={row.InvoiceDate} value={row.InvoiceDate}>
                      {row.InvoiceDate}
                    </MenuItem>
                  ))} */}
                {/* </Select>
            </FormControl> */}
              </div>
            </div>
            <TableContainer
              style={styles.tableContainer}
              sx={{ boxShadow: "0px 4px 8px rgba(186, 220, 211, 5)" }}
            >
              <Table aria-label="pivot table">
                <TableHead style={styles.tableHead}>
                  <TableRow>
                    <TableCell />
                    {/* <TableCell  style={tableHeadStyle} >Region</TableCell> */}
                    <TableCell style={tableHeadStyle}>Account Name</TableCell>
                    {/* <TableCell style={tableHeadStyle}>
                      Account Id
                    </TableCell>
                    <TableCell style={tableHeadStyle}>
                     Currency
                    </TableCell> */}

                    {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}
                    <TableCell style={tableHeadStyle}>Billing Month</TableCell>
                  

                    <TableCell
                      style={{ ...tableHeadStyle,  textAlign: loading ? " " : "right",}}
                    >
                      {" "}
                      Net Cost
                    </TableCell>
                    <TableCell
                      style={{ ...tableHeadStyle,  textAlign: loading ? " " : "right",}}
                    >
                      Customer Cost
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loading ? (
          // Render SkeletonLoaderRow component when loading
          Array.from({ length: 5 }).map((_, index) => (
            <CustomSkeleton key={index} numColumns={5} />
          ))
        ) : (data &&
          data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index)=> (
                      <React.Fragment key={index}>
                        <TableRow
                          className="tableRowHover"
                          // onClick={() =>
                          //   handleRowClick(row.AccountId, row.AccountName)
                          // }
                        >
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRowClick(row.AccountId, row.AccountName);
                              }}
                            >
                              {/* {expandedRow === row.key ? (
                            <ExpandMoreIcon />
                          ) : ( */}
                              <AddIcon />
                              {/* )} */}
                            </IconButton>
                          </TableCell>
                          {/* <TableCell style={tableElementStyle}>{row.CustomerRegion}</TableCell> */}
                          <TableCell style={tableElementStyle}>
                            {row.AccountName}
                          </TableCell>
                          {/* <TableCell style={tableElementStyle}>
                          {row.AccountId}
                        </TableCell>
                        <TableCell style={tableElementStyle}>
                          {row.CostUnit}
                        </TableCell> */}
                          {/* <TableCell style={tableElementStyle}>
                          {row.EndDate}
                        </TableCell> */}
                          {/* <TableCell style={tableElementStyle}>
                      {row.Currency}
                    </TableCell> */}
                          <TableCell
                            style={{ ...tableElementStyle, textAlign: "left" }}
                          >
                            {formatDateToDisplay(row.StartDate)}
                          </TableCell>
                          

                          {/* <TableCell style={tableElementStyle}>{row.DueDate}</TableCell> */}
                          <TableCell
                            style={{ ...tableElementStyle,  textAlign: loading ? " " : "right",}}
                          >
                            {" "}
                            {getSymbolFromCurrency(row.CostUnit)}{" "}
                            {row.CustomerCost.toFixed(2)}
                          </TableCell>
                          <TableCell
                            style={{ ...tableElementStyle,  textAlign: loading ? " " : "right",}}
                          >
                            {" "}
                            {getSymbolFromCurrency(row.CostUnit)} {row.NetCost.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    )))}
                </TableBody>
              </Table>
            </TableContainer>

            {data && data.length > 5 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        {isButtonVisible ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                onClick={generatePDF}
                variant="contained"
                color="primary"
                size="small"
                disabled={isGeneratingPDF}
                style={{
                  backgroundColor: isGeneratingPDF ?"#808080": "#47D7AC", // Change background color to #47D7AC
                  color: "#13294B", // Change text color to #13294B
                  padding: "10px 15px", // Reduce padding to make it smaller
                  borderRadius: "32px", // Apply border-radius
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                {isGeneratingPDF ? ( // Display loader or CloudDownload icon based on PDF generation state
                  <CircularProgress
                    size={15}
                    color="inherit"
                    style={{ marginRight: "5px" }}
                  />
                ) : (
                  <CloudDownload style={{ marginRight: "5px" }} />
                )}
                Download PDF
              </Button>
            </div>
        ):(<div>
          
        </div>)}
          </Box>

          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default AccountsView;
