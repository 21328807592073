import React from "react";
import { Box, Typography } from "@mui/material";

const NoRecordFoundMessage = () => {
  return (
    <Box className="no-records-found" textAlign="center" py={4}>
      <Typography variant="h5">No Records Found</Typography>
      <Typography variant="body1">
        You can add new records and see them here.
      </Typography>
    </Box>
  );
};

export default NoRecordFoundMessage;
