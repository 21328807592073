import Papa from "papaparse";
import exampleCsv from "../data/billingdata.csv";
import { fetchGcpRecords, getRoles } from "../../backend/backend";
const parseCsvAndAggregate = async (callback) => {
  let projectRoles = [];
 
  try {
    const projectRole = "";
    // 011A1E-876B2C-C1395A
    // console.log(getRoles(sessionStorage.getItem("name"),sessionStorage.getItem("username")))
    const userData = await getRoles(
      sessionStorage.getItem("name"),
      sessionStorage.getItem("username"),"GCP"
    );
    const { billing_account_details, message, user_role, useremail, username } =
      userData;
    // console.log(billing_account_details)
    // console.log(message)
    // console.log(user_role)
    // console.log(useremail)
    // console.log(userData)
    sessionStorage.setItem("userrole", user_role);
    if(user_role!=="Admin" && user_role!="Member"){
     projectRoles = billing_account_details.map(
      (account) => account.billing_account_name
    );
  }
     const apiData=await fetchGcpRecords();
    //  console.log(apiData);
     const dataWithDefaults = apiData.map((row) => {
      // Apply any necessary transformations here
      for (const key in row) {
        if (row[key] === "" || row[key] === undefined || row[key] === null) {
          row[key] = isNaN(parseFloat(row[key])) ? 0 : "-";
        }
      }
      return row;
    });

        // Aggregate data here
        const aggregatedData = dataWithDefaults.reduce((acc, curr) => {
          //         if(user_role !=="")
          //  {      if (curr.BillingAccountId !== projectRole) {
          //           return acc; // Skip this row if it doesn't match
          //         }}
          if (user_role != "Admin") {
            if (
              !projectRoles.includes(curr.BillingAccountId) &&
              !projectRoles.includes(curr.RootBillingAccountId)
            ) {
              return acc; // Skip this row if it doesn't match
            }
          }
          // const key = `${curr.CustomerRegion}_${curr.RootBillingAccountId}_${curr.Currency}_${curr.InvoiceDate}`;
          const key = `${curr.RootBillingAccountId}_${curr.InvoiceDate}`;
          // const key =`beeline-lossprevention`
          // console.log(key)
          const existing = acc.find((item) => item.key === key);
          //  
          if (!existing) {
            acc.push({
              key,
              CustomerRegion: curr.CustomerRegion,
              RootBillingAccountId: curr.RootBillingAccountId,
              RootBillingAccountName: curr.RootBillingAccountName,
              RootBillingAccountRegion: curr.BillingAccountRegion,
              Currency: curr.Currency,
              InvoiceDate: curr.InvoiceDate,
              DueDate: curr.DueDate,
              TotalNetCost: parseFloat(curr.NetCost),
              TotalCustomerCost:
                curr.Customer === "Nagarro Internal"
                  ? parseFloat(curr.NetCost)
                  : parseFloat(curr.CustomerCost),

              Customers: [
                {
                  Customer: curr.Customer,
                  NetCost: parseFloat(curr.NetCost),
                  CustomerCost:
                    curr.Customer === "Nagarro Internal"
                      ? parseFloat(curr.NetCost)
                      : parseFloat(curr.CustomerCost),
                  Projects: [
                    {
                      Project: curr.Project,
                      BillingAccount: curr.BillingAccountId,
                      NetCost: parseFloat(curr.NetCost),
                      CustomerCost:
                        curr.Customer === "Nagarro Internal"
                          ? parseFloat(curr.NetCost)
                          : parseFloat(curr.CustomerCost),

                      Services: [
                        {
                          Service: curr.Service,
                          Cost: parseFloat(curr.NetCost) || 0,
                          Discounts: parseFloat(curr.Discounts) || 0,
                          PromotionsAndOthers: parseFloat(curr.Promotion) || 0,
                          CustomerCost:
                            curr.Customer === "Nagarro Internal"
                              ? parseFloat(curr.NetCost)
                              : parseFloat(curr.CustomerCost),
                        },
                      ],
                    },
                  ],
                },
              ],
            });
          } else {
            existing.TotalNetCost += parseFloat(curr.NetCost);
            existing.TotalCustomerCost +=
              curr.Customer === "Nagarro Internal"
                ? parseFloat(curr.NetCost)
                : parseFloat(curr.CustomerCost);

            const existingCustomer = existing.Customers.find(
              (cust) => cust.Customer === curr.Customer
            );
            if (!existingCustomer) {
              existing.Customers.push({
                Customer: curr.Customer,
                NetCost: parseFloat(curr.NetCost),
                CustomerCost:
                  curr.Customer === "Nagarro Internal"
                    ? parseFloat(curr.NetCost)
                    : parseFloat(curr.CustomerCost),
                Projects: [
                  {
                    Project: curr.Project,
                    BillingAccount: curr.BillingAccountId,
                    NetCost: parseFloat(curr.NetCost),
                    CustomerCost:
                      curr.Customer === "Nagarro Internal"
                        ? parseFloat(curr.NetCost)
                        : parseFloat(curr.CustomerCost),

                    Services: [
                      {
                        Service: curr.Service,
                        Cost:
                          parseFloat(curr.NetCost) ||
                          0 + parseFloat(curr.ResellerMargin) ||
                          0,
                        Discounts: parseFloat(curr.Discounts) || 0,
                        PromotionsAndOthers: parseFloat(curr.Promotion) || 0,
                        CustomerCost:
                          curr.Customer === "Nagarro Internal"
                            ? parseFloat(curr.NetCost)
                            : parseFloat(curr.CustomerCost),
                      },
                    ],
                  },
                ],
              });
            } else {
              existingCustomer.NetCost += parseFloat(curr.NetCost);
              existingCustomer.CustomerCost +=
                curr.Customer === "Nagarro Internal"
                  ? parseFloat(curr.NetCost)
                  : parseFloat(curr.CustomerCost);

              const existingProject = existingCustomer.Projects.find(
                (proj) => proj.Project === curr.Project
              );
              if (!existingProject) {
                existingCustomer.Projects.push({
                  Project: curr.Project,
                  BillingAccount: curr.BillingAccountId,
                  NetCost: parseFloat(curr.NetCost),
                  CustomerCost:
                    curr.Customer === "Nagarro Internal"
                      ? parseFloat(curr.NetCost)
                      : parseFloat(curr.CustomerCost),
                  Services: [
                    {
                      Service: curr.Service,
                      Cost:
                        parseFloat(curr.NetCost) ||
                        0 + parseFloat(curr.ResellerMargin) ||
                        0,
                      Discounts: parseFloat(curr.Discounts) || 0,
                      PromotionsAndOthers: parseFloat(curr.Promotion) || 0,
                      CustomerCost:
                        curr.Customer === "Nagarro Internal"
                          ? parseFloat(curr.NetCost)
                          : parseFloat(curr.CustomerCost),
                    },
                  ],
                });
              } else {
                existingProject.NetCost += parseFloat(curr.NetCost);
                existingProject.CustomerCost +=
                  curr.Customer === "Nagarro Internal"
                    ? parseFloat(curr.NetCost)
                    : parseFloat(curr.CustomerCost);

                const existingService = existingProject.Services.find(
                  (serv) => serv.Service === curr.Service
                );
                if (!existingService) {
                  existingProject.Services.push({
                    Service: curr.Service,
                    Cost:
                      parseFloat(curr.NetCost) ||
                      0 + parseFloat(curr.ResellerMargin) ||
                      0,
                    Discounts: parseFloat(curr.Discounts) || 0,
                    PromotionsAndOthers: parseFloat(curr.Promotions) || 0,
                    CustomerCost:
                      curr.Customer === "Nagarro Internal"
                        ? parseFloat(curr.NetCost)
                        : parseFloat(curr.CustomerCost),
                  });
                } else {
                  existingService.Cost +=
                    parseFloat(curr.NetCost) + parseFloat(curr.ResellerMargin);
                  existingService.Discounts += parseFloat(curr.Discounts);
                  existingService.PromotionsAndOthers += parseFloat(
                    curr.Promotions
                  );
                  existingService.CustomerCost +=
                    curr.Customer === "Nagarro Internal"
                      ? parseFloat(curr.NetCost)
                      : parseFloat(curr.CustomerCost);
                }
              }
            }
          }

          return acc;
        }, []);

        // Pass the aggregated data to the callback function
        callback(aggregatedData);
      }
   catch (error) {
    console.error("Error fetching user roles:", error);
  }
};
export default parseCsvAndAggregate;
