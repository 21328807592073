import AWS from './aws-config'
export async function invokeLambdaFunction(body) {
    const lambda = new AWS.Lambda();
  
    const params = {
      FunctionName: 'billingboard_data',
      InvocationType: 'RequestResponse', 
      LogType: 'None', 
      Payload: JSON.stringify(body)
    };
  
    try {
      const data = await lambda.invoke(params).promise();
      //  console.log(data);
      
      let parsedPayload;
      if (data.Payload && data.Payload !== '') {
        // If Payload is not empty, parse it
        parsedPayload = JSON.parse(data.Payload);
      } else if (data.$response && data.$response.data && data.$response.data.Payload) {
        // If Payload is nested under $response, parse it
        parsedPayload = JSON.parse(data.$response.data.Payload);
      } else {
        // If Payload is not found, construct an object with the status code and body
        parsedPayload = { statusCode: data.StatusCode || 500, body: "No response received" };
      }
      
       
      return parsedPayload;
    } catch (error) {
      console.error('Error invoking Lambda function:', error);
      return { statusCode: 500, body: error.message };
    }
}
  