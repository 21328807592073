import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';

const ShowAllLink = ({ route, data }) => {
  const navigate = useNavigate();

  const handleShowAllClick = () => {
    navigate(route, { state: { data } });
  };

  return (
    <Link
      component="button"
      onClick={handleShowAllClick}
      sx={{
        fontFamily: 'Equip',
        color: '#3AAE92',
        cursor: 'pointer',
      }}
    >
      
      See All ({data.length})
    </Link>
  );
};

export default ShowAllLink;
