import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import { blue, red, grey } from "@mui/material/colors";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PendingIcon from "@mui/icons-material/Pending";
import Loading from "../Components/Loading";

import {
  getLogsUrl,
  pipelineStatus,
  addTemplateToDB,
  deploymentDeletionFromDB,
} from "../../backend/backend";
import Livelogs from "./Livelogs";
import { Paper, Box, Stack } from "@mui/material";
import Sidenav from "../Components/Sidenav";
import Header from "../Components/Header";

const pipelineStatusStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  fontSize: "2rem",
  fontWeight: "bold",
  background: "#f0f0f0",
  border: "1px solid #ccc",
  borderRadius: "14px",
};

const PipelineStatus = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [currResponse, setCurrResponse] = useState([]);
  const [check, setCheck] = useState(false);
  const [error, setError] = useState(false);
  const [initLogEntry, setInitLogEntry] = useState("");
  const [planLogEntry, setPlanLogEntry] = useState("");
  const [applyLogEntry, setApplyLogEntry] = useState("");
  const [destroyLogEntry, setDestroyLogEntry] = useState("");
  const [initCount, setInitCount] = useState(0);
  const [planCount, setPlanCount] = useState(0);
  const [applyCount, setApplyCount] = useState(0);
  const [destroyCount, setDestroyCount] = useState(0);
  const [inQueue, setInQueue] = useState(false);
  const [initState, setInitState] = useState("");

  const buildId = params.buildId;
  const pipelineId = params.pipelineId;
  const service_connection_name = params.serviceConn;
  const templateName = params.templateName;
  const username = sessionStorage.getItem("username");

  const getLogs = async () => {
    try {
      const data = await getLogsUrl(buildId);
      return data;
    } catch (error) {
      console.log("Error getting  pipeline logs" + error);
    }
  };

  useEffect(() => {
    getLogs()
      .then((logs) => {
        let logsData = logs.response.value[0];
        logs.response.value.forEach((logEntry) => {
          if (logEntry.id === 9) {
            setInitLogEntry(logEntry.url);
          } else if (logEntry.id === 10) {
            setPlanLogEntry(logEntry.url);
          } else if (logEntry.id === 13) {
            setApplyLogEntry(logEntry.url);
          } else if (logEntry.id === 14) {
            setDestroyLogEntry(logEntry.url);
          }
        });
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  }, [initCount, planCount, applyCount, destroyCount]);

  const fetchPipelineStatus = async () => {
    try {
      let body = {
        buildId: buildId,
      };
      const response = await pipelineStatus(body);

      return response;
    } catch (error) {
       
    }
  };

  const addTemplatesToDB = async () => {
    let cloud_provider;
    if (pipelineId === "1") {
      cloud_provider = "AZURE";
    } else if (pipelineId === "3") {
      cloud_provider = "AWS";
    } else if (pipelineId === "5") {
      cloud_provider = "GCP";
    }
    try {
      let body = {
        buildId: buildId,
        user_name: username,
        service_connection_name: service_connection_name,
        cloudProviderName: cloud_provider,
        pipelineId: pipelineId,
        templateName: templateName,
      };

      const response = await addTemplateToDB(body);

      return response;
    } catch (error) {
       
    }
  };

  const destroyTemplatesFromDB = async () => {
    let cloud_provider;
    if (pipelineId === "1") {
      cloud_provider = "AZURE";
    } else if (pipelineId === "3") {
      cloud_provider = "AWS";
    } else if (pipelineId === "5") {
      cloud_provider = "GCP";
    }
    try {
      let body = {
        buildId: buildId,
        user_name: username,
        cloud_provider: cloud_provider,
        templateName: templateName,
      };

      const response = await deploymentDeletionFromDB(body);

      // const data = response.data;
      return response;
    } catch (error) {
       
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPipelineStatus()
        .then((data) => {
          if (data === null) {
            setError(true);
            clearInterval(interval);
          }

          const res1 = data?.data.records
            .filter((item) => ["Phase"].includes(item.type))
            .map((item) => ({
              itemName: item.name,
              itemState: item.state,
              itemResult: item.result,
            }));

          const currentPipelineStatus = res1.some(
            (item) => item.itemState === "pending"
          );

          if (currentPipelineStatus) {
            setInQueue(true);
          } else {
            setInQueue(false);
          }

          const res = data?.data.records
            .filter((item) =>
              ["init", "plan", "apply", "destroy"].includes(item.name)
            )
            .map((item) => ({
              itemName: item.name,
              itemState: item.state,
              itemResult: item.result,
            }));
          const applyItem = res.find((item) => item.itemName === "apply");
          const applyItem1 = res.find((item) => item.itemName === "init");
          const applyItem2 = res.find((item) => item.itemName === "plan");
          const applyItem3 = res.find((item) => item.itemName === "destroy");
          setInitState(applyItem1?.itemState);
          if (applyItem1) {
            setLoading(false);
          }

          if (
            applyItem1 &&
            applyItem1.itemState === "completed" &&
            applyItem1.itemResult
          ) {
            setInitCount(initCount + 1);
          }
          if (
            applyItem2 &&
            applyItem2.itemState === "completed" &&
            applyItem2.itemResult
          ) {
            setPlanCount(planCount + 1);
          }
          if (
            applyItem &&
            applyItem.itemState === "completed" &&
            applyItem.itemResult === "succeeded"
          ) {
            setApplyCount(applyCount + 1);
            addTemplatesToDB();
            clearInterval(interval);
          }
          if (
            applyItem3 &&
            applyItem3.itemState === "completed" &&
            applyItem3.itemResult === "succeeded"
          ) {
            setDestroyCount(applyCount + 1);
            destroyTemplatesFromDB();
            clearInterval(interval);
          }
          if (
            applyItem3 &&
            applyItem3.itemState === "completed" &&
            applyItem3.itemResult
          ) {
            setDestroyCount(applyCount + 1);
            clearInterval(interval);
          }

          const sortedRes = res.sort((a, b) => {
            const order = ["init", "plan", "apply", "destroy"];
            return order.indexOf(a.itemName) - order.indexOf(b.itemName);
          });
          setCurrResponse(sortedRes);
          setCheck(true);
        })
        .catch((error) => {
           
        });
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (error) {
    return (
      <>
        <Box mt={8}>
          <h2>An error occurred while fetching data please try again.</h2>
        </Box>
      </>
    );
  }
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Header />
          <div>
            {inQueue ? (
              <div
                className="loadingText"
                style={{ animation: "queueAnimation 2s infinite" }}
              >
                Your pipeline is in queue. Please wait for a while.
              </div>
            ) : (
              <Timeline>
                <div>
                  {loading ? (
                    <Loading />
                  ) : (
                    <React.Fragment>
                      <div
                        className="loadingText"
                        style={{ animation: "resourcesAnimation 1s infinite" }}
                      ></div>
                      {currResponse.map((item, index) => (
                        <TimelineItem key={index}>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                          >
                            <h3>{item.itemName}</h3>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                              {item.itemState === "completed" &&
                              item.itemResult === "succeeded" ? (
                                <TaskAltIcon color="success" fontSize="large" />
                              ) : item.itemState === "completed" &&
                                item.itemResult === "skipped" ? (
                                <PendingIcon fontSize="large" />
                              ) : item.itemState === "completed" &&
                                item.itemResult === "failed" ? (
                                <CancelIcon
                                  sx={{ color: red[500] }}
                                  fontSize="large"
                                />
                              ) : item.itemState === "inProgress" ? (
                                <AccessTimeFilledIcon
                                  sx={{ color: blue[500] }}
                                  fontSize="large"
                                />
                              ) : (
                                <PendingIcon fontSize="large" />
                              )}
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "60px", px: 10 }}>
                            <Typography variant="h6" component="span">
                              <div>
                                {item.itemState === "completed" && (
                                  <div>
                                    {item.itemName === "init" && (
                                      <Livelogs
                                        logsUrl={initLogEntry}
                                        stage={item.itemName}
                                      />
                                    )}

                                    {item.itemName === "plan" && (
                                      <Livelogs
                                        logsUrl={planLogEntry}
                                        stage={item.itemName}
                                      />
                                    )}

                                    {item.itemName === "apply" && (
                                      <Livelogs
                                        logsUrl={applyLogEntry}
                                        stage={item.itemName}
                                      />
                                    )}
                                    {item.itemName === "destroy" && (
                                      <Livelogs
                                        logsUrl={destroyLogEntry}
                                        stage={item.itemName}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </React.Fragment>
                  )}
                </div>
              </Timeline>
            )}
          </div>

          {loading ? (
            ""
          ) : (
            <React.Fragment>
              <div style={{ marginLeft: "20px" }}>
                <Stack direction="row">
                  <div style={{ display: "flex" }}>
                    <AccessTimeFilledIcon
                      sx={{ color: blue[500] }}
                      fontSize="large"
                    />
                    <h4 style={{ marginLeft: "20px", marginTop: "5px" }}>
                      Stage InProgress
                    </h4>
                  </div>
                  <div style={{ display: "flex" }}>
                    <PendingIcon fontSize="large" sx={{ color: grey[500] }} />
                    <h4 style={{ marginLeft: "20px", marginTop: "5px" }}>
                      Stage pending
                    </h4>
                  </div>
                  <div style={{ display: "flex" }}>
                    <TaskAltIcon color="success" fontSize="large" />
                    <h4 style={{ marginLeft: "20px", marginTop: "5px" }}>
                      Stage success
                    </h4>
                  </div>
                  <div style={{ display: "flex" }}>
                    <CancelIcon sx={{ color: red[500] }} fontSize="large" />
                    <h4 style={{ marginLeft: "20px", marginTop: "5px" }}>
                      Stage Failed
                    </h4>
                  </div>
                </Stack>
              </div>
            </React.Fragment>
          )}

          <br />
          {/* <Footer /> */}
        </Box>
      </Box>
    </>
  );
};

export default PipelineStatus;
