
import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId:process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey:process.env.REACT_APP_SECRETACCESSKEY,
  region: 'us-east-1', 
  AWS_SDK_LOAD_CONFIG:"1"
});

export default AWS;
