import React from "react";
import Deployments from "./ArchitectureTemplate/Pages/Deployments/Deployments";
import Feedback from "./ArchitectureTemplate/Pages/Feedback/Feedback";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import LoginCallback from "./Authentication/LoginCallback";
import Basic from "./Authentication/Login";
import Dashboard from "./ArchitectureTemplate/Pages/dashboard";
import Pagenotfound from "./ArchitectureTemplate/Pages/Pagenotfound";
import PipelineStatus from "./ArchitectureTemplate/PipelineStatus/PipelineStatus";
import WikiHome from "./ArchitectureTemplate/Pages/wiki/WikiHome";
import ConnectionGuide from "./ArchitectureTemplate/Pages/wiki/ConnectionGuide";
import CreateServiceConnectionForm from "./ArchitectureTemplate/Pages/Connection/CreateServiceConnectionForm";
import ExistingServiceConnectionsTable from "./ArchitectureTemplate/Pages/Connection/ExistingServiceConnectionsTable";
import Home from "./ArchitectureTemplate/Pages/Homepage/Home";
import Test from "./ArchitectureTemplate/Pages/Homepage/Home";
 
import ProviderDashboard from "./ArchitectureTemplate/Pages/Homepage/ProviderDashboard";
import SearchedData from "./ArchitectureTemplate/Pages/Homepage/SearchedData";
import SessionExpired from "./ArchitectureTemplate/Components/SessionExpired";
import ShowAll from "./ArchitectureTemplate/Pages/Homepage/ShowAll";
import AddTemplates from "./ArchitectureTemplate/Pages/AddTemplates/AddTemplates";
import Homepage from "./CloudPracticePortal/pages/Homepage";
import AggregatedData from "./BillingDashboard/Pages/AggregatedData"
import CustomerDetailsTable from "./BillingDashboard/Pages/CustomerDetailsTable"
import ProjectDetailsTable from "./BillingDashboard/Pages/ProjectDetailsTable"
import ServiceDetailsTable from "./BillingDashboard/Pages/ServiceDetailsTable"
import ShowAllData from "./CloudPracticePortal/pages/ShowAllData";
import RegionView from "./BillingDashboard/awsBillingDashboard/Pages/RegionView";
import CustomerView from "./BillingDashboard/awsBillingDashboard/Pages/CustomerView";
import AccountsView from "./BillingDashboard/awsBillingDashboard/Pages/AccountsView";
import ServiceView from "./BillingDashboard/awsBillingDashboard/Pages/ServiceView";
import HomePage from "./BillingDashboard/Homepage";
let token_expiry_time = sessionStorage.getItem("token_expiry");
 
function checkSession() {
  const currentTime = new Date().getTime();
  const storedTime = sessionStorage.getItem("token_creation_time");
 
  if (storedTime && currentTime - parseInt(storedTime) > 59 * 60 * 1000) {
    // More than 1 hour has passed, navigate to "sessionExpired" route
    return true;
  } else return false;
}
 
const AuthGuard = ({ children }) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const isCurrentSessionExpired = checkSession(token_expiry_time);
  if (accessToken && !isCurrentSessionExpired) {
    return children;
  } else {
    // return <Navigate to="/?unauthorized=true" />;
    return <Navigate to="/sessionExpired" />;
  }
};
 
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Basic />} />
          <Route path="/callback" exact element={<LoginCallback />} />
          <Route
            // path="/homepage"
            path = "/coe-portal/architecture-template/home/:id"
            exact
            element={
              <AuthGuard>
                <Home />
              </AuthGuard>
            }
          />
          <Route
            path="/coe-portal"
            exact
            element={
              <AuthGuard>
                <Homepage/>
              </AuthGuard>
            }
          />
          <Route
            path="/coe-portal/architecture-template/AvailableTemplates/:suggestion"
            exact
            element={
              <AuthGuard>
                <SearchedData />
              </AuthGuard>
            }
          />
          <Route
            // path="/deployment"
            path="/coe-portal/architecture-template/deployement"

            exact
            element={
              <AuthGuard>
                <Deployments />
              </AuthGuard>
            }
          />
          <Route
            path="/coe-portal/architecture-template/wiki"
            element={
              <AuthGuard>
                <WikiHome />
              </AuthGuard>
            }
          />
          <Route
            path="/coe-portal/architecture-template/feedback"
            exact
            element={
              <AuthGuard>
                <Feedback />
              </AuthGuard>
            }
          />
 
          <Route
            path="/coe-portal/architecture-template/dashboard/:id"
            exact
            element={
              <AuthGuard>
                <Dashboard />
              </AuthGuard>
            }
          />
          <Route
            path="/coe-portal/architecture-template/PipelineStatus/status/:buildId/:pipelineId/:serviceConn/:templateName"
            exact
            element={
              <AuthGuard>
                <PipelineStatus />
              </AuthGuard>
            }
          />
          <Route
            path="/coe-portal/architecture-template/ConnectionGuide"
            element={
              <AuthGuard>
                <ConnectionGuide />
              </AuthGuard>
            }
          />
          <Route path="/test" element={<Test />} />
          <Route
            path="/coe-portal/architecture-template/CreateConnection"
            element={
              <AuthGuard>
                <CreateServiceConnectionForm />
              </AuthGuard>
            }
          />
          <Route
            path="/coe-portal/architecture-template/MyConnections"
            element={
              <AuthGuard>
                <ExistingServiceConnectionsTable />
              </AuthGuard>
            }
          />
          <Route
            path="/coe-portal/architecture-template/:provider"
            element={
              <AuthGuard>
                <ProviderDashboard />
              </AuthGuard>
            }
          />
          <Route
            path="/addtemplates"
            element={
              <AuthGuard>
                <AddTemplates />
              </AuthGuard>
            }
          />
          {/* billing dashboard routing */}
            <Route
            path="/gcp/billingdashboard"
            element={
              <AuthGuard>
              <AggregatedData/>
              </AuthGuard>
            }
          />
            <Route
            path="/gcp/billingdashboard/:key"
            element={
              <AuthGuard>
              <CustomerDetailsTable />
              </AuthGuard>
            }
          />
            <Route
            path="/gcp/billingdashboard/:key/:customername"
            element={
              <AuthGuard>
              <ProjectDetailsTable />
              </AuthGuard>
            }
          />
             <Route
            path="/gcp/billingdashboard/:key/:customername/:projectname"
            element={
              <AuthGuard>
              <ServiceDetailsTable />
              </AuthGuard>
            }
          />
            <Route
            path="/aws/billingdashboard"
            element={
              <AuthGuard>
              <RegionView />
              </AuthGuard>
            }
          />
          <Route
            path="/aws/billingdashboard/:level/:region/:paramstartdate/:paramenddate"
            element={
              <AuthGuard>
              <CustomerView />
              </AuthGuard>
            }
          />
          <Route
            path="/aws/billingdashboard/accounts/:region/:orgname/:paramstartdate/:paramenddate"
            element={
              <AuthGuard>
              <AccountsView />
              </AuthGuard>
            }
          />
           <Route
            path="/aws/billingdashboard/services/:region/:orgname/:accountId/:paramstartdate/:paramenddate"
            element={
              <AuthGuard>
              <ServiceView />
              </AuthGuard>
            }
          />
          <Route path="/billingdashboard" element={<HomePage/>}/>
          <Route path="/coe-portal/architecture-template/ShowAll" element={<ShowAll />} />
          <Route path="/show-all-data" element={<ShowAllData />} />
          <Route path="*" exact element={<Pagenotfound />} />
          <Route path="/sessionExpired" element={<SessionExpired />} />
        </Routes>
      </BrowserRouter>
     
    </div>
  );
}
 
export default App;