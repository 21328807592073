import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Skeleton,
} from "@mui/material";

const CardSkeleton = (cards) => {
  return Array(cards)
    .fill(0)
    .map((item, index) => (
      <div className="responsive-card" key={index}>
      <div className="image-section">
        <Skeleton variant="rectangular" width={150} height={100} />
      </div>
      <div className="content-section">
        <Skeleton variant="text" width={400} height={20} />
        <Skeleton variant="text" width={300} height={40} />
        <div className="card-footer">
          <Skeleton variant="text" width={50} height={20} />
        </div>
      </div>
    </div>
    ));
};

export default CardSkeleton;
