import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Link,
  Box,
  Paper,
  Container,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Button,
  Divider,
} from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../Components/Header";
import Sidenav from "../../Components/Sidenav";
import { useNavigate } from "react-router-dom";
import gcpImage from "../../../assets/images/gcp.png";
import awsImage from "../../../assets/images/aws-logo.png";
import azureImage from "../../../assets/images/azure_logo.png";
import { padding } from "@mui/system";
import Footer from "../../Components/Footer";
const serviceConnections = [
  {
    title: "AZURE service connection creation",
    description: "Learn how to create an Azure service connection.",
    link: "azure-service-connection-url",
    image: azureImage,
  },
  {
    title: "AWS service connection creation",
    description: "Discover how to create an AWS service connection.",
    link: "aws-service-connection-url",
    image: awsImage,
  },
  {
    title: "GCP service connection creation",
    description: "Find out how to create a GCP service connection.",
    link: "gcp-service-connection-url",
    image: "https://www.gend.co/hs-fs/hubfs/gcp-logo-cloud.png?width=730&name=gcp-logo-cloud.png"
  },
];

const WikiHome = () => {
  const navigate = useNavigate();
  const handleClick = (title) => {
    navigate("/coe-portal/architecture-template/ConnectionGuide", { state: { title } });
  };
  const faqData = [
    {
      question: "Which hyperscalers are supported?",
      answer: "AWS, AZURE, GCP are supported.",
    },
    {
      question: "Which IAC tools are supported?",
      answer: "Terraform is Supported.",
    },
    {
      question: "Are my Cloud provider credentials securely stored?",
      answer: " Yes, your cloud provider ensures the secure storage of your credentials through advanced encryption and robust access controls. ",
    },
    {
      question: "Can I remove the service connections that I created?",
      answer:
        "Yes,In Sidebar, Expand Connection > My connections >choose cloud provider> click on delete icon ",
    },
    {
      question: "How Resources deployed from accelerator can be destroyed?",
      answer:
        "Yes,In Sidebar, Expand Connection > My connections >choose cloud provider> click on delete icon",
    },
    {
      question: "How is TFstate file managed?",
      answer: "TFstate file is managed in azure blob storage",
    },
  ];

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 1 ,minWidth:80}}>
          <Header />
          <Container maxWidth="lg" sx={{ marginTop: 2 }}>
            <Paper
              elevation={3}

              sx={{
                padding: 5,
                overflow: "hidden",
                // backgroundColor:"rgba(240, 249, 253, 0.543)",
                backgroundSize: "60%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                "@media (max-width: 600px)": {
                  backgroundImage: "none",
                },
              }}
            >
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontFamily: "Equip",
                }}
              >
                {serviceConnections.map((connection, index) => (
                  <Card
                    key={index}
                    sx={{
                      display: "flex",
                      marginBottom: 2,
                      backgroundColor: "#ffffff",
                      fontFamily: "Equip",
                    }}
                  >
                    <CardContent sx={{ flex: 1 }}>
                      <Typography
                        variant="h5"
                        sx={{
                          margin: 2,
                          color: "#13294B",
                          fontFamily: "Equip",
                          fontWeight: "400",
                          fontStyle: "normal",
                        }}
                      >
                        {connection.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          margin: 2,
                          color: "#13294B",
                          fontFamily: "Equip",
                          fontWeight: "400",
                          fontStyle: "normal",
                        }}
                      >
                        {connection.description}
                      </Typography>
                      <CardActions>
                        <Button
                          size="small"
                          onClick={() => handleClick(connection.title)}
                          style={{ color: "#47D7AC" }}
                        >
                          Learn more..
                        </Button>
                      </CardActions>
                    </CardContent>
                    <CardMedia
                      component="img"
                      sx={{ width: 220, display: { xs: "none", sm: "block" } }}
                      image={connection.image}
                    />
                  </Card>
                ))}
              </div>
              <Divider />
              <Typography
                variant="h5"
                sx={{
                  margin: 2,
                  color: "#ffffff",
                  fontFamily: "Equip",
                  fontWeight: "bold",
                  fontStyle: "normal",
                  backgroundColor: "#40C2A0",
                  padding: 2,
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  display: "inline-block",
                  animation: "pulse 3s infinite",
                  cursor: "pointer",
                }}
              >
                FAQ
              </Typography>
              {faqData.map((faq, index) => (
                <Accordion
                  key={index}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    marginBottom: "10px",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      backgroundColor: "#ffffff",
                      borderRadius: "4px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      "& .MuiAccordionSummary-content": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#13294B",
                        fontFamily: "Equip",
                        fontWeight: "400",
                        fontStyle: "normal",
                      }}
                    >
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: "#3FBE9E",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#ffffff",
                        fontFamily: "Equip",
                        fontWeight: "400",
                        fontStyle: "normal",
                      }}
                    >
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
              <Divider />
              <Typography
                variant="body1"
                sx={{
                  marginTop: 4,
                  color: "#13294B",
                  fontFamily: "EquipExtended",
                  fontWeight: "400",
                  fontStyle: "normal",
                }}
              >
                For any more queries please drop a mail on:{" "}
                <Link href="mailto:your@email.com">coe@nagarro.com</Link>
              </Typography>
            </Paper>
          </Container>
        </Box>

      </Box>
      <Footer/>
    </div>
  );
};

export default WikiHome;
