import React from "react";
import {
  Button,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  Stack,
} from "@mui/material";
import { DialogActions, DialogContent } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import ReusableDrawer from "../ResuableDrawer";

function GcpContent({
  open,
  onClose,
  submit,
  gcpServiceConnection,
  pipelineConnections,
  handleGcpConnectionChange,
  deleteServiceConn,
}) {
  return (
    <ReusableDrawer
      open={open}
      onClose={onClose}
    >
      <Stack spacing={5} p={3}>
      <Typography
            variant="h6"
            fontFamily="EquipExtended"
            sx={{
              backgroundColor: '#40C2A0', color: 'white' , 
              padding: "10px 16px", 
              borderRadius: "4px 4px 0 0", 
            }}
          >Additional Information</Typography>
        <Typography fontFamily="Equip" variant="subtitle1" gutterBottom>
          Choose your Service Connection Name that you have created...
        </Typography>
        <InputLabel id="demo-simple-select-label">
        <Typography fontFamily="Equip" variant="subtitle1" gutterBottom>
          Service Connection Name
          </Typography>
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gcpServiceConnection}
          onChange={handleGcpConnectionChange}
          fullWidth
          style={{ marginBottom: "16px" }}
        >
          {pipelineConnections.length > 0 ? (
            pipelineConnections.map((option, index) => (
              <MenuItem key={index} value={option.serviceConnectionName}>
                {option.serviceConnectionName}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No available connections</MenuItem>
          )}
        </Select>
        <DialogActions>
        
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
          <Button
            disabled={!gcpServiceConnection}
            onClick={submit}
            sx={{color:'#40C2A0'}}
          >
            Submit
          </Button>
        </DialogActions>
      </Stack>
    </ReusableDrawer>
  );
}

export default GcpContent;
