import { Divider, Grid, Paper, Typography } from "@mui/material";
import Tile from "../../components/Tile";
import websitesData from "../../data/WebisiteData";
import ShowAllLink from "../../components/ShowAllLinks";
const OfferingDecks=()=>{
    const maxDisplayItems = 4;
    const itemsToDisplay =
      websitesData.length <= maxDisplayItems
        ? websitesData
        : websitesData.slice(0, maxDisplayItems);

    return (
        <Paper
          elevation={0}
          sx={{
            padding: 2,
            position: "relative",
            background: "linear-gradient(to bottom,#F8F8FF, #E6E6FA)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h5"
                sx={{
                  color: "#13294B",
                  fontFamily: "EquipExtended",
                  fontWeight: "400",
                  fontStyle: "normal",
                }}
              >
               Offering Decks
              </Typography>
            </div>
    
            <ShowAllLink route="/show-all-data" data={websitesData} />
          </div>
          <Divider sx={{ marginTop: 2 }} />
          <Grid container spacing={2}>
            {itemsToDisplay.map((website, index) => (
              <Grid item xs={12} md={6} key={index}>
               <Tile
                  title={website.title}
                  category={website.category}
                  url={website.url}
                  image={website.image}
                  details={website.details}
                  id = {website.id}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      );
    }
export default OfferingDecks;