import { Box, Card } from "@mui/material";
import Sidenav from "../../Components/Sidenav";
import { Container, Paper } from "@mui/material";
import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import AwsDeployments from "./Tables/AwsDeployement";
import Header from "../../Components/Header";
import AzureDeployments from "./Tables/AzureDeployement";
import GcpDeployments from "./Tables/GcpDeployement";
import Footer from "../../Components/Footer";
export default function Deployments() {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
    <Box sx={{ display: "flex" }}>
      <Sidenav />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Header />
        <Container maxWidth="lg"  sx={{minHeight:"80vh"}}>
          <Paper elevation={3} sx={{marginTop:2}}>
            
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                
              >
                <Tab label="AWS" />
                <Tab label="Azure" />
                <Tab label="GCP" />
              </Tabs>
              <Box p={3}>
            {tabValue === 0 && <AwsDeployments />}
            {tabValue === 1 && <AzureDeployments />}
            {tabValue === 2 && <GcpDeployments />}
            </Box>
          </Paper>
        </Container>
        <Footer />
      </Box>
      
    </Box>
   
    </>
  );
}
