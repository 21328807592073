import axios from "axios";
let accessToken;
const storedData = sessionStorage.getItem("accessToken");

if (accessToken === undefined) {
  accessToken = storedData;
}
export async function waitForDataAndCallFunction() {
  return new Promise((resolve, reject) => {
    const maxAttempts = 10; // Define a maximum number of attempts

    let attempts = 0;

    const checkData = () => {
      attempts++;

      if (attempts > maxAttempts) {
        reject(new Error("Maximum attempts reached"));

        return;
      }

      const dataIsSet = sessionStorage.getItem("dataIsSet");

      if (dataIsSet === "true") {
        const storedData = sessionStorage.getItem("accessToken");

        accessToken = storedData;

        resolve(storedData); // Resolve the promise with the data
      } else {
        setTimeout(checkData, 100); // Check again after a short delay
      }
    };

    checkData();
  });
}

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
};

const globalUrl = process.env.REACT_APP_APIM_RESOURCE_URL;

export async function fetchAllData() {
  try {
    const url = globalUrl + "GetAllData";
    const response = await axios.get(url, config);
    const data = response.data;

    return data;
  } catch (error) {
    // Handle any errors
    console.error(error);
    return null;
  }
}

export async function fetchDataById(id) {
  try {
    const url = globalUrl + "GetDataById/" + id;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(url, config);
    // Handle the response data

    const data = response.data;

    return data;
  } catch (error) {
    // Handle any errors
    console.error(error);
    return null;
  }
}

export async function deployPipeline(body) {
  try {
    const url = globalUrl + "deployPipeline";
    const response = await axios.post(url, body, config);
    // Handle the response data

    const data = response.data;
    return data;
  } catch (error) {
    // Handle any errors
    console.error(error);
    return null;
  }
}

export async function serviceConnectionCreation(body) {
  try {
    const url = globalUrl + "ServiceConnectionCreation";
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        subscription_id: body.subscription_id,
        subscription_name: body.subscription_name,
        service_connection_name: body.service_connection_name,
        tenant_id: body.tenant_id,
        service_principal_id: body.service_principal_id,
        cloud_provider_name: body.cloud_provider_name,
        service_principal_key: body.service_principal_key,
        aws_region: body.aws_region,
        service_connection_email: body.service_connection_email,
        project_name: body.project_name,
        username: body.username,
        name: body.name,
      },
    };
    const response = await axios.post(url, {}, config);
    // Handle the response data

    const data = response.data;
    return data;
  } catch (error) {
    // Handle any errors
    console.error(error);
    return null;
  }
}

export async function saveFeedbackData(body) {
  const apiEndpoint = globalUrl + "Feedback";

  try {
    const response = await axios.post(apiEndpoint, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching build logs:", error);
    return null;
  }
}

export async function getServiceConnectionFromDB(username) {
  const apiEndpoint = globalUrl + "UserProfile";
  try {
    const response = await axios.get(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        user_name: username,
      },
    });
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error fetching build logs:", error);
    return null;
  }
}

export async function ServiceConnectionDeletion(body) {
  const apiEndpoint = globalUrl + "ServiceConnectionDeletion";

  try {
    const response = await axios.delete(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        action: "serviceConnectionDeletion",
        "Content-Type": "application/json",
        service_connection_name: body.service_connection_name,
        cloud_provider: body.cloud_provider,
        user_name: body.user_name,
      },
    });
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error while deleting service connection:", error);
    return null;
  }
}

export async function getSasToken() {
  const apiEndpoint = globalUrl + "SasCreation";

  try {
    const response = await axios.get(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching build logs:", error);
    return null;
  }
}

export async function getLogoutUrl() {
  const apiEndpoint = process.env.REACT_APP_APIM_LOGOUT_URL;

  try {
    const response = await axios.get(apiEndpoint);
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching build logs:", error);
    return null;
  }
}

export async function pipelineStatus(body) {
  try {
    const buildId = body.buildId;
    const apiEndpoint = globalUrl + "pipelineStatus";
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        build_id: buildId,
      },
    };
    const response = await axios.get(apiEndpoint, config);

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getLogsUrl(buildId) {
  const apiEndpoint = globalUrl + "logs/logsurl";
  try {
    const response = await axios.get(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        build_id: buildId,
      },
    });
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error fetching build logs:", error);

    return null;
  }
}

export async function getLogsUrlData(logsUrl) {
  const apiEndpoint = globalUrl + "logs/data";

  try {
    const response = await axios.get(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        logs_url: logsUrl,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    return null;
  }
}

export async function getSearchData(query) {
  const sanitizedQuery = query.replace(/ /g, "+");
  const apiEndpoint =
    globalUrl + `GetSearchData?q=${encodeURIComponent(sanitizedQuery)}`;

  try {
    const response = await axios.get(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error while getting searched data:", error);
  }
}
export async function addTemplateToDB(body) {
  const apiEndpoint = globalUrl + "UserProfile";

  try {
    const response = await axios.post(
      apiEndpoint,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          user_name: body.user_name,
          name: "",
          service_connection_name: body.service_connection_name,
          cloud_provider: body.cloudProviderName,
          subscription_id: "",
          subscription_name: "",
          tenant_id: "",
          service_principal_id: "",
          service_connection_email: "",
          project_name: "",
          pipeline_id: body.pipelineId,
          build_id: body.buildId,
          template_name: body.templateName,
          deployed: "True",
        },
      }
    );
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error fetching build logs:", error);
    return null;
  }
}

export async function saveTemplateFeedbackData(body) {
  const apiEndpoint = globalUrl + "templateFeedback";

  try {
    const response = await axios.post(apiEndpoint, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error while fecthing template feedback data:", error);
    return null;
  }
}
export async function deploymentDeletionFromDB(body) {
  const apiEndpoint = globalUrl + "UserProfile";

  try {
    const response = await axios.delete(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        action: "deploymentDeletion",
        build_id: body.buildId,
        template_name: body.templateName,
        cloud_provider: body.cloud_provider,
        user_name: body.user_name,
      },
    });
    const data = response.data;

    return data;
  } catch (error) {
    console.error("Error while deleting service connection:", error);
    return null;
  }
}

export async function saveTemplateRatingToDb(body) {
  try {
    const url = globalUrl + "TemplateRating";
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        type_template: body.type,
        rating: body.rating,
        username: body.username,
      },
    };

    const response = await axios.post(url, {}, config);
    const data = response.data;
    return data;
  } catch (error) {
    // Handle any errors
    console.error(error);
    return null;
  }
}

export async function getTemplateRatingFromDb(type) {
  try {
    const url = globalUrl + "TemplateRating";
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        type_template: type,
      },
    };
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  } catch (error) {
    // Handle any errors
    console.error(error);
    return null;
  }
}
export async function getSearchRecentData(query) {
  const apiEndpoint =
    globalUrl + `GetSearchData?filter=${encodeURIComponent(query)}`;
  try {
    const response = await axios.get(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error while getting searched data:", error);
  }
}

export async function getAutoCompleteSuggestions(query) {
  const apiEndpoint =
    globalUrl + `AutoCompletionService?search=${encodeURIComponent(query)}`;
  try {
    const response = await axios.get(apiEndpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return response;
  } catch (error) {
     
  }
}

export async function addTemplate(zippedFile, fileName) {
  // const apiEndPoint = globalUrl + `AddTemplate`;
  const apiEndPoint = `https://function-app-coe-accelerator-001.azurewebsites.net/api/AddTemplate`;
  try {
    const response = await fetch(apiEndPoint, {
      method: "POST",
      body: zippedFile,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/zip",
        file_name: fileName,
      },
    });
    return response;
  } catch (error) {
     
  }
}

export async function getRoles(name, username,provider) {
  const url =
    "https://function-app-coe-accelerator-001.azurewebsites.net/api/Authorization?code=vQHodFVLGMoMlZGihllCKAkDlMd4miXoTNafbXdnLPHoAzFuXF5VjQ==";
  const headers = {
    "Content-Type": "application/json",
    username: name,
    useremail: username,
    provider:provider
  };

  try {
    const response = await axios.post(url, {}, { headers });

     console.log(response);
    return response.data;
    
    // Process the data received from the backend
  } catch (error) {
    console.error("Error calling backend function:", error);
  }
}

export async function fetchGcpRecords (){
  const apiUrl = "https://function-app-coe-accelerator-001.azurewebsites.net/api/readDataFromBQ?code=vQHodFVLGMoMlZGihllCKAkDlMd4miXoTNafbXdnLPHoAzFuXF5VjQ==";
  try{
    const response=await fetch (apiUrl);
    if (!response.ok) {
      throw new Error("Failed to fetch records from API");
    }
    const data = await response.json();
    return data;
  }
  catch (error) {
    console.error("Error fetching records from API:", error);
    throw error;
  }

};