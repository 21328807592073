
import React from 'react';
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/material';

function ReusableDrawer({ open, onClose, children }) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: 500 }} role="presentation">
        {children}
      </Box>
    </Drawer>
  );
}

export default ReusableDrawer;
