import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import Snack from "../../../Components/Snack";
import { useNavigate } from "react-router-dom";
import {
  getServiceConnectionFromDB,
  deployPipeline,
} from "../../../../backend/backend";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  TableContainer,
  Button,
} from "@mui/material";
import NoRecordFoundMessage from "../NoRecordFoundMessage";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
export default function GcpDeployments() {
  const navigate = useNavigate();
  const [defaultButton, setDefaultButton] = useState("AWS");
  const [azureDeployments, setAzureDeployments] = useState([]);
  const [awsDeployments, setAwsDeployments] = useState([]);
  const [gcpDeployments, setGcpDeployments] = useState([]);
  const [pipelineStatus, setPipelineStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [destroyLoading, setDestroyLoading] = useState(false);
  const [deletingRowIndex, setDeletingRowIndex] = useState(null);
  const username = sessionStorage.getItem("username");
  const [activeTab, setActiveTab] = useState(0);
  const handleClick = (e) => {
    setDefaultButton(e.target.value);
  };
  const styles = {
    tableContainer: {
      overflow: "hidden",
    },
    tableHead: {
      backgroundColor: "#F8F8FF",
    },
    tableCell: {
      fontFamily: "Equip",
      fontWeight: "bold",
      borderBottom: "1px solid #ddd",
    },
    deleteButton: {
      color: "#f44336",
    },
    logsButton: {
      color: "green",
    },
  };
  const handleLogsButton = (buildId, serviceConnectionName, templateName) => {
    navigate(
      `/coe-portal/architecture-template/PipelineStatus/status/${buildId}/5/${serviceConnectionName}/${templateName}`
    );
  };
  const handledestroyLogsButton = (
    destroy,
    serviceConnectionName,
    templateName
  ) => {
    navigate(
      `/coe-portal/architecture-template/PipelineStatus/status/${destroy}/5/${serviceConnectionName}/${templateName}`
    );
  };
  const handleDestroyButton = (
    pipelineId,
    serviceConnectionName,
    templateName,
    rowIndex
  ) => {
    setDeletingRowIndex(rowIndex);
    setDestroyLoading(true);
    let body;

    body = {
      pipelineId: pipelineId,
      azServiceConnectionName:
        process.env.REACT_APP_AZURE_SERVICE_CONNECTION_PRIMARY,
      azServiceConnectionNameClient: "",
      azRgName: process.env.REACT_APP_TFBACKEND_RG_NAME,
      gcpServiceConnectionName: serviceConnectionName,
      azStorageName: process.env.REACT_APP_TFBACKEND_STORAGE_ACCOUNT_NAME,
      azContainerName: "tf-state-container",
      templateName: templateName,
      awsServiceConnectionName: "",
      cloudProviderName: "GCP",
      userName: username,
      apply_var: "",
      destroy_var: "True",
    };

    if (body) {
      let id, service_connection_name, templateName;
      (async () => {
        try {
          const data1 = await deployPipeline(body);

          if (data1 !== null) {
            if (data1.status === 200) {
              id = data1.buildId;
              service_connection_name = data1.serviceConnectionName;
              templateName = data1.templateName;
              setPipelineStatus("resources destroyed successfully");
              setStatus(200);
              setLoading(false);
              navigate(
                `/coe-portal/architecture-template/PipelineStatus/status/${id}/5/${service_connection_name}/${templateName}`
              );
            }
          } else {
            setPipelineStatus(
              "An error occurred while destroying the resource please try again."
            );
            setStatus(500);

            setLoading(false);
          }
        } catch (error) {
          console.log("Error:", error);
        } finally {
          setDeletingRowIndex(null);
          setDestroyLoading(false);
        }
      })();
    } else {
    }
  };

  async function getServiceConnection(username) {
    try {
      const data1 = await getServiceConnectionFromDB(username);

      if (data1 !== null) {
        data1.map((element) => {
          if (element.cloud === "AZURE") {
            setAzureDeployments(element.deployments);
          } else if (element.cloud === "AWS") {
            setAwsDeployments(element.deployments);
          } else {
            if (element.deployments.length > 0) {
              const arr = [...gcpDeployments];
              element.deployments.map((data, index) => {
                arr.push(data);
              });
              setGcpDeployments(arr);
            } else {
              setGcpDeployments(element.deployments);
            }
          }
        });
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleRefresh = () => {
    getServiceConnection(username);
  };

  useEffect(() => {
    getServiceConnection(username);
  }, []);
  return (
    <>
      {pipelineStatus && status && (
        <Snack
          status={status}
          data={pipelineStatus}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      )}
      {gcpDeployments.length > 0 ? (
        <Box p={0}>
          <TableContainer style={styles.tableContainer}>
            <Table>
              <TableHead style={styles.tableHead}>
                <TableRow>
                  <TableCell style={styles.tableCell}>S.No</TableCell>
                  <TableCell style={styles.tableCell}>
                    Service Connection Name
                  </TableCell>
                  <TableCell style={styles.tableCell}>Template Name</TableCell>
                  <TableCell style={styles.tableCell}>Destroy</TableCell>
                  <TableCell style={styles.tableCell}>Logs</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {gcpDeployments.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.serviceConnectionName}</TableCell>
                    <TableCell>{item.templateName}</TableCell>
                    <TableCell>
                      {deletingRowIndex === index ? (
                        <CircularProgress size={24} />
                      ) : (
                        <>
                          {item.deployed === "True" ? (
                            <>
                              {item.destroy_build_id === "" ? (
                                <IconButton
                                  variant="contained"
                                  style={styles.deleteButton}
                                  onClick={() =>
                                    handleDestroyButton(
                                      item.pipelineId,
                                      item.serviceConnectionName,
                                      item.templateName,
                                      index,
                                      item.buildId
                                    )
                                  }
                                  disabled={deletingRowIndex === index}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : (
                                <Tooltip title="Destroy Logs">
                                  <IconButton
                                    style={styles.deleteButton}
                                    onClick={() =>
                                      handledestroyLogsButton(
                                        item.destroy_build_id,
                                        item.serviceConnectionName,
                                        item.templateName
                                      )
                                    }
                                    variant="contained"
                                    disabled={item.destroy_build_id === ""}
                                  >
                                    <FolderDeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          ) : (
                            <IconButton variant="contained" disabled>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        style={styles.logsButton}
                        variant="contained"
                        onClick={() =>
                          handleLogsButton(
                            item.buildId,
                            item.serviceConnectionName,
                            item.templateName
                          )
                        }
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <NoRecordFoundMessage />
      )}
    </>
  );
}
