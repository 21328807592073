import { Box } from "@mui/material";
import Sidenav from "../../Components/Sidenav";
import Header from "../../Components/Header";
import { Button, Container, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useRef, useState } from "react";
import { addTemplate } from "../../../backend/backend";
import PropTypes from "prop-types";
import "./addtotemplates.css";
import Snack from "../../Components/Snack";
const AddTemplates = () => {
  const [file, setFile] = useState(null);
  const [status,setStatus]=useState("");
  const[statusCode,setStatusCode]=useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      // Handle the case where no file is selected
      return;
    }
  
    // Create a new FormData object and append the selected file
    const formData = new FormData();
    formData.append("file", file);
     console.log("formData", formData);
    try {
      const response = await addTemplate(file,file.name);

      if (response.ok) {
        setStatus("Template added successfully!!");
        setStatusCode(200)
         console.log("File uploaded successfully.");
        setSnackbarOpen(true);
      } else {
        setStatus("Template not Saved some error occur!!");
        setStatusCode(500)
        console.error("Error uploading file:", response.statusText);
        setSnackbarOpen(true);
      }
    } catch (e) {
      console.log("Error uploading file:", e);
    }
    
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Header />
          <Container>
          {status && statusCode && (
            <Snack
              open={snackbarOpen}
              status={statusCode}
              data={status}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              handleClose={handleCloseSnackbar}
            />
          )}
            <Typography variant="h4" align="center" gutterBottom>
              Add Template
            </Typography>
            <Box
              border="2px dashed #ccc"
              borderRadius="8px"
              textAlign="center"
              padding="16px"
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleFileDrop}
              style={{ cursor: "pointer" }}
            >
              <CloudUploadIcon color="action" sx={{ fontSize: 200 }} />
              <Typography variant="subtitle1" gutterBottom>
                Drag and drop a zip file anywhere inside this box or click to
                browse
              </Typography>
              <input
                type="file"
                accept=".zip"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-input"
              />
              <label htmlFor="file-input">
                <Button variant="contained" component="span">
                  Browse
                </Button>
              </label>
            </Box>
            {file && (
              <Box marginTop="16px" textAlign="center">
                <Typography variant="subtitle1">
                  Selected File: {file.name}
                </Typography>
                <Button variant="contained" onClick={handleUpload}>
                  Upload
                </Button>
              </Box>
            )}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default AddTemplates;
