import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Tile = ({ title, category, url, image, details ,id}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if(category === "Offering"){
      navigate(`/coe-portal/architecture-template/home/${id}`)
    }
    else
      window.open(url, "_blank");
  };

  return (
    <div className="tile-container" onClick={handleClick}>
      <div className="tile-image">
        <img src={image} alt={title} />
      </div>

      <div className="tile-details">
        <div className="tile-content">
          <h3 style={{ fontWeight: 300, marginBottom: 0 }}>{title}</h3>

          <p>{details}</p>
        </div>

        <Button
          // href={url}
          // target="_blank"
          // rel="noopener noreferrer"
          sx={{ m: 0, p: 0, color: "#5CD2B5" }}
        >
          {category}
        </Button>
      </div>
    </div>
  );
};

export default Tile;
