import React, { useEffect, useState } from "react";
import cloudImage from "../../../assets/images/cloud.jpg";
import { Rating, Button, Skeleton, Tooltip } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";
import { getTemplateRatingFromDb, getSasToken } from "../../../backend/backend";
const ResponsiveCard = ({
  key,
  title,
  description,
  type,
  isPremium,
  isCoeVerified,
  templateStructureUrl,
}) => {
  const navigate = useNavigate();
  const [templateRating, setTemplateRating] = useState(0);
  const [sasToken, setSasToken] = useState("");
  const [blobUrl, setBlobUrl] = useState("");
  const [loadingImage, setLoadingImage] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [cachedTemplateRating, setCachedTemplateRating] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);

  const fetchSasToken = async () => {
    try {
      const response = await getSasToken();
      if (response === null) {
        throw new Error("Failed to fetch SAS token.");
      }
      const sasToken = response;
      setSasToken(sasToken);
      setLoadingImage(false);
    } catch (error) {
      console.error("Error fetching SAS token:", error);
      setLoadingImage(false);
    }
  };
  const loadImageUrl = () => {
    const imageUrlFromDb = templateStructureUrl;
    setBlobUrl(imageUrlFromDb);
    setImageLoading(false);
  };

  useEffect(() => {
    fetchSasToken();
    loadImageUrl();
  }, []);

  const signedBlobUrl = `${blobUrl}?${sasToken}`;

  useEffect(() => {
    (async () => {
      try {
        const data1 = await getTemplateRatingFromDb(type);
        if (data1 !== null) {
          setTemplateRating(data1);
        } else {
        }
      } catch (error) {
        console.error("Error fetching or caching rating:", error);
      }
    })();
  }, [type]);

  const handleClick = () => {
    navigate(`/coe-portal/architecture-template/dashboard/${type}`);
  };
  return (
    <div className="responsive-card" onClick={handleClick}>
      <div className="image-section">
        {!signedBlobUrl || !blobUrl || !sasToken ? (
          <Skeleton variant="rectangular" width={100} height={100} />
        ) : (
          <img alt="complex" src={signedBlobUrl} />
        )}

        <div className={`tag ${isPremium === "PREMIUM" ? "premium" : "free"}`}>
          {isPremium === "PREMIUM" ? "Premium" : "Free"}
        </div>
      </div>
      <div className="content-section">
        <h3>
          {title}
          {isCoeVerified === "COE_VERIFIED" && (
            <Tooltip title="Verified">
              <VerifiedIcon className="verification-icon" />
            </Tooltip>
          )}
        </h3>
        <p className="description">{description}</p>
        <div className="card-footer">
          <Rating
            name={`rating-${key}`}
            value={templateRating !== 0 ? templateRating : 0}
            precision={0.5}
            readOnly
          />
          <Button
            size="small"
            onClick={handleClick}
            style={{ color: "#13294B" }}
          >
            Explore
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveCard;
