import React from "react";
import { Button, Typography, Box, Stack, DialogActions } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ReusableDrawer from "../ResuableDrawer";

export const AwsContent = ({
  open,
  onClose,
  submit,
  awsServiceConnection,
  pipelineConnections,
  handleAwsConnectionChange,
}) => {
  return (
    <ReusableDrawer open={open} onClose={onClose}>
      <>
        <Stack spacing={5} p={3}>
          <Typography
            variant="h6"
            fontFamily="EquipExtended"
            sx={{
              backgroundColor: '#40C2A0', color: 'white' , 
              padding: "10px 16px", 
              borderRadius: "4px 4px 0 0",
            }}
          >
            Additional Information
          </Typography>
          <Typography fontFamily="Equip" variant="subtitle1" gutterBottom>
            Choose the Service Connection Name you've created:
          </Typography>
          <InputLabel id="demo-simple-select-label">
          <Typography fontFamily="Equip" variant="subtitle1" gutterBottom>
          Service Connection Name
          </Typography>
           
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={awsServiceConnection}
            onChange={handleAwsConnectionChange}
            fullWidth
            style={{ marginBottom: "16px" }}
          >
            {pipelineConnections.length > 0 ? (
              pipelineConnections.map((option, index) => (
                <MenuItem key={index} value={option.serviceConnectionName}>
                  {option.serviceConnectionName}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No available connections</MenuItem>
            )}
          </Select>
          <DialogActions>
         
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
          <Button
            disabled={!awsServiceConnection}
            onClick={submit}
         
            sx={{color:'#40C2A0'}}
          >
            Submit
          </Button>
        </DialogActions>
          
        </Stack>
      </>
    </ReusableDrawer>
  );
};
