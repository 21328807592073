// MainTable.js
import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

const MainTable = ({ data, expandedRow, handleRowClick }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="pivot table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Region</TableCell>
            <TableCell>Root Billing Account</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Invoice Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Net Cost</TableCell>
            <TableCell>Customer Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <React.Fragment key={index}>
              <TableRow onClick={() => handleRowClick(row.key)}>
                <TableCell>
                  <IconButton
                    aria-label={expandedRow === row.key ? 'Collapse' : 'Expand'}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(row.key);
                    }}
                  >
                    {expandedRow === row.key ? <ExpandMoreIcon /> : <AddIcon />}
                  </IconButton>
                </TableCell>
                <TableCell>{row.CustomerRegion}</TableCell>
                <TableCell>{row.RootBillingAccountId}</TableCell>
                <TableCell>{row.Currency}</TableCell>
                <TableCell>{row.InvoiceDate}</TableCell>
                <TableCell>{row.DueDate}</TableCell>
                <TableCell>{row.TotalNetCost.toFixed(2)}</TableCell>
                <TableCell>{row.TotalCustomerCost.toFixed(2)}</TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MainTable;
