import React, { useState } from "react";
import { Paper, Tabs, Tab, Box } from "@mui/material";
import AwsTable from "./Tables/AwsTable";
import AzureTable from "./Tables/AzureTable";
import GcpTable from "./Tables/GcpTable";
import Header from "../../Components/Header";
import Sidenav from "../../Components/Sidenav";
import { Container } from "@mui/system";
import { useLocation } from "react-router-dom";
import Footer from "../../Components/Footer";
const ExistingServiceConnectionsTable = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = parseInt(searchParams.get("tab")) || 0; 

  const [tabValue, setTabValue] = useState(initialTab);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  return (
    <Box sx={{ display: "flex" }}>
    <Sidenav />
    <Box component="main" sx={{ flexGrow: 1, p: 1, minWidth: 80  }}>
      <Header />
      <Container maxWidth="lg" sx={{minHeight:"80vh"}} >
    <Paper elevation={3} sx={{marginTop:2}}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="AWS" />
        <Tab label="Azure" />
        <Tab label="GCP" />
      </Tabs>
      <Box p={3}>
        {tabValue === 0 && <AwsTable />}
        {tabValue === 1 && <AzureTable />}
        {tabValue === 2 && <GcpTable />}
      </Box>
    </Paper>
    </Container>
    <Footer />
    </Box>

    </Box>
  );
};

export default ExistingServiceConnectionsTable;
