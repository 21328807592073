import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Sidenav from "../../Components/Sidenav";
import Grid from "@mui/material/Grid";
import CardSkeleton from "./CardSkeleton";
import Header from "../../Components/Header";

import { getSearchData } from "../../../backend/backend";
import { useParams } from "react-router-dom";
import ResponsiveCard from "./ResponisveCard";

const SearchedData = () => {
  const [searchedData, setSearchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const query = params.suggestion;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSearchData(query);
        
        setSearchedData(data);
        setLoading(false);
      } catch (error) {
        console.log("Error while getting search data:", error);
      }
    };

    fetchData();
  }, [query]);
  
  return (
    <>
      <Box />

      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Header />

          {loading ? (
            <Grid
              container
              spacing={2}
              style={{ justifyContent: "space-around", marginTop: 10 }}
            >
              <CardSkeleton cards={8} />
              <CardSkeleton cards={8} />
              <CardSkeleton cards={8} />
            </Grid>
          ) : (
            <Box p={2}>
            <Grid
              container
              spacing={2}
          
            >
              {searchedData.map((item, index) => (
                <ResponsiveCard
                  title={item.templateDisplayName}
                  description={item.details.description}
                  type={item.templateName}
                  isPremium={item.tags[0]}
                  isCoeVerified={item.tags[1]}
                  templateStructureUrl={item.templateStructureUrl}
                />
              ))}
              
            </Grid>
            </Box>
          )}
          
          
        </Box>
      </Box>
    </>
  );
};

export default SearchedData;
