import React from "react";
import "../../css/loading.css";

const Loading = ({ width, height }) => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default Loading;
