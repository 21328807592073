import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h1" color="primary" gutterBottom>
          Oops!
        </Typography>
        <Typography variant="h4" color="textSecondary" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center" mb={4}>
          The page you're looking for might have been removed or does not exist.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
        >
          Go to Home
        </Button>
      </motion.div>
    </Box>
  );
};

export default NotFoundPage;
