import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  IconButton,
  InputLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import {
  serviceConnectionCreation,
  getServiceConnectionFromDB,
} from "../../../../backend/backend";
import { Box, Button } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Cancel, CheckCircle } from "@mui/icons-material";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import KeyIcon from "@mui/icons-material/Key";
import Snack from "../../../Components/Snack";
function AwsForm() {
  const [defaultButton, setDefaultButton] = useState("AWS");
  const [defaultButton2, setDefaultButton2] = useState(
    "Create a new service connections"
  );
  let activeIndex = 2;
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const [subscriptionId, setSubscriptionId] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [serviceConnName, setServiceConnName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [servicePrincipalId, setServicePrincipalId] = useState("");
  const [servicePrincipalKey, setServicePrincipalKey] = useState("");
  const [connectionEmail, setConnectionEmail] = useState("");
  const [projectName, setProjectName] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");
  const [existingServConn, setExistingServConn] = useState("");
  const username = sessionStorage.getItem("username");
  const name = sessionStorage.getItem("name");

  const [awsRegion, setAwsRegion] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [awsConnection, setAwsConnection] = useState([]);
  const [gcpConnection, setGcpConnection] = useState([]);
  const [deletionStatus, setDeletionStatus] = useState("");

  const handleAwsRegion = (e) => {
    setAwsRegion(e.target.value);
  };

  const detailClear = () => {
    setSubscriptionId("");
    setSubscriptionName("");
    setServiceConnName("");
    setTenantId("");
    setServicePrincipalId("");
    setServicePrincipalKey("");
    setConnectionEmail("");
    setProjectName("");
  };

  const region_list = [
    { key: 1, value: "us-east-2" },
    { key: 2, value: "us-east-2" },
    { key: 3, value: "us-west-1" },
    { key: 4, value: "us-west-2" },
    { key: 5, value: "us-west-2" },
    { key: 6, value: "ap-east-1" },
    { key: 7, value: "ap-south-2" },
    { key: 8, value: "ap-south-2" },
    { key: 9, value: "ap-southeast-4" },
    { key: 10, value: "ap-south-1" },
    { key: 11, value: "ap-northeast-3" },
    { key: 12, value: "ap-northeast-2" },
    { key: 13, value: "ap-southeast-1" },
    { key: 14, value: "ap-southeast-2" },
    { key: 15, value: "ap-northeast-1" },
    { key: 16, value: "ca-central-1" },
    { key: 17, value: "ca-central-1" },
    { key: 18, value: "ca-central-1" },
    { key: 19, value: "eu-west-2" },
    { key: 20, value: "eu-south-1" },
    { key: 21, value: "eu-south-1" },
    { key: 22, value: "eu-south-1" },
    { key: 23, value: "eu-north-1" },
    { key: 24, value: "eu-central-2" },
    { key: 25, value: "il-central-1" },
    { key: 26, value: "me-south-1" },
    { key: 27, value: "me-central-1" },
    { key: 28, value: "sa-east-1" },
    { key: 29, value: "us-gov-east-1" },
    { key: 30, value: "us-gov-west-1" },
  ];
  const tabIndex = 0;

  const isSubmitDisabled =
    serviceConnName.length < 4 ||
    servicePrincipalId.length < 4 ||
    servicePrincipalKey.length < 4 ||
    awsRegion.length < 4;

  const handleSerConnName = (e) => {
    setServiceConnName(e.target.value);
  };

  const handleSerPrinId = (e) => {
    setServicePrincipalId(e.target.value);
  };

  const handleSerPrinKey = (e) => {
    setServicePrincipalKey(e.target.value);
  };

  const handleRefresh = () => {
    getServiceConnection(username);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    try {
      let body;
      body = {
        subscription_id: subscriptionId,
        subscription_name: subscriptionName,
        service_connection_name: serviceConnName,
        tenant_id: tenantId,
        service_principal_id: servicePrincipalId,
        cloud_provider_name: defaultButton,
        service_principal_key: servicePrincipalKey,
        aws_region: awsRegion,
        service_connection_email: connectionEmail,
        project_name: projectName,
        username: username,
        name: name,
      };

      if (body) {
        const data1 = await serviceConnectionCreation(body);

        if (data1 !== null) {
          if (data1.status === 200) {
            setServiceStatus("Service connection created successfully!");
            setStatus(200);
            detailClear();
            navigate(`/coe-portal/architecture-template/MyConnections?tab=${tabIndex}`);
          } else {
            setServiceStatus("Service connection creation failed.");
            setStatus(500);
          }
        }
      } else {
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  async function getServiceConnection(username) {
    try {
      const data1 = await getServiceConnectionFromDB(username);
      // Access the returned data

      if (data1 !== null) {
        // setPipelineConnections(data1);
        data1.map((element) => {
          setAwsConnection(element.serviceConnection);
        });
      } else {
        // setPipelineConnections(null);
      }
    } catch (error) {
      // Handle any errors thrown during the fetch
      console.log(error);
    }
  }

  useEffect(() => {
    getServiceConnection(username);
  }, []);

  return (
    <>
      {serviceStatus && status && (
        <Snack
          status={status}
          data={serviceStatus}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      )}
      <React.Fragment>
        <Box p={0}>
          <TextField
            label="Service Connection Name"
            name="serviceConnectionName"
            value={serviceConnName}
            onChange={handleSerConnName}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Tooltip title="Name of Service Connection to be created">
                  <IconButton size="small">
                    <CloudSyncIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />

          <TextField
            label="Access Key"
            name="accessKey"
            value={servicePrincipalId}
            onChange={handleSerPrinId}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Tooltip title="Access key of your AWS account">
                  <IconButton size="small">
                    <KeyIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />

          <TextField
            label="Secret Access Key"
            name="secretAccessKey"
            value={servicePrincipalKey}
            onChange={handleSerPrinKey}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Tooltip title="Secret Access key of your AWS account">
                  <IconButton size="small">
                    <PasswordIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Region</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={awsRegion}
              label="Agaskdnalsfnae"
              onChange={handleAwsRegion}
              variant="outlined"
              fullWidth
            >
              {region_list.map((option) => (
                <MenuItem key={option.key} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Cancel />}
              onClick={detailClear}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitDisabled || submitLoading}
              onClick={handleSubmit}
            >
              {submitLoading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </React.Fragment>
    </>
  );
}

export default AwsForm;
