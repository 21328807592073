import React, { useEffect, useState } from "react";
import { invokeLambdaFunction } from "../data/backend";
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TablePagination,
  Select,
  MenuItem,
  Box,
  TextField,
  styled,
  Tabs,
  Tab,
  Fab,
  Button,
} from "@mui/material";

import Header from "../../components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../../../ArchitectureTemplate/Components/Footer";
import Sidenav from "../../components/Sidenav";
import { useNavigate, useNavigation } from "react-router-dom";
import { Home } from "@mui/icons-material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getSymbolFromCurrency from "currency-symbol-map";
import Snack from "../../../ArchitectureTemplate/Components/Snack"
import CustomSkeleton from "../../components/CustomSkeleton";
import {getRoles} from "../../../backend/backend"
import {maxAllowedDate} from "../../dateConstants.js"
import {minAllowedDate} from "../../dateConstants.js"


const styles = {
  tableContainer: {
    maxWidth: "100%",
  },
  tableHead: {
    backgroundColor: "#F8F8FF",
  },
  tableCell: {
    fontFamily: "Equip",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  deleteButton: {
    color: "#f44336",
  },
};
const RegionView = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [aggregatedData, setAggregatedData] = useState([]);
    const date = new Date().toISOString().split("T")[0];
    const [selectedTab, setSelectedTab] = useState("back");
    const [endDate, setEndDate] = useState(""); // State to store the selected date from the menu
    const [startDate, setStartDate] = useState("");
    const [currencyFilter, setCurrencyFilter] = useState("");
    const [status,setStatus]=useState("");
    const[statusCode,setStatusCode]=useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate=useNavigate();
    // useEffect(() => {
    //   // Fetch data when both selectedDate and startDate are available
    //   if (startDate && endDate) {
    //     sessionStorage.setItem("endDate", endDate);
    //     sessionStorage.setItem("startDate", startDate);
      
    //     fetchData();
    //   }
    // }, [endDate, startDate]);
    useEffect(() => {
      const fetchDataAndRoles = async () => {
        if (startDate && endDate) {
          sessionStorage.setItem("endDate", endDate);
          sessionStorage.setItem("startDate", startDate);
          
          const userData = await getRoles(
            sessionStorage.getItem("name"),
            sessionStorage.getItem("username"),
            "AWS"
          ); // Call getRoles first and wait for it to complete
          const { billing_account_details, message, user_role, useremail, username } =
          userData;
          // console.log(userData);
    
          fetchData(userData.billing_account_details,userData.user_role);
        }
      };
    
      fetchDataAndRoles();
    }, [endDate, startDate]);
    
    useEffect(() => {
      // Set default dates when the component mounts
      const today = new Date();
      let storedStartDate = sessionStorage.getItem("startDate");
      let storedSelectedDate = sessionStorage.getItem("endDate");
      if (storedStartDate && storedSelectedDate) {
        setStartDate(storedStartDate);
        setEndDate(storedSelectedDate);
      } else {
        const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth()-1, 1);
        const formattedFirstDayOfPreviousMonth = formatDate(firstDayOfPreviousMonth);
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() , 1);
        const formattedFirstDayOfCurrentMonth = formatDate(firstDayOfCurrentMonth);

        setEndDate(formattedFirstDayOfCurrentMonth);
        setStartDate(formattedFirstDayOfPreviousMonth);
      }
    }, []);
    
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
      return `${year}-${month}-01`;
    };
    
  
    const fetchData = async (userdata,userrole) => {
      let accountIds = []; 
      if(userrole!="Member"){
        accountIds = userdata?.map((user) => user.account_id);
      }
      
      // console.log(accountIds);
      try {
        // console.log(userdata)
        const response = await invokeLambdaFunction({
          level: "root",
          start_date: startDate,
          end_date: endDate,
          filters:accountIds
          
        });
        // console.log(response)
        if (userrole === "Member") {
          setStatus("Access denied. You do not have permission to view this content.");
          setStatusCode(403);
          setSnackbarOpen(true);
          return;
        }
    
         
        if (response.statusCode === 200 && response.body !== "No record found") {
          const jsontype = JSON.parse(response.body);
          
          setData(jsontype);
          setLoading(false);
      } else if (response.statusCode === 200 && response.body === "No record found") {
          setStatus(response.body);
          setStatusCode(500)
          setSnackbarOpen(true);
          
          setData([]);
         
      }else if (response.statusCode=== 403){
        setStatus(response.body);
        setStatusCode(500)
        setSnackbarOpen(true);

      }
       else {
          setStatus("No Data is available");
          setStatusCode(500);
          setData(response);
          setSnackbarOpen(true);
      }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
   
  
    
    
    // const handleDateChange = (event) => {
    //   const selectedDate = event.target.value;
    //   setSelectedDate(selectedDate);
      
    //   // Calculate the start date as the first day of the previous month
    //   const previousMonth = new Date(selectedDate);
    //   previousMonth.setMonth(previousMonth.getMonth() - 1);
    //   previousMonth.setDate(1); // Set to the first day of the month
    //   const formattedStartDate = previousMonth.toISOString().split("T")[0];
    //   setStartDate(formattedStartDate);
    // };
    const handleDateChange = (newDate) => {
      // console.log(newDate)
      const inputdate=newDate.toISOString().split("T")[0]
      setStartDate(inputdate);
      
      // Calculate the start date as the first day of the previous month
      const currentMonth = new Date(newDate);
      currentMonth.setMonth(currentMonth.getMonth() + 1);
      currentMonth.setDate(1); // Set to the first day of the month
    
      const formattedStartDate = formatDate(currentMonth);
      // console.log(currentMonth)
      setEndDate(formattedStartDate);
      
     
    };
    const handleCurrencyFilterChange = (event) => {
      setCurrencyFilter(event.target.value);
    };
  
    // Filter data based on currency filter
    
    const filteredData = data?.filter((item) =>
      item.CostUnit.toLowerCase().includes(currencyFilter.toLowerCase())
    );
   
  const tableHeadStyle = {
    fontWeight: "bold",
    color: "#13294B",
    fontFamily: "EquipExtended",
    fontSize: 14,
  };
  const tableElementStyle = {
    fontFamily: "Equip",
    fontSize: 14,
    fontWeight: 400,
  };

  const handleRowClick = (customerRegion) => {
    const level="customers"
    // console.log(customerRegion)
    navigate(`/aws/billingdashboard/${level}/${customerRegion}/${startDate}/${endDate}`)
  };
 
  function formatDateToDisplay(dateString) {
    const date = new Date(dateString);
    const options = {  month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
}
const handleCloseSnackbar = () => {
  setSnackbarOpen(false);
};
const handleChangePage = (event, newPage) => {
  setPage(newPage); // Update page state for TablePagination component
};
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Header />
          <Box sx={{ minHeight: "80vh" }}>
          {status && statusCode && (
            <Snack
              open={snackbarOpen}
              status={statusCode}
              data={status}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              handleClose={handleCloseSnackbar}
            />
          )}
          <div style={{ display: "flex" }}>
            <div
              style={{ flex: 1, margin: "10px", justifyItems: "flex-start" }}
            >
              <Tabs
                value={selectedTab}
                // onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab
                  label="Home"
                  iconPosition="start"
                  icon={<Home />}
                  value="back"
                />
              </Tabs>
            </div>
            <div style={{ margin: "10px", justifyItems: "flex-end" }}>
              <TextField
                sx={{ minWidth: 200, marginTop: 3, marginRight: 2 }}
                label="Filter by Currency"
              
                value={currencyFilter}
                onChange={handleCurrencyFilterChange}
                variant="standard"
              
              />
               {/* <Select
                  sx={{ minWidth: 200, marginTop: 5 }}
                  onChange={handleDateChange}
                  variant="standard"
                  value={selectedDate} 
                >
                  {generatePastMonths().map((month, index) => (
                    <MenuItem key={index} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select> */}
                 
                    <DatePicker className="datepicker"
                     
                  label="Select Month and Year"
                  showMonthYearPicker
                  
                  selected={startDate}
                  value={formatDateToDisplay(startDate)}
              
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField variant="filled" {...params} 
                  style={{minWidth:100,marginRight:"20px"}}
                  />}
                  maxDate={maxAllowedDate}
                  minDate={minAllowedDate}
                />
               
            </div>
          </div>
            <TableContainer
              style={styles.tableContainer}
              sx={{ boxShadow: "0px 4px 8px rgba(186, 220, 211, 5)" }}
            >
              <Table aria-label="pivot table">
                <TableHead style={styles.tableHead}>
                  <TableRow>
                    <TableCell />
                    {/* <TableCell  style={tableHeadStyle} >Region</TableCell> */}
                    <TableCell style={tableHeadStyle}>
                      Customer Region 
                    </TableCell>
                    {/* <TableCell style={tableHeadStyle}>
                     Currency
                    </TableCell> */}
                  

                    {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}
                    <TableCell style={tableHeadStyle}>Billing Month</TableCell>
                    {/* <TableCell style={tableHeadStyle}>End Date</TableCell> */}

                    <TableCell
                      style={{ ...tableHeadStyle,  textAlign: loading ? " " : "right", }}
                    >
                      {" "}
                      Net Cost
                    </TableCell>
                    <TableCell
                      style={{ ...tableHeadStyle,textAlign: loading ? " " : "right" }}
                    >
                      Customer Cost
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                {loading ? (
          // Render SkeletonLoaderRow component when loading
          Array.from({ length: 2 }).map((_, index) => (
            <CustomSkeleton key={index} numColumns={5} />
          ))
        ) : (
                   filteredData && filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) =>(
                    <React.Fragment key={index}>
                      <TableRow
                        className="tableRowHover"
                        
                      >
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRowClick(row.CustomerRegion);
                            }}
                          >
                            {/* {expandedRow === row.key ? (
                            <ExpandMoreIcon />
                          ) : ( */}
                            <AddIcon />
                            {/* )} */}
                          </IconButton>
                        </TableCell>
                        {/* <TableCell style={tableElementStyle}>{row.CustomerRegion}</TableCell> */}
                        <TableCell style={tableElementStyle}>
                          {row.CustomerRegion}
                        </TableCell>
                        {/* <TableCell style={tableElementStyle}>
                          {row.CostUnit}
                        </TableCell> */}
                        {/* <TableCell style={tableElementStyle}>
                          {row.EndDate}
                        </TableCell> */}
                        {/* <TableCell style={tableElementStyle}>
                      {row.Currency}
                    </TableCell> */}
                     <TableCell
                          style={{ ...tableElementStyle, textAlign: "left" }}
                        >
                        {formatDateToDisplay(row.StartDate)}
                        </TableCell>
                        {/* {row.EndDate > date ? (
                        <TableCell style={tableElementStyle}>
                          Ongoing
                        </TableCell>
                      ) : ( 
                        <TableCell
                          style={{ ...tableElementStyle, textAlign: "left" }}
                        >
                         {formatDateToDisplay(row.EndDate)}
                        </TableCell>
                         )} */}

                        {/* <TableCell style={tableElementStyle}>{row.DueDate}</TableCell> */}
                        <TableCell
                          style={{ ...tableElementStyle, textAlign: "right" }}
                        >
                          {" "}
                          {getSymbolFromCurrency(row.CostUnit)}{" "}
                          {row.CustomerCost.toFixed(2)}
                        </TableCell>
                        <TableCell
                          style={{ ...tableElementStyle, textAlign: "right" }}
                        >
                          {" "}
                          {getSymbolFromCurrency(row.CostUnit)}{" "}
                          {row.NetCost.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )))}
                </TableBody>
              </Table>
            </TableContainer>
         
            {data && data.length > 5 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default RegionView;
