import React, { useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import { Button } from "@mui/material";
import CircleLoader from "react-spinners/CircleLoader";
import LockIcon from "@mui/icons-material/Lock";
import NavigationIcon from "@mui/icons-material/Navigation";
import "./login.css";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { makeStyles } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

function Basic() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const unauthorized = new URLSearchParams(location.search).get("unauthorized");
  const customBgColor = "#13294B";
  const customColor = "#ffffff";

  const buttonStyle = {
    backgroundColor: customBgColor,
    color: customColor,
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_APIM_LOGIN_AUTH_URL
        // "https://apim-coe-accelerator-portal.azure-api.net/login/auth"
      );

      const { loginUrl, codeVerifier } = response.data;
       
      sessionStorage.setItem("key", codeVerifier);
      // setIsLoading(true);
      window.location.href = loginUrl;
     
    } catch (error) {
      console.error("Error during login:", error);
      setIsLoading(false);
    } finally {
      // You might need to handle this based on the response or error status
      // setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div>
        <section className="full-viewport">
          <div className="box">
            <div className="centered">
              <Fab
                size="large"
                color="#ffffff"
                aria-label="add"
                style={buttonStyle}
              >
                <Box sx={{ fontSize: "1.8rem" }}>
                  <PersonIcon />
                </Box>
              </Fab>
            </div>
            <h5>Sign in</h5>
            <h5>Cloud Practice Portal-Powered by Cloud Practice</h5>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Fab
                sx={{ marginTop: 2 }}
                variant="extended"
                onClick={handleLogin}
                style={buttonStyle}
                disabled={isLoading} // Disable button during loading
              >
                {isLoading ? (<>
                  <CircularProgress style={{marginRight:"5px"}} color="inherit" size={24} /> 
                  Login with Azure AD
                </>
                  
                ) : (
                  <>
                    <LockIcon sx={{ mr: 1 }} />
                    Login with Azure AD
                  </>
                )}
              </Fab>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Basic;


// import React, { useState } from "react";
// import axios from "axios";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import CircularProgress from "@mui/material/CircularProgress";
// import Card from "@mui/material/Card";
// import { Button } from "@mui/material";
// import CircleLoader from "react-spinners/CircleLoader";
// import LockIcon from "@mui/icons-material/Lock";
// import NavigationIcon from "@mui/icons-material/Navigation";
// import "./login.css";
// import Fab from "@mui/material/Fab";
// import AddIcon from "@mui/icons-material/Add";
// import PersonIcon from "@mui/icons-material/Person";
// import Box from "@mui/material/Box";
// import { alignProperty } from "@mui/material/styles/cssUtils";
// import { makeStyles } from "@mui/material/styles";
// import { useLocation } from "react-router-dom";
// function Basic() {
//   const [isLoading, setIsLoading] = useState(false);
//   const location = useLocation();
//   const unauthorized = new URLSearchParams(location.search).get("unauthorized");
//   const customBgColor = "#13294B";
//   const customColor = "#ffffff";

//   const buttonStyle = {
//     backgroundColor: customBgColor,
//     color: customColor,
//   };
//   const handleLogin = async () => {
//     try {
//       setIsLoading(true);
//       const response = await axios.get(
//         process.env.REACT_APP_APIM_LOGIN_AUTH_URL
//       );

//       const { loginUrl, codeVerifier } = response.data;
//        
//       sessionStorage.setItem("key", codeVerifier);

//       window.location.href = loginUrl;
//     } catch (error) {
//       console.error("Error during login:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="container">
//       {/* {isLoading ? ( */}
//         {/* <div className="spiner">
//           <CircleLoader color="#36d7b7" size={150} />
//         </div>
//       ) : ( */}
//         <div>
//           <section className="full-viewport">
//             <div className="box">
//               <div className="centered">
//                 <Fab
//                   size="large"
//                   color="#ffffff"
//                   aria-label="add"
//                   style={buttonStyle}
//                 >
//                   <Box sx={{ fontSize: "1.8rem" }}>
//                     <PersonIcon />
//                   </Box>
//                 </Fab>
//               </div>
//               <h5>Sign in</h5>
//               <h5>Cloud Canvas-Powered by Cloud CoE</h5>

//               <div
//                 style={{
//                   display: "flex",

//                   justifyContent: "center",

//                   alignItems: "center",
//                 }}
//               >
//                 <Fab
//                   sx={{ marginTop: 2 }}
//                   variant="extended"
//                   onClick={handleLogin}
//                   style={buttonStyle}
//                 >
//                   <LockIcon sx={{ mr: 1 }} />
//                   Login with Azure AD
//                 </Fab>
//               </div>
//             </div>
//           </section>
//         </div>
//       {/* )} */}
//     </div>
//   );
// }

// export default Basic;
