import React, { useState } from "react";
import { Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getLogoutUrl } from "../../backend/backend";
function SessionExpired() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const handleLogout = async () => {
    const response = await getLogoutUrl();

    sessionStorage.clear();
    window.location.href = response.logoutUrl;
  };

  return (
    <Container maxWidth="sm">
      <div style={{ textAlign: "center", marginTop: "20vh" }}>
        <Typography variant="h5" gutterBottom>
          Session Expired
        </Typography>
        <Typography paragraph>
          Your session has expired. Please log in again to continue.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleLoginClick}>
          Re-LogIn
        </Button>
      </div>
    </Container>
  );
}

export default SessionExpired;
