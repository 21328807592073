import React, { useEffect, useState } from "react";
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  TablePagination,
  Select,
  MenuItem,
  Box,
  TextField,
  styled,
  Tabs,
  Tab,
  Fab,
  Button,
} from "@mui/material";
import parseCsvAndAggregate from "./Aggregation";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { TableSortLabel } from "@mui/material";
import { Home } from "@mui/icons-material";
import CloudIcon from "@mui/icons-material/Cloud";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getSymbolFromCurrency from "currency-symbol-map";
import Header from "../components/Header";
import {maxAllowedDate} from "../dateConstants.js"
import {minAllowedDate} from "../dateConstants.js"
import Footer from "../../ArchitectureTemplate/Components/Footer";
import Sidenav from "../components/Sidenav";
import Snack from "../../ArchitectureTemplate/Components/Snack";
import CustomSkeleton from "../components/CustomSkeleton";

const styles = {
  tableContainer: {
    maxWidth: "100%",
  },
  tableHead: {
    backgroundColor: "#F8F8FF",
  },
  tableCell: {
    fontFamily: "Equip",
    fontWeight: "bold",
    borderBottom: "1px solid #ddd",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  deleteButton: {
    color: "#f44336",
  },
};
const TableWrapper = styled("div")({
  width: "100%",
  overflowX: "auto",
});

const AggregatedData = () => {
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState(null);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandedCustomer, setExpandedCustomer] = useState(null);
  const [expandedProject, setExpandedProject] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [customerPage, setCustomerPage] = useState(0);
  const [customerRowsPerPage, setCustomerRowsPerPage] = useState(5);
  const [filterValue, setFilterValue] = useState("");
  // const [startDate, setStartDate] = useState('');
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [uniqueInvoiceDates, setUniqueInvoiceDates] = useState([]);
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState("");
  const [selectedTab, setSelectedTab] = useState("back");
  // const [endDate, setEndDate] = useState('');
  const defaultStartDate = new Date();
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
  defaultStartDate.setDate(1); // Set to the first day of the month
  const formattedDefaultStartDate = defaultStartDate.toISOString().slice(0, 10);
  const [userRole, setUserRole] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  // Set default end date to the last day of the previous month
  const defaultEndDate = new Date();
  defaultEndDate.setDate(0); // Set to the last day of the previous month
  const formattedDefaultEndDate = defaultEndDate.toISOString().slice(0, 10);

  // useState hooks for start and end dates
  const [startDate, setStartDate] = useState(formattedDefaultStartDate);
  const [endDate, setEndDate] = useState(formattedDefaultEndDate);

  useEffect(() => {
    // Call the parseCsvToJson function and pass a callback to set the state
    const role = sessionStorage.getItem("user_role");
    setUserRole(role);
    if (role === "Member") {
      setStatus("Access denied. You do not have permission to view this content.");
      setStatusCode(403);
      setSnackbarOpen(true);
      
    }
    parseCsvAndAggregate((data) => {
      // console.log(data);
      setJsonData(data);
      setLoading(false);
      setInitialDataLoaded(true)
    });
  }, []);

  useEffect(() => {
    if (jsonData) {
      // const aggregatedData = jsonData();
      setAggregatedData(jsonData);
      const uniqueDates = jsonData
        ? [...new Set(jsonData.map((item) => item.InvoiceDate))]
        : [];

      // Sort unique invoice dates in ascending order
      uniqueDates.sort((a, b) => new Date(a) - new Date(b));
      setUniqueInvoiceDates(uniqueDates);
       
    }
    
  }, [jsonData]);

  const handleRowClick = (key, row) => {
    setExpandedRow(expandedRow === key ? null : key);
    navigate(`/gcp/billingdashboard/${key}`, { state: JSON.stringify(row) });

     
    setExpandedCustomer(null);
    setExpandedProject(null);
  };

  const handleCustomerClick = (customer) => {
    setExpandedCustomer(expandedCustomer === customer ? null : customer);
    setExpandedProject(null);
  };

  const handleProjectClick = (project) => {
    setExpandedProject(expandedProject === project ? null : project);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCustomerPageChange = (event, newPage) => {
    setCustomerPage(newPage);
  };
  const handleInvoiceDateChange = (event) => {
    setSelectedInvoiceDate(event.target.value);
    sessionStorage.setItem("selectedInvoiceDate", event.target.value);
  };
  const handleCustomerRowsPerPageChange = (event) => {
    setCustomerRowsPerPage(parseInt(event.target.value, 10));
    setCustomerPage(0);
  };
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
    setPage(0);
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    sessionStorage.setItem("startdate", event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
    localStorage.setItem("enddate", event.target.value);
  };

  const handleOtherFilterChange = (event) => {
    setFilterValue(event.target.value);
  };
  //  
  // Filter aggregatedData based on the filterValue
  // const filteredData = aggregatedData.filter(item =>
  //   item.Currency.toLowerCase().includes(filterValue.toLowerCase())
  // );
  const filteredData = aggregatedData.filter((item) => {
    // Check if the currency matches the filter value
    //  
    const currencyMatch =
      !filterValue ||
      (item.Currency &&
        item.Currency.toLowerCase().includes(filterValue.toLowerCase()));

    // Check if the invoice date matches the selected date
    const dateMatch =
      !selectedInvoiceDate || item.InvoiceDate === selectedInvoiceDate;

    // Return true if both criteria match
    
    return currencyMatch && dateMatch;
  }

);
  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortedColumn(column);
    const sortedData = [...aggregatedData].sort((a, b) => {
      const dateA = new Date(a.InvoiceDate);
      const dateB = new Date(b.InvoiceDate);
      return isAsc ? dateA - dateB : dateB - dateA;
    });
    setAggregatedData(sortedData);
  };

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfCurrentMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth - 1);
    let formattedLastDayOfPreviousMonth = lastDayOfPreviousMonth
      .toISOString()
      .split("T")[0];
  
    if (uniqueInvoiceDates && uniqueInvoiceDates.length > 0) {
      // Check if the last day of the previous month is available in the unique invoice dates
      if (!uniqueInvoiceDates.includes(formattedLastDayOfPreviousMonth)) {
        // If not, find the latest available date before the last day of the previous month
        const latestDateBeforePreviousMonth = uniqueInvoiceDates
          .filter(date => new Date(date) < lastDayOfPreviousMonth)
          .sort((a, b) => new Date(b) - new Date(a))[0];
  
        if (latestDateBeforePreviousMonth) {
          formattedLastDayOfPreviousMonth = latestDateBeforePreviousMonth;
        }
      }
    }
  
    const storedSelectedInvoiceDate = sessionStorage.getItem(
      "selectedInvoiceDate"
    );
  
    // Set the selected invoice date based on the session storage or the latest available date before the last day of the previous month
    setSelectedInvoiceDate(
      storedSelectedInvoiceDate || formattedLastDayOfPreviousMonth
    );
  }, [uniqueInvoiceDates]);
  
  
  
  const tableHeadStyle = {
    fontWeight: "bold",
    color: "#13294B",
    fontFamily: "EquipExtended",
    fontSize: 14,
  };
  const tableElementStyle = {
    fontFamily: "Equip",
    fontSize: 14,
    fontWeight: 400,
  };
  
  const date = new Date().toISOString().split("T")[0];
  //  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  function formatDateToDisplay(dateString) {
    const date = new Date(dateString);
    const options = {  month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
}
function getLastDayOfMonth(date) {
  const currentDate = new Date(date);
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const lastDay = new Date(year, month + 1, 0);
  return `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;
}

const handleDateChange = (newDate) => {
  const lastDayOfMonth = getLastDayOfMonth(newDate);
  // console.log(lastDayOfMonth)
const inputdate=newDate.toISOString().split("T")[0]
  setSelectedInvoiceDate(lastDayOfMonth);
  // console.log(inputdate);
  sessionStorage.setItem("selectedInvoiceDate", lastDayOfMonth);
  // console.log(newDate)
  // const inputdate=newDate.toISOString().split("T")[0]
  // setStartDate(inputdate);
  
  // // Calculate the start date as the first day of the previous month
  // const currentMonth = new Date(newDate);
  // currentMonth.setMonth(currentMonth.getMonth() + 1);
  // currentMonth.setDate(1); // Set to the first day of the month

  // const formattedStartDate = formatDate(currentMonth);
  // // console.log(currentMonth)
  // setEndDate(formattedStartDate);
  
 
};

  return (
    <>
    
      <Box sx={{ display: "flex" }}>
        <Sidenav/>
       
        <Box  component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Header />
        
        <Box sx={{minHeight:"80vh"}}>
        {initialDataLoaded && filteredData.length === 0 && (
            <Snack
              open={true} // Set to true to always show the snackbar when data is not available
              status={500} // Define the status code as per your requirement
              data="No data available " // Provide a message to inform the user
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              handleClose={handleCloseSnackbar}
            />
          )}
        {status && statusCode && (
              <Snack
                open={snackbarOpen}
                status={statusCode}
                data={status}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                handleClose={handleCloseSnackbar}
              />
            )}
          <div style={{ display: "flex" }}>
            <div
              style={{ flex: 1, margin: "10px", justifyItems: "flex-start" }}
            >
              <Tabs
                value={selectedTab}
                // onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab
                  label="Home"
                  iconPosition="start"
                  icon={<Home />}
                  value="back"
                />
              </Tabs>
            </div>
            <div style={{ margin: "10px", justifyItems: "flex-end" }}>
              <TextField
                sx={{ minWidth: 200, marginTop: 3, marginRight: 2 }}
                label="Filter by Currency"
                value={filterValue}
                onChange={handleFilterChange}
                variant="standard"
              />
              {/* <Select
                sx={{ minWidth: 200, marginTop: 5 }}
                value={selectedInvoiceDate}
                onChange={handleInvoiceDateChange}
                variant="standard"
              >
                {uniqueInvoiceDates &&
                  uniqueInvoiceDates
                    .filter((date) => date !== undefined) // Filter out undefined dates
                    .map((date, index) => (
                      <MenuItem key={index} value={date}>
                        {date}
                      </MenuItem>
                    ))}
              </Select> */}
              <DatePicker className="datepicker"
                     
                     label="Select Month and Year"
                     showMonthYearPicker
                     
                     selected={selectedInvoiceDate}
                     value={formatDateToDisplay(selectedInvoiceDate)}
                 
                     onChange={handleDateChange}
                     renderInput={(params) => <TextField variant="filled" {...params} 
                     style={{minWidth:100,marginRight:"20px"}}
                     />}
                     maxDate={maxAllowedDate}
                     minDate={minAllowedDate}
                   />
            </div>
          </div>
          <TableContainer
            style={styles.tableContainer}
            sx={{ boxShadow: "0px 4px 8px rgba(186, 220, 211, 5)" }}
          >
            <Table aria-label="pivot table">
              <TableHead style={styles.tableHead}>
                <TableRow>
                  <TableCell />
                  {/* <TableCell  style={tableHeadStyle} >Region</TableCell> */}
                  <TableCell style={tableHeadStyle}>Billing Account</TableCell>
                  <TableCell style={tableHeadStyle}>
                    Billing Account Name
                  </TableCell>
                  <TableCell style={tableHeadStyle}>
                    Billing Account Region
                  </TableCell>

                  {/* <TableCell style={tableHeadStyle}>Currency</TableCell> */}
                  <TableCell style={tableHeadStyle}>Invoice Date</TableCell>

                  <TableCell style={{ ...tableHeadStyle,textAlign: loading ? " " : "right" }}>
                    {" "}
                    Net Cost
                  </TableCell>
                  <TableCell style={{ ...tableHeadStyle,textAlign: loading ? " " : "right"}}>
                    Customer Cost
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {loading ? (
              Array.from({ length: 2 }).map((_, index) => (
            <CustomSkeleton key={index} numColumns={7} />
          ))
        ) : (
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        className="tableRowHover"
                        onClick={() => handleRowClick(row.key, row)}
                      >
                        <TableCell>
                          <IconButton
                            aria-label={
                              expandedRow === row.key ? "Collapse" : "Expand"
                            }
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRowClick(row.key, row);
                            }}
                          >
                            {expandedRow === row.key ? (
                              <ExpandMoreIcon />
                            ) : (
                              <AddIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        {/* <TableCell style={tableElementStyle}>{row.CustomerRegion}</TableCell> */}
                        <TableCell style={tableElementStyle}>
                          {row.RootBillingAccountId}
                        </TableCell>
                        <TableCell style={tableElementStyle}>
                          {row.RootBillingAccountName}
                        </TableCell>
                        <TableCell style={tableElementStyle}>
                          {row.RootBillingAccountRegion}
                        </TableCell>
                        {/* <TableCell style={tableElementStyle}>
                        {row.Currency}
                      </TableCell> */}
                        {row.InvoiceDate > date ? (
                          <TableCell style={tableElementStyle}>
                            Ongoing
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{ ...tableElementStyle, textAlign: "left" }}
                          >
                            {row.InvoiceDate}
                          </TableCell>
                        )}

                        {/* <TableCell style={tableElementStyle}>{row.DueDate}</TableCell> */}
                        <TableCell
                          style={{ ...tableElementStyle, textAlign: "right" }}
                        >
                          {" "}
                          {getSymbolFromCurrency(row.Currency)}{" "}
                          {row.TotalNetCost.toFixed(2)}
                        </TableCell>
                        <TableCell
                          style={{ ...tableElementStyle, textAlign: "right" }}
                        >
                          {" "}
                          {getSymbolFromCurrency(row.Currency)}{" "}
                          {row.TotalCustomerCost.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
        )))}
              </TableBody>
            </Table>
          </TableContainer>

          {filteredData.length > 5 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
         
           </Box>
           <Footer />
        </Box>
      
      </Box>
      
   
    </>
  );
};

export default AggregatedData;
