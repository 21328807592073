// import React from "react";
// import { TableCell, TableRow } from "@mui/material";

// const CustomSkeleton = ({ numColumns }) => {
//   const renderFirstCell = numColumns === 6 ? null : <TableCell />;

//   return (
//     <TableRow>
//       {renderFirstCell}
     
//       <TableCell>
//         <div className="shimmer" style={{ width: "100px", height: "20px" }} />
//       </TableCell>
//       <TableCell>
//         <div className="shimmer" style={{ width: "100px", height: "20px" }} />
//       </TableCell>
//       <TableCell style={{ textAlign: "right" }}>
//         <div className="shimmer" style={{ width: "100px", height: "20px" }} />
//       </TableCell>
//       <TableCell style={{ textAlign: "left", marginLeft: "10px" }}>
//         <div className="shimmer" style={{ width: "100px", height: "20px" }} />
//       </TableCell>
      
//     </TableRow>
//   );
// };

// export default CustomSkeleton;

import React from "react";
import { TableCell, TableRow } from "@mui/material";

const CustomSkeleton = ({ numColumns ,service}) => {
  const cells = [];

  // Conditionally render the first cell based on the numColumns prop
  if (service !== 6) {
    cells.push(<TableCell key={0} />);
  }

  // Generate TableCell elements based on the numColumns prop
  for (let i = 0; i < numColumns - 1; i++) {
    cells.push(
      <TableCell key={i + 1}>
        <div className="shimmer" style={{ width: "100px", height: "20px" }} />
      </TableCell>
    );
  }

  return <TableRow>{cells}</TableRow>;
};

export default CustomSkeleton;
