import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Fab,
  Menu,
  MenuItem,
  ListItemIcon
} from "@mui/material";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Header from "../components/Header";
// import Footer from "../Footer"; // Footer component removed as per your request
import Offerings from "./HomeTiles/Offerings";
import OfferingDecks from "./HomeTiles/OfferingDecks";
import WhitePapers from "./HomeTiles/WhitePapers";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import CloudIcon from '@mui/icons-material/Cloud';
import InfoIcon from '@mui/icons-material/Info';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Footer from "../../ArchitectureTemplate/Components/Footer";
import { CloudDone } from "@mui/icons-material";
const Homepage = () => {
  const navigate= useNavigate();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };
  const handleExternalLink = (url) => {
    window.open(url, '_blank');
  };
  return (
    <Box p={2} >
      <Header />
      <div style={{ margin: "2%", marginTop: 0, position: "relative" }}>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontFamily: "Equip",
            m: 2,
            textAlign: "center",
            color: "#13294B",
            fontWeight: "500",
            fontSize:"20px"
          }}
        >
          Welcome to Nagarro Cloud Practice Portal. Unlock the limitless potential of the
          digital realm with a portal that transcends boundaries, ushers in
          innovation, and empowers your journey to the cloud.
        </Typography>
        <div style={{ textAlign: "center" }}>
          <img
            src="https://www.nagarro.com/hubfs/Vector%2027.png"
            alt="Vector 27"
            loading="lazy"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        <Box sx={{ my: 2 }}>
          <Offerings />
        </Box>
        <Box sx={{ my: 2 }}>
          <OfferingDecks />
        </Box>
        <Box sx={{ my: 2 }}>
          <WhitePapers />
        </Box>
        {/* Fixed Vertical Bar */}
        <div
          style={{
            marginLeft: "200px",
            position: "fixed",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(64, 178, 174, 0.8)",
            color: "#FFFFFF",
            zIndex: 1000,
            minHeight: isHovered ? "auto" : "200px",
            width: isHovered ? "250px" : "40px",
            transition: "width 0.3s ease",
            boxShadow: "0px 0px 5px rgba(0,0,0,0.5)",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            backdropFilter: isHovered ? "blur(5px)" : "none",
          }}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          <div style={{ padding: "20px" }}>
            <Typography
              variant="h6"
              gutterBottom
              fontFamily="EquipExtended"
              style={{
                transform: isHovered ? "rotate(0)" : "rotate(-90deg)",
                transition: "transform 0.3s ease",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              QUICK LINKS
            </Typography>
            {isHovered && (
              <>
                <MenuItem onClick={() => navigate('/billingdashboard')}>
                  <ListItemIcon>
                    <RequestQuoteIcon />
                  </ListItemIcon>
                   Billing dashboard
                </MenuItem>
                
                <MenuItem onClick={() => handleExternalLink('https://nagarro.sharepoint.com/sites/CloudPractice/Consulting')}>
                  <ListItemIcon>
                    <CloudDone />
                  </ListItemIcon>
                  Cloud Consulting Site
                </MenuItem>
                <MenuItem onClick={() =>  handleExternalLink('https://nagarro.sharepoint.com/sites/CloudPractice/Reselling/SitePages/Home.aspx')}>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  Cloud Reselling Site
                </MenuItem>
                {/* Add more menu items as needed */}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </Box>
  );
};

export default Homepage;
