import { Box, Divider } from "@mui/material";

import Grid from "@mui/material/Grid";


import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";




import { useParams } from "react-router-dom";

import { Margin } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import Tile from "../components/Tile";
import Header from "../components/Header";
import CoeportalButton from "../../ArchitectureTemplate/Components/CoePortalButton";
import Footer from "../../ArchitectureTemplate/Components/Footer";
export default function ShowAllData({ data }) {
  const location = useLocation();
  const passedData = location.state.data;

  const [allData, setAllData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [newSearchedData, setNewSearchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [type, setType] = useState("");
  const [templateRating, setTemplateRating] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const provider = params.provider;

  const [retryCount, setRetryCount] = useState(0);

  const handleRetry = () => {
    setRetryCount(retryCount + 1);
  };

 
  return (

    <Box p={2} sx={{minHeight:"85vh"}}>
        <Header/>
    <Grid container spacing={2}>
      {passedData.map((website, index) => (
        <Grid item xs={12} key={index}>
          <Tile
             title={website.title}
             category={website.category}
             url={website.url}
             image={website.image}
             details={website.details}
             id = {website.id}
          />
          <Divider sx={{ Margin: 1 }} />
        </Grid>
      ))}
    </Grid>
    <CoeportalButton/>
    <Footer/>
  </Box>
  );
}
