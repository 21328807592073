import { useState } from "react";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  TableContainer,
  Box,
  Typography,
} from "@mui/material";

const StyledTableContainer = styled(TableContainer)({
  width: "100%",
  overflowX: "auto"
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "0px 12px 0px 0px",
  "&:hover": {
    backgroundColor: "red",
  },
}));

const Projects = ({ data }) => {
  // const { columns, rows } = data();
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card sx={{marginTop:1}}>
      <Typography variant="h5" sx={{ padding: 2, fontFamily: "Equip" }}>
        Template Resource Breakdown
      </Typography>

    
<StyledTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>S.No</TableCell>
            <TableCell>Resource</TableCell>
            <TableCell>Info</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.details.resources.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{item.resourceName}</TableCell>
              <TableCell>{item.details}</TableCell>
              <TableCell>{item.resourceCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </StyledTableContainer>
    </Card>
  );
};

export default Projects;
